import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useSetRecoilState } from 'recoil';

import type { GetSingleTruckQuery } from '@/types/truck';

import addIcon from '../../assets/icons/add.svg';
import editIcon from '../../assets/icons/edit.svg';
import scanIcon from '../../assets/icons/scanning.svg';
import truckLogo from '../../assets/icons/truck.svg';
import AddButton from '../../components/ui/common/AddButton';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import DateField from '../../components/ui/common/DateField';
import Dropdown from '../../components/ui/common/Dropdown';
import FileUploader from '../../components/ui/common/FileUploader';
import InputField from '../../components/ui/common/InputField';
import LevelIndicator from '../../components/ui/common/LevelIndicator';
import DTDocuments from '../../components/ui/trucks/DTDocuments';
import DTLastIncidents from '../../components/ui/trucks/DTLastIncidents';
import DTLastRoutes from '../../components/ui/trucks/DTLastRoutes';
import TruckDriverCard from '../../components/ui/trucks/TruckDriverCard';
import TruckServiceCard from '../../components/ui/trucks/TruckServiceCard';
import TruckStatusDropdown from '../../components/ui/trucks/TruckStatusDropdown';
import {
  FAT_BONES_SERVICE_NAME,
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
  truckMakeOptions,
} from '../../config/constants';
import { useModal } from '../../hooks/useModal';
import { useNotification } from '../../hooks/useNotification';
import {
  incidentSelectedToEdit,
  truckDriversToEdit,
  truckServiceToEdit,
} from '../../recoil/dataToEdit';
import { isImageModalOpenAtom } from '../../recoil/modalImage';
import { needRefreshState } from '../../recoil/needRefresh';
import {
  DOCUMENT_TRUCK_UPLOAD,
  UPDATE_TRUCK,
} from '../../services/graphql/trucks/trucks-mutations';
import { GET_TRUCK_BY_ID_DETAILS_VIEW } from '../../services/graphql/trucks/trucks-queries';
import { convertDateToString } from '../../utils/convertDate';

const TruckDetailView = () => {
  const { t } = useTranslation('common');
  const {
    notifySaveError,
    notifySaveSuccess,
    notifyUpdateError,
    notifyUpdateOk,
  } = useNotification();
  const { id } = useParams();
  const truckId = id;

  // ==== Visual Notifications ====
  const notifyDocumentUploadOk = () => {
    toast.success(t('trucks.save-document-ok'), {
      toastId: 'notifyStatusSaveOk',
    });
  };

  // ==== States ====

  const [data, setData] = useState<GetSingleTruckQuery | null>(null);
  const [key, setKey] = useState(0);
  const [date, setDate] = useState<string | null>(null);
  const [file, setFile] = useState<{
    file: File | null;
    type: string | null;
  }>({ file: null, type: null });
  const setQRModal = useSetRecoilState(isImageModalOpenAtom);
  const setTruckServices = useSetRecoilState(truckServiceToEdit);
  const setTruckDrivers = useSetRecoilState(truckDriversToEdit);
  const setIncidentToEdit = useSetRecoilState(incidentSelectedToEdit);
  const [needRefresh, setNeedRefresh] = useRecoilState(needRefreshState);
  const {
    handleOpenTruckServiceModal,
    handleOpenTruckDriversModal,
    handleOpenNewIncidentModal,
  } = useModal();

  // ==== Queries ====
  const { data: truckData, refetch } = useQuery<GetSingleTruckQuery>(
    GET_TRUCK_BY_ID_DETAILS_VIEW,
    {
      variables: { truckId },
    },
  );

  useEffect(() => {
    if (
      needRefresh === 'incidents' ||
      needRefresh === 'services' ||
      needRefresh === 'drivers'
    )
      refetch();
  }, [needRefresh]);

  // ==== Mutations ====
  // Update truck status
  const [updateStatus] = useMutation<any>(UPDATE_TRUCK, {
    onCompleted: () => {
      refetch();
      notifyUpdateOk();
    },
    onError: () => {
      notifyUpdateError();
    },
  });

  const [updateTruck] = useMutation<any>(UPDATE_TRUCK, {
    onCompleted: () => {
      refetch();
      notifySaveSuccess();
    },
    onError: () => {
      notifySaveError();
    },
  });

  const [uploadDocument, { loading: loadingUploadDocument }] = useMutation<any>(
    DOCUMENT_TRUCK_UPLOAD,
    {
      onCompleted: () => {
        notifyDocumentUploadOk();
        setFile({ file: null, type: null });
        setNeedRefresh('documents');
        setTimeout(() => {
          setNeedRefresh(false);
        }, 500);
      },
      onError: () => {
        setFile({ file: null, type: null });
        setKey(key + 1);
        notifySaveError();
      },
    },
  );

  // ==== Handlers ====
  const handleLicenseChange = (value: string | number) => {
    if (!truckId) return;

    updateTruck({
      variables: {
        truckId,
        license: value.toString(),
      },
    });
  };

  const handleMakeChange = (value: string | number) => {
    if (!truckId) return;

    updateTruck({
      variables: {
        truckId,
        make: value.toString(),
      },
    });
  };

  const handleModelChange = (value: string | number) => {
    if (!truckId) return;
    updateTruck({
      variables: {
        truckId,
        model: value.toString(),
      },
    });
  };

  const handleMileageChange = (value: string | number) => {
    if (!truckId) return;

    updateTruck({
      variables: {
        truckId,
        mileage: Number(value),
      },
    });
  };

  const handleTKChange = (value: string | number) => {
    if (!truckId) return;

    updateTruck({
      variables: {
        truckId,
        tk: value,
      },
    });
  };

  const handleTKEXPDATEChange = (value: string | number) => {
    if (!truckId) return;

    updateTruck({
      variables: {
        truckId,
        TKEXPDATE: value,
      },
    });
  };

  const handleOpenFullImageModal = () => {
    setQRModal({
      isOpen: true,
      isQR: true,
      documentUrl: data?.getTruckById?.data.truck.license || '',
    });
  };

  const handleOpenTruckServicesModal = () => {
    setTruckServices({
      id: data?.getTruckById?.data.truck.id || '',
      license: data?.getTruckById?.data.truck.license || '',
      fatBones_service: data?.getTruckById?.data.truck.fatBones_service,
      fatBones_service_gallons:
        data?.getTruckById?.data.truck.fatBones_service_gallons,
      grease_service: data?.getTruckById?.data.truck.grease_service,
      grease_service_gallons:
        data?.getTruckById?.data.truck.grease_service_gallons,
      hood_service: data?.getTruckById?.data.truck.hood_service,
      hood_service_gallons: data?.getTruckById?.data.truck.hood_service_gallons,
      oil_service: data?.getTruckById?.data.truck.oil_service,
      oil_service_gallons: data?.getTruckById?.data.truck.oil_service_gallons,
    });
    handleOpenTruckServiceModal();
  };

  const handleTruckDriversModal = () => {
    setTruckDrivers({
      id: data?.getTruckById?.data.truck.id || '',
      license: data?.getTruckById?.data.truck.license || '',
      truckDrivers:
        data?.getTruckById?.data?.truckDrivers.map((td) => td.user_id) || [],
    });
    handleOpenTruckDriversModal();
  };

  const handleNewIncidentModal = (incidentId?: string) => {
    setIncidentToEdit(
      incidentId !== undefined
        ? { incidentId, truckId: id || '' }
        : { truckId: id || '', incidentId: '' },
    );
    handleOpenNewIncidentModal();
  };

  const handleFileChange = (newFile: File | null, type: string | null) => {
    setFile({ file: newFile, type });
  };

  const handleFileTypeChange = (type: string | null) => {
    setFile({ file: file?.file, type });
  };

  const handleFileSubmit = () => {
    if (!file.file || !file.type) {
      setKey(key + 1);
      notifySaveError();
    }
    if (file.file && file.type) {
      uploadDocument({
        variables: {
          file: file.file,
          truckId: id,
          type: file.type,
        },
      }).then(() => {
        setKey((prevKey) => prevKey + 1);
      });
    }
  };

  // ==== Effects ====
  useEffect(() => {
    if (truckData && truckData.getTruckById?.data) {
      setData(truckData);
      if (truckData?.getTruckById?.data.truck.TKEXPDATE) {
        const validDate = new Date(
          String(truckData?.getTruckById?.data.truck.TKEXPDATE),
        )
          .getTime()
          .toString();
        if (validDate !== 'NaN') setDate(validDate);
      }
    }
  }, [truckData]);

  return (
    <>
      <section>
        <div className="fixed top-0 z-20 w-full bg-white">
          <Breadcrumb
            title={`${t('trucks.title-h1')}`}
            secondTitle={data?.getTruckById?.data.truck.license || ''}
            image={truckLogo}
          />
        </div>
        <div className="mt-[30px] flex w-full flex-col ">
          <div className="flex w-full flex-col justify-between md:flex-row">
            <div className="flex min-w-[300px] flex-col justify-center gap-6 md:flex-row md:items-center">
              <h1 className="text-xl font-semibold">
                {data?.getTruckById?.data.truck.license || ''}
              </h1>
              {/* Status */}
              {data && data.getTruckById?.data && (
                <div className="flex w-full items-center  self-stretch">
                  <TruckStatusDropdown
                    initialStatusValue={data?.getTruckById?.data.truck.status}
                    mutation={updateStatus}
                    truckId={data.getTruckById?.data.truck.id}
                  />
                </div>
              )}
            </div>
            <button
              onClick={handleOpenFullImageModal}
              className="button-icon mt-3 flex min-h-[33px] min-w-[128px] max-w-[200px] justify-center gap-2 rounded-md border-[1px] border-green px-2 py-1 text-xs hover:bg-green hover:text-white md:mt-0"
            >
              <img src={scanIcon} alt="scan icon" />
              <p>{t('trucks.generate-qr')}</p>
            </button>
          </div>
          {/* Truck info */}
          <div className="flex w-full flex-col pt-6">
            <div className="grid w-full grid-cols-1 gap-2 md:grid-cols-2 md:gap-2 lg:grid-cols-3 lg:gap-4 xl:grid-cols-5 xl:gap-6">
              {/* License Id */}
              <div className="min-h-[44px] w-[200px]">
                <InputField
                  id="license"
                  type="text"
                  label={t('trucks.license')}
                  selectedValue={data?.getTruckById?.data.truck.license || null}
                  onSelectChange={handleLicenseChange}
                />
              </div>

              {/* Make */}
              <div className="min-h-[44px] w-[200px]">
                <Dropdown
                  options={truckMakeOptions}
                  selectedValue={data?.getTruckById?.data.truck.make}
                  onSelectChange={handleMakeChange}
                  label={t('trucks.make')}
                />
              </div>

              {/* Model */}
              <div className="min-h-[44px] w-[200px]">
                <InputField
                  id="model"
                  type="text"
                  label={t('trucks.model')}
                  selectedValue={data?.getTruckById?.data.truck.model || null}
                  onSelectChange={handleModelChange}
                />
              </div>

              {/* Mileage */}
              <div className="min-h-[44px] w-[200px]">
                <InputField
                  id="mileage"
                  type="text"
                  label={t('trucks.mileage')}
                  selectedValue={data?.getTruckById?.data.truck.mileage || null}
                  onSelectChange={handleMileageChange}
                />
              </div>

              {/* TK */}
              <div className="min-h-[44px] w-[200px]">
                <InputField
                  type="text"
                  id="tk"
                  label={t('trucks.TK')}
                  selectedValue={data?.getTruckById?.data.truck.tk || null}
                  onSelectChange={handleTKChange}
                />
              </div>

              {/* Oil Level */}
              <LevelIndicator
                level={data?.getTruckById?.data.truck.oil_level || ''}
                label={t('trucks.oil-level')}
              />
              {/* Antifreeze Level */}
              <LevelIndicator
                level={data?.getTruckById?.data.truck.antifreeze_level || ''}
                label={t('trucks.antifreeze-level')}
              />
              {/* DEF Level */}
              <LevelIndicator
                level={data?.getTruckById?.data.truck.def_level || ''}
                label={t('trucks.DEF')}
              />
              {/* tire Status */}
              <LevelIndicator
                level={data?.getTruckById?.data.truck.tire_status || ''}
                label={t('trucks.tire-status')}
              />
              {}
              <DateField
                label={`Valid License Date TK#`}
                placeholder={'Select date'}
                selectedValue={date}
                onSelectChange={(_date: any) => {
                  const dateString = convertDateToString(_date);
                  handleTKEXPDATEChange(dateString);
                }}
                onlyActualDate={true}
              />
            </div>
          </div>
          {/* Services and drivers */}
          <div className="mt-10 flex flex-col gap-5 md:flex-row">
            {/* Services */}
            <div className="w-full md:w-1/2">
              <p className="mb-3 text-xl font-semibold">
                {t('trucks.services-title')}
              </p>
              <div className="flex flex-row flex-wrap gap-5">
                {/* First service equal to  "Oil Diposal" */}
                {data?.getTruckById?.data.truck.oil_service && (
                  <TruckServiceCard
                    capacity={
                      data?.getTruckById?.data.truck.oil_service_gallons
                    }
                    service={OIL_DISPOSAL_SERVICE_NAME}
                  />
                )}

                {/* Second service equal to  "Hood Cleaning" */}
                {data?.getTruckById?.data.truck.hood_service && (
                  <TruckServiceCard
                    capacity={
                      data?.getTruckById?.data.truck.hood_service_gallons
                    }
                    service={HOOD_CLEANING_SERVICE_NAME}
                  />
                )}

                {/* Third service equal to  "Grease Trap" */}
                {data?.getTruckById?.data.truck.grease_service && (
                  <TruckServiceCard
                    capacity={
                      data?.getTruckById?.data.truck.grease_service_gallons
                    }
                    service={GREASE_TRAP_SERVICE_NAME}
                  />
                )}

                {/* Third service equal to  "FAT BONES" */}
                {data?.getTruckById?.data.truck.fatBones_service && (
                  <TruckServiceCard
                    capacity={
                      data?.getTruckById?.data.truck.fatBones_service_gallons
                    }
                    service={FAT_BONES_SERVICE_NAME}
                  />
                )}
                <button
                  onClick={handleOpenTruckServicesModal}
                  id="addServices"
                  className="box-shadow flex h-[122px] w-[74px] items-center justify-center rounded-lg"
                >
                  <img
                    src={editIcon}
                    alt="add icon"
                    className="h-[40px] opacity-70"
                  />
                </button>
              </div>
            </div>
            {/* Drivers */}
            <div className="w-full md:w-1/2">
              <p className="mb-3 text-xl font-semibold">
                {t('trucks.drivers-title')}
              </p>
              <div className="flex flex-row flex-wrap gap-5">
                {truckData?.getTruckById?.data?.truckDrivers.map((td) => (
                  <TruckDriverCard driver={td.driver} />
                ))}

                <button
                  onClick={handleTruckDriversModal}
                  id="addDrivers"
                  className="box-shadow flex h-[122px] w-[74px] items-center justify-center rounded-lg"
                >
                  <img
                    src={editIcon}
                    alt="add icon"
                    className="h-[40px] opacity-70"
                  />
                </button>
              </div>
            </div>
          </div>

          {/* Last Routes Table */}
          <div className="mt-10">
            <p className="mb-3 text-xl font-semibold">
              {t('trucks.last-routes-table-title')}
            </p>
            <DTLastRoutes
              truckLicense={data?.getTruckById?.data.truck.license || ''}
            />
          </div>
          {/* Last Routes Table */}
          <div className="mt-10">
            <div className="mb-5 flex flex-row items-start justify-between">
              <p className="text-xl font-semibold">
                {t('trucks.last-incidents-table-title')}
              </p>
              <AddButton
                handleAction={() => handleNewIncidentModal()}
                icon={addIcon}
                text={t('trucks.add-incident')}
              />
            </div>
            <DTLastIncidents
              truckId={data?.getTruckById?.data.truck.id || ''}
              openEditIncidentModal={(incident_id: string) => {
                handleNewIncidentModal(incident_id);
              }}
            />
          </div>
          {/* Documents Table */}
          <div className="mb-20 mt-10">
            <div className="flex flex-row justify-between">
              <p className="mb-3 text-xl font-semibold">
                {t('trucks.documents-table-title')}
              </p>
            </div>
            <div className="flex flex-col gap-x-5 lg:flex-row">
              <div className="w-full lg:w-2/3">
                <DTDocuments
                  truckId={data?.getTruckById?.data.truck.id || ''}
                />
              </div>
              <div className="mb-6 w-full lg:w-1/3">
                <div className="h-[390px] w-full">
                  <FileUploader
                    onFileChange={handleFileChange}
                    onFileTypeChange={handleFileTypeChange}
                    submitFile={handleFileSubmit}
                    key={key}
                    customClassname="h-[inherit] w-full flex flex-col items-center justify-center top-[-8px]"
                    loading={loadingUploadDocument}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TruckDetailView;
