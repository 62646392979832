import { gql } from '@apollo/client';

export const GET_WITHDRAWS = gql`
  query GetWithdraw(
    $orderDirection: String
    $orderBy: String
    $offset: Int
    $limit: Int
    $minimun: Int
  ) {
    getWithdraw(
      orderDirection: $orderDirection
      orderBy: $orderBy
      offset: $offset
      limit: $limit
      minimun: $minimun
    ) {
      status
      code
      count
      error
      data {
        id
        receiptId
        amount
        withdraw_date
        notDisbursed
        document
      }
    }
  }
`;

export const GET_DISBURSEMENTS = gql`
  query GetDisbursement(
    $orderDirection: String
    $orderBy: String
    $offset: Int
    $limit: Int
  ) {
    getDisbursement(
      orderDirection: $orderDirection
      orderBy: $orderBy
      offset: $offset
      limit: $limit
    ) {
      status
      code
      count
      error
      data {
        id
        receiptId
        amount
        disbursement_date
        cash_left
        driver
      }
    }
  }
`;
export const GET_PAYMENTS = gql`
  query GetCashPayments(
    $orderDirection: String
    $orderBy: String
    $offset: Int
    $limit: Int
    $filterName: String
    $filterStartDate: String
    $filterEndDate: String
    $filterDriver: String
    $filterAmountTo: Float
    $filterAmountFrom: Float
    $filterReceipt: String
  ) {
    getCashPayments(
      orderDirection: $orderDirection
      orderBy: $orderBy
      offset: $offset
      limit: $limit
      filterName: $filterName
      filterStartDate: $filterStartDate
      filterEndDate: $filterEndDate
      filterDriver: $filterDriver
      filterAmountTo: $filterAmountTo
      filterAmountFrom: $filterAmountFrom
      filterReceipt: $filterReceipt
    ) {
      status
      code
      count
      error
      data {
        id
        receiptId
        subRows {
          id
          receiptId
          amount
        }
        amount
        payment_date
        route
        driver
        client
      }
    }
  }
`;

export const GET_METRICS = gql`
  query GetImportantMetrics {
    getImportantMetrics {
      status
      code
      error
      data {
        notDisbursed
        cashLeft
        diffWithdraw
        diffDisburse
        drivers {
          driver
          cash_left
        }
      }
    }
  }
`;
