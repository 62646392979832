import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { tokenStateAtom } from '../recoil/authState';
import { userRolesAtom, userStateAtom } from '../recoil/userData';
import { GET_USER_BY_AUTH0_ID } from '../services/graphql/user/user-query';

export const useUserData = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [userData, setUserData] = useRecoilState(userStateAtom);
  const setToken = useSetRecoilState(tokenStateAtom);
  const setRoles = useSetRecoilState(userRolesAtom);

  const { loading, error, data } = useQuery(GET_USER_BY_AUTH0_ID, {
    variables: { auth0_id: user?.sub },
    skip: !user?.sub,
  });

  useEffect(() => {
    if (loading || error) return;

    setUserData(data?.getUser.data);
    if (user) {
      setRoles(user.user_roles);
    }
  }, [loading, error, data, user]);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    };

    fetchToken();
  }, [getAccessTokenSilently, setToken, user]);

  return userData || {};
};
