import { gql } from '@apollo/client';

export const GET_ROUTE_RECEIPTS_BY_ID = gql`
  query GetRouteById($routeId: String!) {
    getRouteById(routeId: $routeId) {
      status
      code
      error
      data {
        driverReceipts {
          id
          route_id
          amount
          createdAt
          updatedAt
          image
          category
          date
        }
      }
    }
  }
`;
