import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Controller, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRecoilState, useSetRecoilState } from 'recoil';

import type { GetTruckIncidentByIdQuery } from '@/types/incidents';
import type { GetStatusRoutesResponse } from '@/types/route';

import saveBigIcon from '../../../assets/icons/save.svg';
import truckIcon from '../../../assets/icons/truck.svg';
import {
  damageCategoryOptions,
  damageGradeOptions,
  statusTruckIncidentOptions,
} from '../../../config/constants';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { incidentSelectedToEdit } from '../../../recoil/dataToEdit';
import { needRefreshState } from '../../../recoil/needRefresh';
import {
  CREATE_INCIDENT,
  UPDATE_INCIDENT,
} from '../../../services/graphql/incidents/incidents-mutations';
import { GET_INCIDENT_BY_ID } from '../../../services/graphql/incidents/incidents-querys';
import { GET_ALL_ROUTES } from '../../../services/graphql/route/route-querys';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import Dropdown from '../../ui/common/Dropdown';
import InputField from '../../ui/common/InputField';

interface FormType {
  incident_category: string;
  status: string;
  route_id: string;
  incident_grade: string;
  cost: number;
}

const NewIncident = () => {
  const { t } = useTranslation('common');
  const { notifyUpdateOk, notifyUpdateError } = useNotification();

  const [incidentId] = useRecoilState(incidentSelectedToEdit);
  const [routeOptions, setRouteOptions] = useState<
    { label: string; value: string }[]
  >([] as { label: string; value: string }[]);
  const [routeSearch, setRouteSearch] = useState('');
  // Form
  const {
    control,
    reset,
    formState: { errors },
    watch,
    register,
    handleSubmit,
  } = useForm<FormType>();
  // const { incident_category } = watch();
  const { cost } = watch();
  // CONTROLLERS
  const {
    field: { onChange: onChangeDamageCategory, value: damageCategory },
  } = useController({
    name: 'incident_category',
    control,
    rules: { required: t('modalNewIncident.required') },
  });

  const {
    field: { onChange: onChangeStatus, value: status },
  } = useController({
    name: 'status',
    control,
    rules: { required: t('modalNewIncident.required') },
  });

  const {
    field: { onChange: onChangeDamageGrade, value: damageGrade },
  } = useController({
    name: 'incident_grade',
    control,
    rules: { required: t('modalNewIncident.required') },
  });

  const {
    field: { onChange: onChangeRoute, value: routeId },
  } = useController({
    name: 'route_id',
    control,
    rules: { required: t('modalNewIncident.required') },
  });

  // Visual Notifications
  const notifyOkCreate = () =>
    toast.success(t('modalNewIncident.create-success'), {
      toastId: 'notifyStatusSaveOk',
    });
  const notifyErrorCreate = () =>
    toast.error(t('modalNewIncident.create-error'));

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh('incidents');
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  // Querys
  const { data } = useQuery<GetTruckIncidentByIdQuery>(GET_INCIDENT_BY_ID, {
    variables: { incidentId: incidentId?.incidentId },
    skip: !incidentId?.incidentId,
  });

  const { data: routesList } = useQuery<GetStatusRoutesResponse>(
    GET_ALL_ROUTES,
    {
      variables: { limit: 10, routeNameFilter: routeSearch },
    },
  );

  // Mutations
  const [updateIncident, { loading: updateIncidentLoading }] = useMutation<any>(
    UPDATE_INCIDENT,
    {
      onCompleted: () => {
        notifyUpdateOk();
        closeOrder();
      },
      onError: () => {
        notifyUpdateError();
      },
    },
  );

  const [createIncident, { loading: createIncidentLoading }] = useMutation<any>(
    CREATE_INCIDENT,
    {
      onCompleted: () => {
        notifyOkCreate();
        closeOrder();
      },
      onError: () => {
        notifyErrorCreate();
      },
    },
  );

  // On Submit
  const onSubmit = (formData: FormType) => {
    if (incidentId?.incidentId) {
      updateIncident({
        variables: {
          ...formData,
          cost: Number(formData.cost),
          incidentId: incidentId.incidentId,
          truckId: incidentId.truckId,
        },
      });
    } else {
      createIncident({
        variables: {
          ...formData,
          cost: Number(formData.cost),
          truck_id: incidentId?.truckId,
        },
      });
    }
  };

  // EFFECTS
  useEffect(() => {
    if (data?.getTruckIncidentById?.data && incidentId?.incidentId) {
      reset({ ...(data.getTruckIncidentById.data as FormType) });
    }
  }, [data]);

  useEffect(() => {
    if (routesList?.getRoutes.data) {
      if (incidentId?.incidentId && !routeId) return;
      const routes = routesList?.getRoutes.data.map((r) => ({
        label: r.route_name,
        value: r.id,
      }));
      if (
        incidentId?.incidentId &&
        routes.findIndex((r) => r.value === routeId) < 0
      ) {
        routes.push({
          label: data?.getTruckIncidentById.data.route_name || '',
          value: routeId,
        });
      }
      setRouteOptions(routes);
    }
  }, [routesList, routeId]);

  const isMissingTool =
    damageCategoryOptions.findIndex((d) => d.value === damageCategory) < 0;

  return (
    <>
      <Breadcrumb
        title={t('modalNewIncident.breadcrumps-1')}
        secondTitle={t('modalNewIncident.breadcrumps-2')}
        thirdTitle={
          incidentId?.incidentId
            ? data?.getTruckIncidentById?.data.incident_category
            : t('modalNewIncident.breadcrumps-3-create')
        }
        image={truckIcon}
        id="modalService"
      />
      <h1 className="mt-6 text-xl font-[600]">
        {incidentId?.incidentId
          ? t('modalNewIncident.edit-title')
          : t('modalNewIncident.create-title')}
      </h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="my-6 flex flex-col gap-y-4"
      >
        {/* Damage Category  */}
        <Controller
          control={control}
          name="incident_category"
          render={({ field }) => (
            <Dropdown
              label={t('modalNewIncident.incident-category')}
              options={
                isMissingTool
                  ? [
                      ...damageCategoryOptions,
                      { value: damageCategory, label: damageCategory },
                    ]
                  : damageCategoryOptions
              }
              disabled={isMissingTool}
              onSelectChange={onChangeDamageCategory}
              selectedValue={damageCategory || ''}
              errors={errors.incident_category}
              {...field}
            />
          )}
        />
        {/* Status  */}
        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <Dropdown
              label={t('modalNewIncident.status')}
              options={statusTruckIncidentOptions}
              onSelectChange={onChangeStatus}
              selectedValue={status || ''}
              errors={errors.status}
              {...field}
            />
          )}
        />
        {/* Status  */}
        <Controller
          control={control}
          name="incident_grade"
          render={({ field }) => (
            <Dropdown
              label={t('modalNewIncident.incident-grade')}
              options={damageGradeOptions}
              onSelectChange={onChangeDamageGrade}
              selectedValue={damageGrade || ''}
              errors={errors.incident_grade}
              {...field}
            />
          )}
        />
        {/* Associate Route  */}
        <Controller
          control={control}
          name="route_id"
          render={({ field }) => (
            <Dropdown
              label={t('modalNewIncident.associate-route')}
              options={routeOptions}
              isSearchable
              onSelectChange={onChangeRoute}
              selectedValue={routeId || ''}
              errors={errors.route_id}
              onSearchChange={setRouteSearch}
              {...field}
            />
          )}
        />
        {/* Cost */}
        <InputField
          label={t('modalNewIncident.cost')}
          selectedValue={cost}
          doNotUseSaveSelfButton
          type="number"
          errors={errors.cost}
          register={register}
          registerName="cost"
          validationSchema={{
            required: t('modalNewIncident.required'),
          }}
        />
        <div className="mt-12 flex justify-end">
          <Button
            type="submit"
            loading={updateIncidentLoading || createIncidentLoading}
            id="saveService"
            icon={saveBigIcon}
            text={t('modalAssociateTruckService.save')}
            variant="green"
          />
        </div>
      </form>
    </>
  );
};

export default NewIncident;
