import { useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { WITHDRAW_TABLES_MAX_RESULTS } from '../../../../config/constants';
import { useModal } from '../../../../hooks/useModal';
import { cashDisbursedID } from '../../../../recoil/dataToEdit';
// import { useNotification } from '../../../../hooks/useNotification';
import { needRefreshState } from '../../../../recoil/needRefresh';
import { withDrawFilterState } from '../../../../recoil/withdrawFilter';
import { cleanWithDrawFiltersState } from '../../../../recoil/withdrawFilterClean';
import { GET_WITHDRAWS } from '../../../../services/graphql/cashdeposit/cashdeposit.queries';
import type { WithDrawTable } from '../../../../types/tables';
import { convertToAmericanDateFormat } from '../../../../utils/convertTimestamp';
import { formatNumberMoney } from '../../../../utils/numberFormatter';
import DataTable from '../../common/DataTable/DataTable';
import DTBoldCell from '../../common/DataTable/DTBoldCell';
import DTHeader from '../../common/DataTable/DTHeader';
import DocumentActions from '../../common/DocumentActions';

const DTWithdraw = () => {
  const { t } = useTranslation('common');
  const location = useLocation();
  // const { notifyDeleteError, notifyDeleteOk } = useNotification();

  // States

  const [filters, setFilters] = useRecoilState(withDrawFilterState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const cleanFilters = useRecoilValue(cleanWithDrawFiltersState);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: WITHDRAW_TABLES_MAX_RESULTS,
  });
  const [data, setData] = useState<WithDrawTable[]>([]);
  const [total, setTotal] = useState<number>(0);
  const { handleOpenNewCashDisbursedModal } = useModal();
  const setDisburshId = useSetRecoilState(cashDisbursedID);
  const notifyNoMoneyToDisburse = () => toast.error(t('disbursed.errormoney'));
  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  const openModal = (rowInfo: any) => {
    if (rowInfo.notDisbursed > 0) {
      setDisburshId(rowInfo.id);
      handleOpenNewCashDisbursedModal();
    } else {
      notifyNoMoneyToDisburse();
    }
  };

  const {
    data: fetchData,
    loading,
    error,
    refetch,
  } = useQuery(GET_WITHDRAWS, {
    variables: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'withdraw_date',
      orderDirection: filters?.orderDirection || 'DESC',
    },
  });

  useEffect(() => {
    if (fetchData && fetchData.getWithdraw?.data) {
      const updatedData: WithDrawTable[] = fetchData.getWithdraw.data.map(
        (withdraw: WithDrawTable) => ({
          id: withdraw.id,
          receiptId: withdraw.receiptId || '',
          notDisbursed: withdraw.notDisbursed || 0,
          amount: withdraw.amount || 0,
          withdraw_date: withdraw.withdraw_date || '',
          document: withdraw.document || '',
        }),
      );
      setData(updatedData);
      setTotal(fetchData.getWithdraw.count);
    }
  }, [fetchData]);

  const columnHelper = createColumnHelper<WithDrawTable>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('receiptId', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <DTBoldCell content={info.getValue()} id={info.cell.id} />
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('withdraw.th-receiptId')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="receiptId"
          />
        ),
      }),
      columnHelper.accessor('amount', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">
            {formatNumberMoney(info.getValue())}
          </span>
        ),

        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('withdraw.th-amount')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="amount"
          />
        ),
      }),
      columnHelper.accessor('notDisbursed', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span
            className={`text-xs font-medium ${
              info.getValue() > 0 ? 'text-reyesgold' : ''
            }`}
          >
            {formatNumberMoney(info.getValue())}
          </span>
        ),

        header: ({ ...info }) => (
          <DTHeader
            onClick={() => handleChangeSortDirection('notDisbursed')}
            title={t('withdraw.th-notDisbursed')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="notDisbursed"
          />
        ),
      }),
      columnHelper.accessor('withdraw_date', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('withdraw.th-date')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="withdraw_date"
          />
        ),
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() !== null
              ? convertToAmericanDateFormat(info.getValue() as string)
              : ''}
          </span>
        ),
      }),
      columnHelper.accessor('document', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <DocumentActions
            documentId={info.row.original.receiptId}
            documentName={info.row.original.document}
            cashDisburse={() => {
              openModal(info.row.original);
            }}
            url={info.getValue()}
          />
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('withdraw.th-document')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="document"
          />
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  // Need refresh (reload data on modal close, filters,...)

  const [needRefresh] = useRecoilState(needRefreshState);

  useEffect(() => {
    refetch({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...filters,
    });
  }, [filters, needRefresh, pageIndex, pageSize]);

  // On before leave
  useEffect(() => {
    // clean filters when leaving component
    setFilters(cleanFilters);
    setFilters((prev) => ({
      ...prev,
      orderDirection: 'DESC',
      orderBy: 'withdraw_date',
    }));
  }, [location.key]);

  return (
    <>
      <div className="min-h-[200px]">
        <DataTable
          error={error}
          loading={loading}
          table={table}
          total={total}
          tableId=""
          sorting={{ ...filters }}
          loadingRows={pageSize}
          tableLayout="auto"
        />
      </div>
    </>
  );
};

export default DTWithdraw;
