import { selector } from 'recoil';

import type { Filters } from './clientsFilter';
import { clientFiltersState } from './clientsFilter';

export const cleanFiltersState = selector({
  key: 'cleanTheFilters',
  get: ({ get }) => {
    const currentFilters = get(clientFiltersState);

    // Limpia los filtros y devuelve una nueva copia con todo en null
    const cleanedFilters: Filters = {};
    Object.keys(currentFilters || {}).forEach((key) => {
      cleanedFilters[key] = null;
    });

    return cleanedFilters;
  },
});
