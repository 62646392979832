import workerIcon from '../../../assets/icons/workers.svg';

type Props = {
  driver: string;
};

const TruckDriverCard = ({ driver }: Props) => {
  return (
    <div
      className="box-shadow flex h-[122px] w-[150px] flex-col items-center gap-[0.2rem] rounded-lg px-6 py-4"
      id={`${driver.replace(/\s/g, '')}Card`}
    >
      <div className=" rounded-full bg-[#b2bfc1]">
        <img
          src={workerIcon}
          alt="worker icon"
          className="img-color h-[61px] w-[61px] p-2"
        />
      </div>
      <div className="mt-1 flex items-center justify-center gap-1">
        <p className="max-w-[120px] truncate text-xs">{driver}</p>
      </div>
    </div>
  );
};

export default TruckDriverCard;
