import { useMutation, useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { ChangeEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import type { GetDocumentByClientIdQuery } from '@/types/document';
import type { InvoiceToShow } from '@/types/invoice';

import { INVOICES_TABLES_MAX_RESULTS } from '../../../config/constants';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { invoicesFiltersState } from '../../../recoil/invoicesFilter';
import { cleanInvoicesFiltersState } from '../../../recoil/invoicesFilterClean';
import { needRefreshState } from '../../../recoil/needRefresh';
import { GET_DOCUMENTS } from '../../../services/graphql/document/document.queries';
import { DOCUMENT_DELETE } from '../../../services/graphql/document/document-mutations';
import type { InvoicesTable } from '../../../types/tables';
import { convertToAmericanDateFormat } from '../../../utils/convertTimestamp';
import DataTable from '../common/DataTable/DataTable';
import DTBoldCell from '../common/DataTable/DTBoldCell';
import DTHeader from '../common/DataTable/DTHeader';
import DeleteButton from '../common/DeleteButton';
import DocumentActions from '../common/DocumentActions';
import FilterMarkInvoices from '../common/FilterMarkInvoices';
import Searchbar from '../common/Searchbar';

const DTInvoices = () => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const { notifyDeleteError, notifyDeleteOk, notifyNoSelection } =
    useNotification();

  // States

  const [filters, setFilters] = useRecoilState(invoicesFiltersState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [deleteIconState, setDeleteIconState] = useState<boolean>(false);
  const cleanFilters = useRecoilValue(cleanInvoicesFiltersState);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: INVOICES_TABLES_MAX_RESULTS,
  });
  const [data, setData] = useState<InvoiceToShow[]>([]);
  const [total, setTotal] = useState<number>(0);

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  const {
    data: fetchData,
    loading,
    error,
    refetch: refetchDocuments,
  } = useQuery<GetDocumentByClientIdQuery>(GET_DOCUMENTS, {
    variables: {
      typeFilter: ['Invoice'],
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'date',
      orderDirection: filters?.orderDirection || 'DESC',
    },
  });

  const [deleteDocument] = useMutation<any>(DOCUMENT_DELETE, {
    onCompleted: () => {
      refetchDocuments({ limit: pageSize, offset: pageIndex * pageSize });
      notifyDeleteOk();
      setDeleteIconState(!deleteIconState);
    },
    onError: () => {
      notifyDeleteError();
    },
  });

  useEffect(() => {
    if (fetchData && fetchData.getDocuments?.data) {
      const updatedData: InvoiceToShow[] = fetchData.getDocuments.data.map(
        (invoice) => ({
          id: invoice.id,
          invoiceName: invoice.name || '',
          routeName: invoice.route_name || '',
          clientName: invoice.client_name || '',
          date: invoice.date || '',
          document: invoice.url || '',
        }),
      );

      setData(updatedData);
      setTotal(fetchData.getDocuments.count);
    }
  }, [fetchData]);

  const columnHelper = createColumnHelper<InvoicesTable>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('select', {
        size: 30,
        minSize: 30,
        maxSize: 30,
        header: ({ table }) => (
          <input
            checked={table.getIsAllPageRowsSelected()}
            onChange={table.getToggleAllPageRowsSelectedHandler()}
            type="checkbox"
            name="select"
            id="select"
            className="table-checkbox-th flex h-[18px] w-[18px] items-center justify-center rounded-md border border-white bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
          />
        ),
        cell: ({ row }) => (
          <input
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            type="checkbox"
            name="select"
            id="select"
            className="flex h-[18px] w-[18px] items-center justify-center rounded-md border border-green bg-transparent text-green checked:bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      }),
      columnHelper.accessor('invoiceName', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <DTBoldCell content={info.getValue()} id={info.cell.id} />
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('invoices.th-invoice-name')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="invoiceName"
          />
        ),
      }),
      columnHelper.accessor('routeName', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),

        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('invoices.th-route-name')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="routeName"
          />
        ),
      }),
      columnHelper.accessor('clientName', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),

        header: ({ ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection('client')}
            title={t('invoices.th-client')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="clientName"
          />
        ),
      }),
      columnHelper.accessor('date', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-documents.th-date')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="date"
          />
        ),
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() !== null
              ? convertToAmericanDateFormat(info.getValue() as string)
              : ''}
          </span>
        ),
      }),
      columnHelper.accessor('document', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <DocumentActions
            documentId={info.row.original.id}
            documentName={info.row.original.invoiceName}
            deleteDocument={deleteDocument}
            url={info.getValue()}
          />
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('invoices.th-document')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="document"
          />
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  // Debounce Name filtering
  const [filterValue, setFilterValue] = useState<string>('');
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleFilterChangeWithDebounce = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = event.target.value;
    setFilterValue(inputValue);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      let newFilters: Record<string, any> = {};

      newFilters = { ...filters };

      if (inputValue) {
        newFilters.nameFilter = inputValue;
      } else {
        newFilters.nameFilter = null;
      }

      setFilters(newFilters);
    }, 500);
  };

  // Delete clients from row selection

  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);

  useEffect(() => {
    if (table.getSelectedRowModel().flatRows.length > 0) {
      setSelectedDocuments(
        table.getSelectedRowModel().flatRows.map((row) => row.original.id),
      );
    }
  }, [table.getSelectedRowModel().flatRows]);

  const [deleteDocuments, { loading: deleteDocumentsLoading }] =
    useMutation<any>(DOCUMENT_DELETE, {
      variables: {
        id: selectedDocuments,
      },
      onCompleted: () => {
        refetchDocuments({ limit: pageSize, offset: pageIndex * pageSize });
        notifyDeleteOk();
        table.resetRowSelection();
        setDeleteIconState(!deleteIconState);
      },
      onError: () => {
        notifyDeleteError();
      },
    });

  const handleRemove = () => {
    if (selectedDocuments.length > 0) {
      deleteDocuments();
    } else {
      notifyNoSelection();
    }
  };

  // Need refresh (reload data on modal close, filters,...)

  const [needRefresh] = useRecoilState(needRefreshState);

  useEffect(() => {
    refetchDocuments({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...filters,
    });
  }, [filters, needRefresh, pageIndex, pageSize]);

  // Modals
  const { handleOpenInvoicesFilterModal } = useModal();

  // On before leave
  useEffect(() => {
    // clean filters when leaving component
    setFilters(cleanFilters);
    setFilters((prev) => ({
      ...prev,
      orderDirection: 'DESC',
      orderBy: 'date',
    }));
  }, [location.key]);

  return (
    <>
      <div className="min-h-[200px] min-w-[488px]">
        <div className="my-2 flex w-full justify-between gap-4">
          <Searchbar
            filterValue={filterValue}
            onChange={handleFilterChangeWithDebounce}
            handleOpenFilterModal={handleOpenInvoicesFilterModal}
          />
          <div className="relative flex gap-4">
            <DeleteButton handleRemove={handleRemove} />
          </div>
        </div>
        <div className="my-2 flex w-full flex-wrap gap-2">
          {filters &&
            Object.entries(filters).map(
              ([name, value]) =>
                value !== null &&
                value !== undefined &&
                name !== 'filterInvoiceName' &&
                name !== 'nameFilter' &&
                name !== 'orderDirection' &&
                name !== 'orderBy' &&
                name !== 'limit' &&
                name !== 'offset' && (
                  <FilterMarkInvoices
                    key={name}
                    filterName={name}
                    filterValue={value}
                  />
                ),
            )}
        </div>
        <DataTable
          error={error}
          loading={loading || deleteDocumentsLoading}
          table={table}
          total={total}
          tableId="InvoicesTable"
          sorting={{ ...filters }}
          loadingRows={pageSize}
        />
      </div>
    </>
  );
};

export default DTInvoices;
