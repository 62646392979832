import { NavLink } from 'react-router-dom';

import boneIcon from '../../assets/icons/boneIcon.svg';
import cookerIcon from '../../assets/icons/cooker-hood.svg';
import greaseIcon from '../../assets/icons/grease-trap.svg';
import oilIcon from '../../assets/icons/oil-disposal.svg';
import {
  FAT_BONES_SERVICE_NAME,
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
} from '../../config/constants';

type Props = {
  clientId: string;
  service: string;
};

const SecondaryNavItem = ({ clientId, service }: Props) => {
  let servicePath = service.toLowerCase().replace(/\s+/g, '-');
  if (servicePath === 'meat,-fat-&-bone') {
    servicePath = 'meat-fat-bone';
  }
  const switchIcon = () => {
    switch (service) {
      case OIL_DISPOSAL_SERVICE_NAME:
        return oilIcon;
      case GREASE_TRAP_SERVICE_NAME:
        return greaseIcon;
      case HOOD_CLEANING_SERVICE_NAME:
        return cookerIcon;
      case FAT_BONES_SERVICE_NAME:
        return boneIcon;
      default:
        return oilIcon;
    }
  };

  return (
    <li className="mb-6">
      <NavLink
        to={`/dashboard/clients/${servicePath}/${clientId}`}
        className={({ isActive }) =>
          isActive
            ? 'navLinkActive flex gap-2 items-center titleAttr'
            : 'navLinkInactive flex gap-2 items-center titleAttr'
        }
        data-title={service}
      >
        <img className="h-[22px]" src={switchIcon()} alt={service} />
      </NavLink>
    </li>
  );
};

export default SecondaryNavItem;
