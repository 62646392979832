import { gql } from '@apollo/client';

export const GET_EQUIPMENT = gql`
  query GetEquipment(
    $limit: Int
    $offset: Int
    $nameFilter: String
    $orderBy: String
    $orderDirection: String
  ) {
    getEquipment(
      limit: $limit
      offset: $offset
      nameFilter: $nameFilter
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      count
      error
      data {
        id
        name
        quantity
        cost
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const GET_EQUIPMENT_ID = gql`
  query GetEquipmentById($id: String!) {
    getEquipmentById(id: $id) {
      status
      code
      error
      data {
        id
        name
        quantity
        cost
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
