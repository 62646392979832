import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { driversListState } from '../recoil/driversList';
import { trucksListState } from '../recoil/trucksList';
import { GET_TRUCK_DRIVER } from '../services/graphql/drivers/drivers-query';
import { GET_TRUCKS_BY_SERVICE } from '../services/graphql/trucks/trucks-queries';

export const useDropdownData = (
  service: string | undefined,
  date: string | undefined,
  truckId: string | undefined,
  driverId?: string | undefined,
  routeUpdated?: boolean,
) => {
  const setDrivers = useSetRecoilState(driversListState);
  const setTrucks = useSetRecoilState(trucksListState);
  const { data: driverData, refetch: refetchDrivers } = useQuery(
    GET_TRUCK_DRIVER,
    {
      variables: { truckId, date },
      skip: !truckId || !date,
    },
  );
  const { data: truckData, refetch: refetchTrucks } = useQuery(
    GET_TRUCKS_BY_SERVICE,
    {
      variables: {
        service,
        date,
        orderBy: 'license',
        orderDirection: 'ASC',
        nameFilter: '',
      },
      skip: !date || !service,
    },
  );

  useEffect(() => {
    if (driverData && driverData.getTruckDrivers.data) {
      setDrivers(
        driverData.getTruckDrivers.data.map(
          (driver: { id: string; name: string }) => ({
            value: driver.id,
            label: driver.name,
          }),
        ),
      );
      if (date && truckId) refetchDrivers();
    }
  }, [driverData, date, routeUpdated, truckData, driverId]);

  useEffect(() => {
    if (truckData && truckData.getTruckByService.data) {
      setTrucks(
        truckData.getTruckByService.data.map(
          (truck: { license: string; id: string }) => ({
            value: truck.license,
            label: truck.license,
            id: truck.id,
          }),
        ),
      );
      if (date && service) refetchTrucks();
    }
  }, [truckData, driverId, routeUpdated, date, driverData, truckId]);
  useEffect(() => {
    if (date && truckId) refetchDrivers();
  }, [refetchDrivers]);

  useEffect(() => {
    if (date && service) refetchTrucks();
  }, [refetchTrucks]);
  return { truckData, driverData, service };
};
