import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import { routeOptions } from '../../../config/constants';
import { useNotification } from '../../../hooks/useNotification';
import { UPDATE_ROUTE_STATUS } from '../../../services/graphql/route/route-mutations';
import {
  colourStyles,
  CustomOption,
  CustomSingleValue,
} from '../../../styles/statusDropdownStyle';

type RouteStatusDropdownProps = {
  routeId: string | undefined;
  initialStatus: string;
  refetchRoutes: () => void;
};

const RouteStatusDropdown: React.FC<RouteStatusDropdownProps> = ({
  routeId,
  initialStatus,
  refetchRoutes,
}) => {
  const { notifySaveError, notifySaveSuccess } = useNotification();

  const [updateRoute] = useMutation(UPDATE_ROUTE_STATUS, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: () => {
      refetchRoutes();
      notifySaveSuccess();
    },
  });
  const [status, setStatus] = useState<string>(initialStatus);

  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  const handleStatusChange = async (selectedOption: any) => {
    const newStatus = selectedOption.value;
    setStatus(newStatus);

    await updateRoute({
      variables: {
        routeId,
        status: newStatus,
      },
    });
  };

  return (
    <div className="absolute flex text-xs font-medium">
      <Select
        styles={colourStyles(status, routeOptions)}
        components={{
          DropdownIndicator: () => <ArrowDownIcon />,
          IndicatorSeparator: () => null,
          Option: CustomOption,
          SingleValue: CustomSingleValue,
        }}
        options={routeOptions}
        onChange={handleStatusChange}
        id="RouteStatusDropdown"
        value={routeOptions.find((option) => option.value === status)}
        isSearchable={false}
      />
    </div>
  );
};
export default RouteStatusDropdown;
