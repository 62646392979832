import { useMutation } from '@apollo/client';
import React from 'react';
import Select from 'react-select';

import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import { stopOptions } from '../../../config/constants';
import { useNotification } from '../../../hooks/useNotification';
import { UPDATE_STOP_STATUS } from '../../../services/graphql/stops/stops-mutation';
import {
  colourStyles,
  CustomOption,
  CustomSingleValue,
} from '../../../styles/statusDropdownStyle';

type StopStatusDropdownProps = {
  stopId: string | undefined;
  initialStatus: string;
  refetchRoutes: () => void;
};

const StopStatusDropdown: React.FC<StopStatusDropdownProps> = ({
  stopId,
  initialStatus,
  refetchRoutes,
}) => {
  const { notifySaveError, notifySaveSuccess } = useNotification();

  const [UpdateStop] = useMutation(UPDATE_STOP_STATUS, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: () => {
      refetchRoutes();
      notifySaveSuccess();
    },
  });
  const [status, setStatus] = React.useState(initialStatus);

  const handleStatusChange = async (selectedOption: any) => {
    const newStatus = selectedOption.value as string;

    await UpdateStop({
      variables: {
        stopId,
        value: newStatus,
      },
    });

    setStatus(newStatus);
  };

  return (
    <div className="flex items-center justify-end gap-[15px]">
      <div className="flex items-center justify-center gap-1 rounded-md px-2.5">
        <Select
          styles={colourStyles(status, stopOptions)}
          components={{
            DropdownIndicator: () => <ArrowDownIcon />,
            IndicatorSeparator: () => null,
            Option: CustomOption,
            SingleValue: CustomSingleValue,
          }}
          options={stopOptions}
          onChange={handleStatusChange}
          value={stopOptions.find((option) => option.value === status)}
          isSearchable={false}
        />
      </div>
    </div>
  );
};
export default StopStatusDropdown;
