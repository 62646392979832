import { gql } from '@apollo/client';

export const GET_ALL_DRIVERS = gql`
  query GetDrivers($date: String) {
    getDrivers(date: $date) {
      status
      code
      data {
        id
        name
        phone
        email
        role
        status
        profile_picture
        auth0_id
        signature
        createdAt
        updatedAt
        deletedAt
      }
      error
    }
  }
`;

export const GET_TRUCK_DRIVER = gql`
  query GetTruckDrivers($truckId: String!, $date: String) {
    getTruckDrivers(truckId: $truckId, date: $date) {
      status
      code
      error
      data {
        id
        name
        phone
        email
        role
        status
        profile_picture
        auth0_id
        signature
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
