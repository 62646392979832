import { useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { WITHDRAW_TABLES_MAX_RESULTS } from '../../../../config/constants';
import { disburseFilterState } from '../../../../recoil/disburseFilter';
import { cleanDisburseFiltersState } from '../../../../recoil/disburseFilterClean';
// import { useNotification } from '../../../../hooks/useNotification';
import { needRefreshState } from '../../../../recoil/needRefresh';
import { GET_DISBURSEMENTS } from '../../../../services/graphql/cashdeposit/cashdeposit.queries';
import type { DisbursementTable } from '../../../../types/tables';
import { convertToAmericanDateFormat } from '../../../../utils/convertTimestamp';
import { formatNumberMoney } from '../../../../utils/numberFormatter';
import DataTable from '../../common/DataTable/DataTable';
import DTBoldCell from '../../common/DataTable/DTBoldCell';
import DTHeader from '../../common/DataTable/DTHeader';

const DTDisbursement = () => {
  const { t } = useTranslation('common');
  const location = useLocation();
  // const { notifyDeleteError, notifyDeleteOk } = useNotification();

  // States

  const [filters, setFilters] = useRecoilState(disburseFilterState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const cleanFilters = useRecoilValue(cleanDisburseFiltersState);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: WITHDRAW_TABLES_MAX_RESULTS,
  });
  const [data, setData] = useState<DisbursementTable[]>([]);
  const [total, setTotal] = useState<number>(0);

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  const {
    data: fetchData,
    loading,
    error,
    refetch,
  } = useQuery(GET_DISBURSEMENTS, {
    variables: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'disbursement_date',
      orderDirection: filters?.orderDirection || 'DESC',
    },
  });

  useEffect(() => {
    if (fetchData && fetchData.getDisbursement?.data) {
      const updatedData: DisbursementTable[] =
        fetchData.getDisbursement.data.map((withdraw: DisbursementTable) => ({
          id: withdraw.id,
          receiptId: withdraw.receiptId || '',
          cash_left: withdraw.cash_left || 0,
          amount: withdraw.amount || 0,
          disbursement_date: withdraw.disbursement_date || '',
          driver: withdraw.driver || '',
        }));
      setData(updatedData);
      setTotal(fetchData.getDisbursement.count);
    }
  }, [fetchData]);

  const columnHelper = createColumnHelper<DisbursementTable>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('receiptId', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <DTBoldCell
            content={info.getValue()}
            id={info.cell.id + Math.random()}
          />
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('withdraw.th-receiptId')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="receiptId"
          />
        ),
      }),
      columnHelper.accessor('amount', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">
            {formatNumberMoney(info.getValue())}
          </span>
        ),

        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('withdraw.th-amount')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="amount"
          />
        ),
      }),
      columnHelper.accessor('cash_left', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">
            {formatNumberMoney(info.getValue())}
          </span>
        ),

        header: ({ ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection('cash_left')}
            title={t('disbursed.th-notSpent')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="cash_left"
          />
        ),
      }),
      columnHelper.accessor('driver', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
        header: ({ ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection('driver')}
            title={t('disbursed.th-driver')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="driver"
          />
        ),
      }),
      columnHelper.accessor('disbursement_date', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('disbursed.th-date')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="disbursement_date"
          />
        ),
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() !== null
              ? convertToAmericanDateFormat(info.getValue() as string)
              : ''}
          </span>
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  // Need refresh (reload data on modal close, filters,...)

  const [needRefresh] = useRecoilState(needRefreshState);

  useEffect(() => {
    refetch({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...filters,
    });
  }, [filters, needRefresh, pageIndex, pageSize]);

  // On before leave
  useEffect(() => {
    // clean filters when leaving component
    setFilters(cleanFilters);
    setFilters((prev) => ({
      ...prev,
      orderDirection: 'DESC',
      orderBy: 'disbursement_date',
    }));
  }, [location.key]);

  return (
    <>
      <div className="min-h-[200px]">
        <DataTable
          error={error}
          loading={loading}
          table={table}
          total={total}
          tableId=""
          sorting={{ ...filters }}
          loadingRows={pageSize}
          tableLayout="auto"
        />
      </div>
    </>
  );
};

export default DTDisbursement;
