import { atom } from 'recoil';

type IsImageModalOpenState = {
  isOpen: boolean;
  isQR?: boolean;
  documentUrl: string;
};

export const isImageModalOpenAtom = atom<IsImageModalOpenState>({
  key: 'isImageModalOpenState',
  default: {
    isOpen: false,
    isQR: false,
    documentUrl: '',
  },
});
