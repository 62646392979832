import { gql } from '@apollo/client';

export const UPDATE_INCIDENT = gql`
  mutation UpdateIncident(
    $incidentId: String!
    $truckId: String!
    $status: String
    $cost: Int
    $incident_category: String
    $incident_grade: String
    $route_id: String
  ) {
    updateIncident(
      incidentId: $incidentId
      truckId: $truckId
      status: $status
      cost: $cost
      incident_category: $incident_category
      incident_grade: $incident_grade
      route_id: $route_id
    ) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_INCIDENT = gql`
  mutation CreateIncident(
    $incident_category: String!
    $incident_grade: String!
    $route_id: String!
    $truck_id: String!
    $cost: Int!
    $status: String!
  ) {
    createIncident(
      incident_category: $incident_category
      incident_grade: $incident_grade
      route_id: $route_id
      truck_id: $truck_id
      cost: $cost
      status: $status
    ) {
      status
      code
      data
      error
    }
  }
`;
