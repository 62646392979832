import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import cashDisburseIcon from '../../../assets/icons/cashDisburse.svg';
import iconDownload from '../../../assets/icons/document-download.svg';
import iconSee from '../../../assets/icons/eye.svg';
import iconDelete from '../../../assets/icons/trash-timeline.svg';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { tokenStateAtom } from '../../../recoil/authState';
import { confirmationModalState } from '../../../recoil/modalDelete';
import { isImageModalOpenAtom } from '../../../recoil/modalImage';
import { DOCUMENT_DELETE } from '../../../services/graphql/document/document-mutations';

type Props = {
  documentName: string | undefined;
  url?: string;
  documentId: string;
  deleteDocument?: (options: { variables: { id: string } }) => void;
  cashDisburse?: () => void;
};

const DocumentActions = ({
  documentId,
  documentName,
  deleteDocument,
  url,
  cashDisburse,
}: Props) => {
  const { notifyDeleteError, notifyDeleteOk } = useNotification();
  const { t } = useTranslation('common');
  const { handleCloseAllModals } = useModal();
  const setConfirmModalState = useSetRecoilState(confirmationModalState);

  // Just if it is not provided by parent component
  const [deleteDocumentLocally] = useMutation(DOCUMENT_DELETE, {
    onError: () => {
      notifyDeleteError();
    },
    onCompleted: () => {
      notifyDeleteOk();
    },
  });

  const handleDeleteDocument = () => {
    if (deleteDocument) {
      deleteDocument({
        variables: {
          id: documentId,
        },
      });
    } else {
      deleteDocumentLocally({
        variables: {
          id: [documentId],
        },
      });
    }
  };
  const handleDeleteDocumentPopup = () => {
    handleCloseAllModals();
    setConfirmModalState({
      isOpen: true,
      onConfirm: () => {
        handleDeleteDocument();
      },
      onCancel: () => {},
      message: t('global.are-you-sure-delete'),
    });
  };
  const token = useRecoilValue(tokenStateAtom);

  const handleDownloadClick = () => {
    const headers = new Headers({
      Authorization: `Bearer ${token}`,
    });
    fetch(`${process.env.REACT_APP_FILE_URL}${url || documentName}`, {
      method: 'GET',
      headers,
    })
      .then((response) => {
        response.blob().then((blob) => {
          const newUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = newUrl;
          a.download = documentName || 'documento';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(newUrl);
        });
      })
      .catch((error) => {
        console.error('Error durante la descarga:', error);
      });
  };

  const setImageModal = useSetRecoilState(isImageModalOpenAtom);

  const handleOpenFullImageModal = () => {
    setImageModal({
      isOpen: true,
      isQR: false,
      documentUrl: url || documentName || '',
    });
  };

  const handleSeeDocument = () => {
    handleOpenFullImageModal();
  };

  return (
    <>
      <div className="flex items-center gap-2">
        {process.env.REACT_APP_FILE_URL && documentName && (
          <>
            <a
              onClick={handleDownloadClick}
              id="downloadDocument"
              rel="noreferrer"
              download={document}
              className="cursor-pointer"
            >
              <img
                className="h-[16px]"
                src={iconDownload}
                alt="icon download"
              />
            </a>
            <div className="cursor-pointer" onClick={handleSeeDocument}>
              <img className="h-[16px]" src={iconSee} alt="icon see" />
            </div>
            {!cashDisburse && (
              <button onClick={handleDeleteDocumentPopup} id="deleteDocument">
                <img className="h-[16px]" src={iconDelete} alt="icon delete" />
              </button>
            )}
            {cashDisburse && (
              <button onClick={cashDisburse} id="disburseMoney">
                <img
                  className="h-[16px]"
                  src={cashDisburseIcon}
                  alt="icon disburse"
                />
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DocumentActions;
