import { Tooltip } from 'react-tooltip';

type Props = {
  content: string;
  id: string;
};

const DTBoldCell = ({ id, content }: Props) => {
  return (
    <>
      <Tooltip
        id={id}
        className="!rounded-[5px] !bg-greenextralight !px-3 !py-1 !font-bold !text-black"
        noArrow
        float
      />
      <span
        data-tooltip-id={id}
        data-tooltip-content={content}
        className="cell-class cursor-pointer text-xs font-bold"
      >
        {content}
      </span>
    </>
  );
};

export default DTBoldCell;
