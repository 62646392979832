export const useBlockScroll = () => {
  const blockScroll = () => {
    const { body } = document;
    body.style.overflow = 'hidden';
    body.style.marginRight = '17px';
  };

  const allowScroll = () => {
    const { body } = document;
    body.style.overflow = 'auto';
    body.style.marginRight = '0px';
  };

  return { allowScroll, blockScroll };
};
