import { gql } from '@apollo/client';

export const GET_SETTINGS = gql`
  query GetSettings {
    getSettings {
      status
      code
      error
      data {
        id
        name
        value
        deletedAt
        createdAt
        updatedAt
      }
    }
  }
`;
