/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import arrowDown from '../../../../assets/icons/arrow-down.svg';
import { NOTES_MAX_RESULTS } from '../../../../config/constants';
import { useNotification } from '../../../../hooks/useNotification';
import useRouteNotes from '../../../../hooks/useRouteNotes';
import { userStateAtom } from '../../../../recoil/userData';
import { CREATE_NOTE_MUTATION } from '../../../../services/graphql/notes/notesMutation';
import { Button } from '../../common/Button';
import LoaderSmall from '../../LoaderSmall';
import NoteCard from './cards/NoteCard';

interface NotesProps {
  route_Id: string | undefined;
}

const Notes: React.FC<NotesProps> = ({ route_Id }) => {
  const { register, handleSubmit, reset } = useForm();
  const user = useRecoilValue(userStateAtom);
  const [createNote, { loading: loadingCreate }] =
    useMutation(CREATE_NOTE_MUTATION);

  const { notifySaveSuccess, notifySaveError } = useNotification();

  const {
    fetchMoreNotes,
    loading: loadingNotes,
    notes,
    refetchNotes,
    hasNextPage,
    errorNotes,
    totalNotes,
  } = useRouteNotes();

  const { t } = useTranslation('common');

  const onSubmit = (data: any) => {
    if (!data.timelineTextearea) {
      return;
    }
    createNote({
      variables: {
        note: data.timelineTextearea,
        userId: user?.id,
        routeId: route_Id,
      },
    }).then((dataResponse) => {
      if (dataResponse.data.createNote.status !== 'success') {
        notifySaveError();
        return;
      }
      notifySaveSuccess();
      reset();
      refetchNotes();
    });
  };
  return (
    <div>
      <div className="flex flex-col justify-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="relative mb-6 flex flex-col justify-center"
        >
          <div className="relative my-2">
            <textarea
              {...register('timelineTextearea')}
              autoCorrect="off"
              className="box-shadow h-[120px] w-full rounded-lg border-none py-4 pl-4 text-blacker placeholder:text-xs placeholder:text-greenextralight focus:shadow-custom focus:ring-0 md:h-[120px] md:pr-[225px]"
              id="timelineTextearea"
              name="timelineTextearea"
              placeholder={t('routes.route-add-note')}
            ></textarea>
            <div className="absolute bottom-5 right-5 flex items-center justify-center gap-3">
              <Button
                text={t('notes.addNote')}
                disabled={loadingCreate}
                loading={loadingCreate}
                type="submit"
                id="send-note"
                variant="green"
              />
            </div>
          </div>
        </form>
        {loadingNotes ? (
          <p>{t('notes.loading')}</p>
        ) : errorNotes ? (
          <p>{t('notes.error')}</p>
        ) : (
          notes &&
          [...notes].map((note: any) => {
            const createdAt = Number(note.createdAt);

            return (
              <NoteCard
                createdAt={createdAt}
                note={note.note}
                id={note.id}
                user_name={note.user_name}
                key={note.id}
              />
            );
          })
        )}
        {loadingNotes && <LoaderSmall />}
        {hasNextPage && totalNotes > NOTES_MAX_RESULTS && (
          <button
            className="flex text-xs italic"
            onClick={() => fetchMoreNotes()}
          >
            <img src={arrowDown} alt="arrow down" />
            {t('notes.loadMore')}
          </button>
        )}
      </div>
    </div>
  );
};

export default Notes;
