import 'react-datepicker/dist/react-datepicker.css';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import clientIcon from '../../../assets/icons/clients-small.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import {
  availableDaysOptions,
  serviceZoneOptions,
} from '../../../config/constants';
import { useModal } from '../../../hooks/useModal';
import { editRouteFiltersState } from '../../../recoil/editRouteFilter';
import { needRefreshState } from '../../../recoil/needRefresh';
import { convertDateToString } from '../../../utils/convertDate';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import DateField from '../../ui/common/DateField';
import DropdownMultiple from '../../ui/common/DropdownMultiple';
import InputField from '../../ui/common/InputField';
import Toggle from '../../ui/common/Toggle';

const FilterEditRoute = () => {
  const { t } = useTranslation('common');

  // Form title
  const breadcrumbTitle = `${t('routes.title-h1')} > ${t(
    'modalFilterEditRoutes.title',
  )}`;

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  // Filters
  const [filters, setFilters] = useRecoilState(editRouteFiltersState);

  const [availableDays, setAvailableDays] = useState<
    string[] | undefined | null
  >(undefined);
  const [routeZone, setRouteZone] = useState<string[] | undefined | null>(
    undefined,
  );
  const [readyToVisit, setReadyToVisit] = useState<boolean | undefined | null>(
    undefined,
  );
  const [estimatedGalTo, setEstimatedGalTo] = useState<
    number | undefined | null
  >(undefined);
  const [estimatedGalFrom, setEstimatedGalFrom] = useState<
    number | undefined | null
  >(undefined);
  const [lastServiceBefore, setLastServiceBefore] = useState<
    string | undefined | null
  >(undefined);

  useEffect(() => {
    if (filters?.availableDays) {
      const updatedAvailableDaysFilter = [...filters.availableDays];
      setAvailableDays(updatedAvailableDaysFilter);
    }
    if (filters?.last_serviceBefore) {
      setLastServiceBefore(filters.last_serviceBefore);
    }
    if (filters?.ready_to_visit !== undefined) {
      setReadyToVisit(filters.ready_to_visit);
    }

    if (filters?.estimated_gallons_to) {
      setEstimatedGalTo(filters.estimated_gallons_to);
    }
    if (filters?.estimated_gallons_from) {
      setEstimatedGalFrom(filters.estimated_gallons_from);
    }
    if (filters?.route_zone) {
      const updatedRouteZone = [...filters.route_zone];
      setRouteZone(updatedRouteZone);
    }
  }, [filters]);

  const handleFiltersSave = () => {
    let newFilters: Record<string, any> = {};

    newFilters = { ...filters };

    if (availableDays) {
      if (availableDays.length > 0) {
        newFilters.availableDays = availableDays;
      } else {
        delete newFilters.availableDays;
      }
    } else {
      delete newFilters.availableDays;
    }

    if (routeZone) {
      if (routeZone.length > 0) {
        newFilters.route_zone = routeZone;
      } else {
        delete newFilters.route_zone;
      }
    } else {
      delete newFilters.route_zone;
    }

    if (readyToVisit) {
      newFilters.ready_to_visit = readyToVisit;
    } else {
      delete newFilters.ready_to_visit;
    }

    if (estimatedGalTo) {
      newFilters.estimated_gallons_to = estimatedGalTo;
    } else {
      delete newFilters.estimated_gallons_to;
    }

    if (estimatedGalFrom) {
      newFilters.estimated_gallons_from = estimatedGalFrom;
    } else {
      delete newFilters.estimated_gallons_from;
    }

    if (lastServiceBefore) {
      newFilters.last_serviceBefore = lastServiceBefore;
    } else {
      delete newFilters.last_serviceBefore;
    }

    if (Object.keys(newFilters).length > 0) {
      setFilters(newFilters);
    } else {
      setFilters(null);
    }
    closeOrder();
  };

  // ==== Handlers ====

  const handleEstimatedGallonsTo = (value: number | string) => {
    if (typeof value === 'number') {
      setEstimatedGalTo(value);
    }
    if (typeof value === 'string') {
      setEstimatedGalTo(Number(value));
    }
  };

  const handleEstimatedGallonsFrom = (value: number | string) => {
    if (typeof value === 'number') {
      setEstimatedGalFrom(value);
    }
    if (typeof value === 'string') {
      setEstimatedGalFrom(Number(value));
    }
  };

  return (
    <>
      <Breadcrumb title={breadcrumbTitle} image={clientIcon} />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalFilterClient.filters')}
      </h1>

      {/* Last Service Before Date */}
      <div className="h-[60px]">
        <DateField
          onSelectChange={(date: any) => {
            setLastServiceBefore(convertDateToString(date));
          }}
          selectedValue={
            lastServiceBefore
              ? new Date(lastServiceBefore).getTime().toString()
              : ''
          }
          label={t('modalFilterEditRoutes.last-service-before')}
          placeholder={t('global.select-date')}
        />
      </div>

      {/* Available Days */}
      <div className="h-[60px]">
        <DropdownMultiple
          label={t('modalFilterEditRoutes.available-days')}
          onChange={setAvailableDays}
          options={availableDaysOptions}
          selectedValues={availableDays || undefined}
        />
      </div>

      {/* Route Zone */}
      <div className="h-[60px]">
        <DropdownMultiple
          label={t('modalFilterEditRoutes.route-zone')}
          onChange={setRouteZone}
          options={serviceZoneOptions}
          selectedValues={routeZone || undefined}
        />
      </div>

      {/* Ready To Visit */}
      <div className="h-[60px]">
        <Toggle
          label={t('modalFilterEditRoutes.ready-to-visit')}
          selectedValue={readyToVisit || false}
          onSelectChange={setReadyToVisit}
        />
      </div>

      {/* Estimated Gallons From */}
      <div className="h-[60px]">
        <InputField
          type="number"
          doNotUseSaveSelfButton
          label={t('modalFilterEditRoutes.estimated-gallons-from')}
          onSelectChange={handleEstimatedGallonsFrom}
          selectedValue={estimatedGalFrom}
        />
      </div>

      {/* Estimated Gallons To */}
      <div className="h-[60px]">
        <InputField
          type="number"
          doNotUseSaveSelfButton
          label={t('modalFilterEditRoutes.estimated-gallons-to')}
          onSelectChange={handleEstimatedGallonsTo}
          selectedValue={estimatedGalTo}
        />
      </div>

      <div className="mt-6 flex justify-end">
        <Button
          text={t('modalNewClient.save')}
          variant="green"
          icon={saveBigIcon}
          onClick={handleFiltersSave}
          type="button"
          id="save-edit-route-filters"
        />
      </div>
    </>
  );
};

export default FilterEditRoute;
