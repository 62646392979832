import { gql } from '@apollo/client';

export const GET_INCIDENTS_BY_TRUCK = gql`
  query GetIncidentsByTruckId(
    $truckId: String!
    $limit: Int
    $offset: Int
    $orderBy: String
    $orderDirection: String
  ) {
    getIncidentsByTruckId(
      truckId: $truckId
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      count
      data {
        id
        truck_id
        route_id
        repairedAt
        status
        incident_category
        incident_grade
        cost
        driver
        createdAt
        updatedAt
      }
      error
    }
  }
`;

export const GET_INCIDENT_BY_ID = gql`
  query GetTruckIncidentById($incidentId: String!) {
    getTruckIncidentById(incidentId: $incidentId) {
      status
      code
      data {
        id
        truck_id
        route_id
        repairedAt
        status
        incident_category
        incident_grade
        cost
        driver
        createdAt
        updatedAt
        route_name
      }
      error
    }
  }
`;
