import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import closeIcon from '../../assets/icons/close-square.svg';
import iconEdit from '../../assets/icons/edit.svg';
import iconWarning from '../../assets/icons/warning-red.svg';
import { editModalState } from '../../recoil/modalEdit';

function ModalEdit() {
  const { t } = useTranslation('common');

  const [modalState, setModalState] = useRecoilState(editModalState);

  const handleConfirm = () => {
    modalState.onConfirm();
    setModalState({ ...modalState, isOpen: false });
  };

  const handleCancel = () => {
    modalState.onCancel();
    setModalState({ ...modalState, isOpen: false });
  };

  return (
    <div>
      <div className="fixed inset-0 z-[100] flex items-center justify-center">
        <div
          className="fixed inset-0 bg-gray-800 opacity-50"
          onClick={handleCancel}
        ></div>
        <div className="fixed left-1/2 top-1/2 w-[350px] -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-[20px] bg-white p-6 shadow-lg md:px-10 md:py-14">
          <button
            onClick={handleCancel}
            className="absolute right-2 top-2 m-2 cursor-pointer text-lg text-gray-500"
          >
            <img src={closeIcon} alt="close icon" />
          </button>
          <div className="flex flex-col items-center justify-center gap-2">
            <img src={iconWarning} alt="warning icon" />
            <p className="mt-4 text-center text-lg font-bold">
              {modalState.title}
            </p>
            <p className="mb-4 mt-2 text-center text-xs">
              {modalState.message}
            </p>
            <div className="flex gap-4">
              <button
                className="flex items-center gap-2 rounded-md border border-green bg-white px-[10px] py-[6px] text-xs text-green hover:bg-redish hover:text-white"
                onClick={handleCancel}
              >
                {t('global.cancel')}
              </button>
              <button
                id="confirm"
                className="button-icon-reverse flex items-center gap-2 rounded-md bg-green px-[10px] py-[6px] text-xs text-white hover:bg-gold hover:text-blacker"
                onClick={handleConfirm}
              >
                <img className="h-[20px]" src={iconEdit} alt="trash icon" />
                {t('global.confirm')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalEdit;
