import { useMemo } from 'react';

import sortIcon from '../../../../assets/icons/sort.svg';
import sortASCIcon from '../../../../assets/icons/sort-asc.svg';
import sortDESCIcon from '../../../../assets/icons/sort-desc.svg';

type Props = {
  onClick?: () => void;
  title: string;
  sortable?: boolean;
  sorting?: string;
  accessor: string;
  sortingDirection?: string;
};

const DTHeader = ({
  onClick,
  title,
  sortable,
  sorting,
  accessor,
  sortingDirection,
}: Props) => {
  const getIcon = useMemo(() => {
    if (sorting === accessor) {
      if (sortingDirection?.toLowerCase() === 'asc') return sortASCIcon;
      if (sortingDirection?.toLowerCase() === 'desc') return sortDESCIcon;
    }
    return sortIcon;
  }, [accessor, sorting, sortingDirection]);

  return (
    <div className="flex gap-2">
      <span className="text-xs font-medium">{title}</span>
      {sortable && (
        <button onClick={onClick}>
          <img src={getIcon} className="" alt="sort icon" />
        </button>
      )}
    </div>
  );
};

export default DTHeader;
