import { gql } from '@apollo/client';

export const CREATE_SERVICE = gql`
  mutation CreateService(
    $service: String!
    $frequency: String!
    $zone: String!
    $clientId: String!
  ) {
    createService(
      service: $service
      frequency: $frequency
      zone: $zone
      clientId: $clientId
    ) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation UpdateService($key: String!, $value: String!, $serviceId: String!) {
    updateService(key: $key, value: $value, serviceId: $serviceId) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_SERVICE = gql`
  mutation DeleteService($serviceId: String!) {
    deleteService(serviceId: $serviceId) {
      status
      code
      data
      error
    }
  }
`;
