import { gql } from '@apollo/client';

export const GET_DOCUMENT_BY_CLIENTID = gql`
  query GetServiceVisits(
    $clientId: [String]!
    $limit: Int
    $offset: Int
    $startDate: String
    $endDate: String
    $deletedFilter: Boolean
    $typeFilter: [String]
    $nameFilter: String
    $orderBy: String
    $orderDirection: String
  ) {
    getDocuments(
      clientId: $clientId
      limit: $limit
      offset: $offset
      startDate: $startDate
      endDate: $endDate
      deletedFilter: $deletedFilter
      typeFilter: $typeFilter
      nameFilter: $nameFilter
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      count
      data {
        id
        client_id
        name
        stop_id
        type
        url
        date
        createdAt
        updatedAt
        deletedAt
      }
      error
    }
  }
`;
export const GET_DOCUMENTS = gql`
  query GetDocuments(
    $clientId: [String]
    $limit: Int
    $offset: Int
    $startDate: String
    $endDate: String
    $deletedFilter: Boolean
    $typeFilter: [String]
    $nameFilter: String
    $orderBy: String
    $orderDirection: String
  ) {
    getDocuments(
      clientId: $clientId
      limit: $limit
      offset: $offset
      startDate: $startDate
      endDate: $endDate
      deletedFilter: $deletedFilter
      typeFilter: $typeFilter
      nameFilter: $nameFilter
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      count
      data {
        id
        client_id
        name
        stop_id
        truck_id
        type
        url
        date
        createdAt
        updatedAt
        deletedAt
        client_name
        route_name
      }
      error
    }
  }
`;

export const GET_STOP_DOCUMENTS = gql`
  query GetStopDocuments($stopId: String!) {
    getStopDocuments(stopId: $stopId) {
      status
      code
      count
      data {
        id
        client_id
        name
        stop_id
        truck_id
        type
        url
        date
        createdAt
        updatedAt
        deletedAt
        client_name
        route_name
      }
      error
    }
  }
`;

export const GET_DOCUMENTS_BY_TRUCK_ID = gql`
  query GetDocumentsByTruckId(
    $truckId: String!
    $limit: Int
    $offset: Int
    $orderBy: String
    $orderDirection: String
  ) {
    getDocumentsByTruckId(
      truckId: $truckId
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      count
      data {
        id
        client_id
        name
        stop_id
        truck_id
        type
        url
        date
        createdAt
        updatedAt
        deletedAt
        client_name
        route_name
      }
      error
    }
  }
`;
