import { atom } from 'recoil';

export interface TruckFilters {
  nameFilter?: string | null;
  status?: string[] | null;
  service?: string[] | null;
  deleted?: boolean | null;
  model?: string[] | null;
  orderBy?: string | null;
  orderDirection?: 'ASC' | 'DESC' | null;
  [key: string]: string | string[] | boolean | null | undefined;
}

export const truckFiltersState = atom<TruckFilters | null>({
  key: '_truckFiltersState',
  default: null,
});
