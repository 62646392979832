import { gql } from '@apollo/client';

export const UPDATE_ROUTE_STATUS = gql`
  mutation UpdateRoute($routeId: String!, $status: String!) {
    updateRoute(routeId: $routeId, status: $status) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_ROUTES = gql`
  mutation DeleteRoute($routeId: [String]!) {
    deleteRoute(routeId: $routeId) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_ROUTE = gql`
  mutation UpdateRoute(
    $date: String
    $driverId: String
    $truckId: String
    $routeId: String!
    $stops: [StopInput]
    $status: String
    $truck_mileage: Int
    $estimated_gallons: Int
  ) {
    updateRoute(
      date: $date
      driverId: $driverId
      truckId: $truckId
      routeId: $routeId
      stops: $stops
      status: $status
      truck_mileage: $truck_mileage
      estimated_gallons: $estimated_gallons
    ) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_ROUTE = gql`
  mutation CreateRoute(
    $service: String!
    $date: String!
    $driverId: String!
    $truckId: String!
  ) {
    createRoute(
      service: $service
      date: $date
      driverId: $driverId
      truckId: $truckId
    ) {
      status
      code
      data
      error
    }
  }
`;
