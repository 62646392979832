import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import truckIcon from '../../assets/icons/truck.svg';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import { LoaderScreen } from '../../components/ui/LoaderScreen';
import DTTrucks from '../../components/ui/trucks/DTTrucks';

const TrucksView = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <section>
        <Breadcrumb title={t('trucks.title-h1')} image={truckIcon} />
        <Suspense fallback={<LoaderScreen />}>
          <DTTrucks />
        </Suspense>
      </section>
    </>
  );
};

export default TrucksView;
