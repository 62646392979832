/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import { useQuery } from '@apollo/client';
import { type Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import type { GetRouteByIdResponse, SingleStop } from '@/types/route';

import editIcon from '../../assets/icons/edit.svg';
import routeLogo from '../../assets/icons/routes.svg';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import Graph from '../../components/ui/common/Graph';
import GraphTruck from '../../components/ui/common/GraphTruck';
import { LoaderScreen } from '../../components/ui/LoaderScreen';
import RouteCard from '../../components/ui/routes/overall/cards/RouteCard';
import StopCard from '../../components/ui/routes/overall/cards/StopCard';
import Notes from '../../components/ui/routes/overall/Notes';
import Receipts from '../../components/ui/routes/overall/Receipts';
import RouteStatusDropdown from '../../components/ui/routes/RouteStatus';
import { FAT_BONES_SERVICE_NAME } from '../../config/constants';
import { selectedRouteState } from '../../recoil/selectedRoute';
import { GET_ROUTE_BY_ID } from '../../services/graphql/route/route-querys';
import Map from '../../services/map/Mapbox';
import NotFoundView from '../NotFoundView';

const RouteDetailView = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { id: routeId } = useParams();
  const setSelectedRoute = useSetRecoilState(selectedRouteState);

  const [graphData, setGraphData] = useState<number[]>([]);
  const [graphDataBarrel, setGraphDataBarrel] = useState<number[]>([]);

  // ==== HANDLERS ====

  const handleEditRoute = () => {
    navigate(`/dashboard/routes/edit/${routeId}`);
  };

  // === QUERIES ===

  const { data, loading, error, refetch } = useQuery<GetRouteByIdResponse>(
    GET_ROUTE_BY_ID,
    {
      variables: { routeId },
      fetchPolicy: 'network-only',
      onCompleted: (routeData) => {
        if (routeData?.getRouteById.data && routeId) {
          setSelectedRoute({
            route_name: routeData.getRouteById.data.route_name,
            route_id: routeId,
          });
          setGraphData(
            routeData.getRouteById.data.stops
              .map((stop) => stop.stop.picked_up_gallons)
              .filter((value: any) => value !== undefined && value !== null),
          );
          setGraphDataBarrel(
            routeData.getRouteById.data.stops
              .map((stop) => Number(stop.stop.picked_up_barrel))
              .filter((value: any) => value !== undefined && value !== null),
          );
        }
      },
    },
  );
  const route = data?.getRouteById.data;
  if (loading) return <LoaderScreen />;
  if (error) return <NotFoundView />;
  const stopCoords = route?.stops.map(
    (stop: {
      stop: { status: any };
      client_service: {
        client_coords: any;
        client_name: string;
      };
    }) => ({
      status: stop.stop.status,
      clientCoords: stop.client_service.client_coords,
      clientName: stop.client_service.client_name,
    }),
  );

  if (!route) return <NotFoundView />;

  return (
    <>
      <section>
        <div className="fixed top-0 z-20 w-full bg-white">
          <Breadcrumb
            title={t('routes.title-h1')}
            image={routeLogo}
            secondTitle={route?.route_name}
          />
        </div>
        <div className="my-7 flex min-w-[300px] flex-col justify-center gap-6 md:flex-row md:items-center">
          <h1 className="text-xl font-semibold">{route.route_name}</h1>
          {route.status && (
            <div className="flex w-full items-center  self-stretch">
              <RouteStatusDropdown
                routeId={routeId}
                initialStatus={route?.status}
                refetchRoutes={refetch}
              />
            </div>
          )}
        </div>

        {route && <RouteCard route={route} refetchRoutes={refetch} />}

        <div className="my-5 inline-flex h-6 w-[200px] flex-col items-start justify-start gap-2.5">
          <div className="inline-flex items-center justify-center gap-0.5 self-stretch">
            <div className="flex shrink grow basis-0 flex-row items-center gap-x-5 text-xl font-semibold text-zinc-800">
              {t('routes.route-route-stops')}
              <div
                className={`inline-flex cursor-pointer items-center justify-center gap-0.5 self-stretch rounded-md bg-greenextralight p-2 hover:bg-gold`}
                onClick={handleEditRoute}
              >
                <img src={editIcon} alt="Edit" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-row items-start justify-between gap-4">
          <div className="flex w-full flex-col">
            {route?.stops.map(
              (stop: SingleStop, index: Key | null | undefined) => (
                <StopCard
                  service={route.service}
                  key={index}
                  businessName={stop.client_service.client_name}
                  status={stop.stop.status}
                  phoneNumber={stop.client_service.client_phone}
                  address={stop.client_service.client_address}
                  estGallons={stop.stop.estimated_gallons}
                  estBarrels={Number(stop.stop.estimated_barrel)}
                  puBarrels={Number(stop.stop.picked_up_barrel)}
                  puGallons={stop.stop.picked_up_gallons}
                  paymentT={stop.stop.payment_type}
                  amount={stop.stop.payments}
                  paidAmount={stop.client_service.paid_amount}
                  paymentS={stop.stop.payment_status}
                  id={stop.stop.id}
                  clientId={stop.client_service.client_id}
                  paidClient={stop.client_service.paid_client}
                  container_size={stop.client_service.container_size}
                  serviceId={stop.client_service.id}
                  invoiceNumber={stop.stop.invoice_id}
                  refetchRoute={refetch}
                />
              ),
            )}
          </div>
          <div className="w-full">
            <Map stops={stopCoords || []} isNotSticky={true} />
          </div>
        </div>
        <div className="">
          <p className="text-xl font-semibold">
            {t('routes.route-driver-receipts')}
          </p>
          <Receipts route_Id={routeId} />
        </div>

        <p className="mb-2 mt-8 text-xl font-semibold">
          {t('routes.route-notes')}
        </p>
        <Notes route_Id={routeId} />

        {/* Metrics */}
        {route.service !== FAT_BONES_SERVICE_NAME && (
          <>
            {graphData.length > 0 ||
              (data?.getRouteById?.data?.percentage_picked_up !== undefined && (
                <h2 className="mb-2 mt-6 text-xl font-semibold">
                  {t('metrics.title')}
                </h2>
              ))}

            <div className="mb-6 flex flex-col gap-6 md:gap-12 lg:flex-row">
              {graphData && (
                <Graph
                  data={graphData}
                  qty={graphData
                    .reduce((a: number, b: number) => a + b, 0)
                    .toString()}
                  qtyType={t('metrics.gal')}
                  subTitle={t('metrics.total-oil')}
                />
              )}
              {data &&
                data.getRouteById.data &&
                data?.getRouteById.data.percentage_picked_up !== undefined && (
                  <GraphTruck
                    qty={data.getRouteById.data.percentage_picked_up}
                    qtyType="%"
                    subTitle={t('metrics.truck-capacity')}
                  />
                )}
            </div>
          </>
        )}
        {route.service === FAT_BONES_SERVICE_NAME && (
          <>
            {data?.getRouteById?.data?.estimated_barrel !== undefined && (
              <h2 className="mb-2 mt-6 text-xl font-semibold">
                {t('metrics.title')}
              </h2>
            )}

            <div className="mb-6 flex flex-col gap-6 md:gap-12 lg:flex-row">
              {graphDataBarrel && (
                <Graph
                  data={graphDataBarrel}
                  qty={graphDataBarrel
                    .reduce((a: number, b: number) => a + b, 0)
                    .toString()}
                  qtyType={t('metrics.barrel')}
                  subTitle={t('metrics.total-barrel')}
                />
              )}
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default RouteDetailView;
