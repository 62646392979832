import { gql } from '@apollo/client';

export const CREATE_TRUCK = gql`
  mutation CreateTruck($make: String!, $model: String!, $license: String!) {
    createTruck(make: $make, model: $model, license: $license) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_TRUCKS = gql`
  mutation DeleteTruck($truckId: [String]!) {
    deleteTruck(truckId: $truckId) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_TRUCK = gql`
  mutation UpdateTruck(
    $truckId: String!
    $oil_level: String
    $antifreeze_level: String
    $tire_status: String
    $def_level: String
    $gas_level: String
    $status: String
    $make: String
    $model: String
    $license: String
    $mileage: Int
    $tk: String
    $TKEXPDATE: String
    $oil_service: Boolean
    $oil_service_gallons: Int
    $fatBones_service: Boolean
    $fatBones_service_gallons: Int
    $grease_service: Boolean
    $grease_service_gallons: Int
    $hood_service: Boolean
    $hood_service_gallons: Int
  ) {
    updateTruck(
      truckId: $truckId
      oil_level: $oil_level
      antifreeze_level: $antifreeze_level
      tire_status: $tire_status
      def_level: $def_level
      gas_level: $gas_level
      mileage: $mileage
      status: $status
      make: $make
      model: $model
      license: $license
      tk: $tk
      TKEXPDATE: $TKEXPDATE
      oil_service: $oil_service
      oil_service_gallons: $oil_service_gallons
      fatBones_service: $fatBones_service
      fatBones_service_gallons: $fatBones_service_gallons
      grease_service: $grease_service
      grease_service_gallons: $grease_service_gallons
      hood_service: $hood_service
      hood_service_gallons: $hood_service_gallons
    ) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_TRUCK_DRIVERS = gql`
  mutation UpdateTruckDrivers($truckId: String!, $drivers: [String]) {
    updateTruckDrivers(truckId: $truckId, drivers: $drivers) {
      status
      code
      data
      error
    }
  }
`;

export const DOCUMENT_TRUCK_UPLOAD = gql`
  mutation UploadFile($file: Upload!, $truckId: String!, $type: String!) {
    uploadFile(file: $file, truckId: $truckId, type: $type) {
      status
      code
      data
      error
    }
  }
`;
