import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import routeLogo from '../../assets/icons/routes.svg';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import { LoaderScreen } from '../../components/ui/LoaderScreen';

const DTRoutes = React.lazy(
  () => import('../../components/ui/routes/DTRoutes'),
);

const RoutesView = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <section>
        <Breadcrumb title={t('routes.title-h1')} image={routeLogo} />
        <Suspense fallback={<LoaderScreen />}>
          <DTRoutes />
        </Suspense>
      </section>
    </>
  );
};

export default RoutesView;
