export type ClientStatusColors = {
  Active: string;
  Inactive: string;
  'Payment pending': string;
  ['']?: string;
};

export const statusColors: ClientStatusColors = {
  Active: '#00870D',
  Inactive: '#646464',
  'Payment pending': '#D30000',
};

export const getStatusColor = (
  statusValue: keyof ClientStatusColors | 'transparent'
): string => {
  switch (statusValue) {
    case 'Active':
      return statusColors.Active;
    case 'Inactive':
      return statusColors.Inactive;
    case 'Payment pending':
      return statusColors['Payment pending'];
    default:
      return 'transparent';
  }
};

// Generic status

export const genericStatusColors: Record<string, string> = {
  Active: '#00870D',
  Inactive: '#646464',
  'Payment pending': '#D30000',
};

export const getGenericStatusColor = (statusValue: any): string => {
  return genericStatusColors[statusValue] || 'transparent';
};

export const cashLeftColor = (cashLeft: number): string | undefined => {
  const COLORS = ['text-green', 'text-reyesgold', 'text-red'];
  if (cashLeft <= 99) {
    return COLORS[2];
  }
  if (cashLeft <= 199) {
    return COLORS[1];
  }
  return COLORS[0];
};
