import 'react-datepicker/dist/react-datepicker.css';

import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import calendarIcon from '../../../assets/icons/calendar.svg';
import clientIcon from '../../../assets/icons/clients-small.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { documentOptions } from '../../../config/constants';
import { useModal } from '../../../hooks/useModal';
import { clientDocumentsFiltersState } from '../../../recoil/clientDocumentsFilter';
import { needRefreshState } from '../../../recoil/needRefresh';
import { convertDateToString } from '../../../utils/convertDate';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import DropdownMultiple from '../../ui/common/DropdownMultiple';

const FilterClientDocuments = () => {
  const { t } = useTranslation('common');

  // Form title
  const breadcrumbTitle = `${t('clients.title-h1')}`;
  const breadcrumbSecondTitle = t('client-documents.title-h1');
  const breadcrumbThirdTitle = t('modalFilterClientDocuments.filters');

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  // Filters
  const [filters, setFilters] = useRecoilState(clientDocumentsFiltersState);

  const [startDateFilter, setStartDateFilter] = useState<string>('');
  const [endDateFilter, setEndDateFilter] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<string[]>([]);

  const handleFiltersSave = () => {
    let newFilters: Record<string, any> = {};

    newFilters = { ...filters };

    if (typeFilter.length > 0) {
      newFilters.typeFilter = typeFilter;
    } else {
      newFilters.typeFilter = null;
    }

    if (startDateFilter) {
      newFilters.startDate = startDateFilter;
    } else {
      newFilters.startDate = null;
    }

    if (endDateFilter) {
      newFilters.endDate = endDateFilter;
    } else {
      newFilters.endDate = null;
    }

    if (Object.keys(newFilters).length > 0) {
      setFilters(newFilters);
    } else {
      setFilters(null);
    }
    closeOrder();
  };

  const handleTypeChange = (value: string[]) => {
    setTypeFilter(value);
  };

  useEffect(() => {
    if (filters) {
      if (filters.typeFilter) {
        setTypeFilter(filters.typeFilter);
      }
      if (filters.startDate) {
        setStartDateFilter(filters.startDate);
      }
      if (filters.endDate) {
        setEndDateFilter(filters.endDate);
      }
    }
  }, [filters]);

  return (
    <>
      <Breadcrumb
        title={breadcrumbTitle}
        secondTitle={breadcrumbSecondTitle}
        thirdTitle={breadcrumbThirdTitle}
        image={clientIcon}
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalFilterClientDocuments.filters')}
      </h1>
      <div className="my-6 flex flex-col gap-2">
        {/* Type Filter */}
        <div className="h-[60px]">
          <div className="relative">
            <DropdownMultiple
              label={t('modalFilterClientDocuments.type')}
              placeholder={t('modalFilterClientDocuments.type-select')}
              onChange={handleTypeChange}
              options={documentOptions.map((option) => option.value)}
              selectedValues={[...typeFilter]}
            />
          </div>
        </div>
        {/* Start Date Filter Datepicker */}
        <div className="h-[60px]">
          <label htmlFor="startDate" className="block text-[14px] font-[600]">
            {t('modalFilterClientDocuments.startDate')}
          </label>
          <div className="flex items-center">
            <img src={calendarIcon} alt="calendar icon" />
            <DatePicker
              calendarStartDay={1}
              dateFormat={'yyyy-MM-dd'}
              className="h-[40px] w-full cursor-pointer border-none bg-white px-2 text-xs text-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
              selected={new Date(startDateFilter || Date.now())}
              onChange={(date: any) => {
                setStartDateFilter(convertDateToString(date));
              }}
            />
          </div>
        </div>

        {/* End Date Filter Datepicker */}
        <div className="h-[60px]">
          <label htmlFor="endDate" className="block text-[14px] font-[600]">
            {t('modalFilterClientDocuments.endDate')}
          </label>
          <div className="flex items-center">
            <img src={calendarIcon} alt="calendar icon" />
            <DatePicker
              calendarStartDay={1}
              dateFormat={'yyyy-MM-dd'}
              className="h-[40px] w-full cursor-pointer border-none bg-white px-2 text-xs text-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
              selected={new Date(endDateFilter || Date.now())}
              onChange={(date: any) => {
                setEndDateFilter(convertDateToString(date));
              }}
            />
          </div>
        </div>

        <div className="mt-6 flex justify-end">
          <Button
            text={t('modalNewClient.save')}
            variant="green"
            icon={saveBigIcon}
            onClick={handleFiltersSave}
            type="button"
            id="save-client-documents-filters"
          />
        </div>
      </div>
    </>
  );
};

export default FilterClientDocuments;
