import { useMutation, useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import type { GetIncientsByTruckQuery } from '@/types/incidents';
import type { LastIncidentsTruckTable } from '@/types/tables';

import { LAST_INCIDENTS_TABLE_MAX_RESULTS } from '../../../config/constants';
import { useNotification } from '../../../hooks/useNotification';
import { selectedClient } from '../../../recoil/navigation';
import { needRefreshState } from '../../../recoil/needRefresh';
import { UPDATE_INCIDENT } from '../../../services/graphql/incidents/incidents-mutations';
import { GET_INCIDENTS_BY_TRUCK } from '../../../services/graphql/incidents/incidents-querys';
import { convertToAmericanDateFormat } from '../../../utils/convertTimestamp';
import { formatNumberMoney } from '../../../utils/numberFormatter';
import DataTable from '../common/DataTable/DataTable';
import DTHeader from '../common/DataTable/DTHeader';
import TruckIncidentStatusDropdown from './TruckIncidentStatusDropdown';

type Props = {
  truckId: string;
  openEditIncidentModal: (id: string) => void;
};

const DTLastIncidents = ({ truckId, openEditIncidentModal }: Props) => {
  const { t } = useTranslation('common');
  const { notifyUpdateError, notifyUpdateOk } = useNotification();
  // States
  const [client] = useRecoilState(selectedClient);
  const [filters, setFilters] = useState({
    orderBy: '',
    orderDirection: '',
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  // Refresh data handlers
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh('incidents');
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: LAST_INCIDENTS_TABLE_MAX_RESULTS,
  });
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const needRefresh = useRecoilValue(needRefreshState);

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  // Mutations
  const [updateStatus] = useMutation<any>(UPDATE_INCIDENT, {
    onCompleted: () => {
      notifyUpdateOk();
      refreshOrder();
      endRefreshOrder();
    },
    onError: () => {
      notifyUpdateError();
    },
  });

  // Queries
  const {
    data: fetchData,
    loading,
    error,
    refetch,
  } = useQuery<GetIncientsByTruckQuery>(GET_INCIDENTS_BY_TRUCK, {
    variables: {
      truckId,
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'date',
      orderDirection: filters?.orderDirection || 'DESC',
    },
    skip: truckId === '',
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!fetchData?.getIncidentsByTruckId.data) {
      return;
    }

    const formattedData = fetchData?.getIncidentsByTruckId?.data.map(
      (item) => ({
        id: item.id,
        truckId: item.truck_id,
        incident_category: item.incident_category,
        status: item.status,
        incident_grade: item.incident_grade,
        driver: item.driver,
        cost: item.cost,
        date: item.createdAt,
      }),
    );

    setData(formattedData);
    setTotal(fetchData?.getIncidentsByTruckId?.count || 0);
  }, [fetchData, client]);

  const columnHelper = createColumnHelper<LastIncidentsTruckTable>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('incident_category', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info: any) => (
          <span className="cell-class cursor-pointer text-xs font-bold">
            {info.getValue()}
          </span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-incident-category')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="incident_category"
          />
        ),
      }),
      columnHelper.accessor('status', {
        size: 150,
        minSize: 100,
        maxSize: 200,
        cell: (info) => (
          <div className="flex items-center">
            <TruckIncidentStatusDropdown
              incidentId={info.row.original.id}
              truckId={info.row.original.truckId}
              initialStatusValue={info.getValue()}
              mutation={updateStatus}
              key={info.row.original.id}
            />
          </div>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-status')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="status"
          />
        ),
      }),
      columnHelper.accessor('incident_grade', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-incident-grade')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="incident_grade"
          />
        ),
        cell: (info: any) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
      }),
      columnHelper.accessor('driver', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-last-incident-driver')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="driver"
          />
        ),
      }),
      columnHelper.accessor('cost', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">
            {formatNumberMoney(info.getValue())}
          </span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            title={t('trucks.th-cost')}
            accessor="cost"
            onClick={() => handleChangeSortDirection(header.id)}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
          />
        ),
      }),
      columnHelper.accessor('date', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() !== null
              ? convertToAmericanDateFormat(info.getValue() as string)
              : ''}
          </span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-last-incidents-date')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="date"
          />
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: 'DESC',
      orderBy: 'date',
    }));
  }, []);

  useEffect(() => {
    if (!truckId) return;
    refetch({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...filters,
    });
  }, [filters, pageIndex, pageSize]);

  useEffect(() => {
    if (needRefresh === 'incidents') refetch();
  }, [needRefresh]);

  // FUNCTIONS
  const handleRowClick = (incident_id: string, event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    const isCellClick = target.classList.contains('cell-class');

    if (isCellClick) {
      // Open Modal for incident
      openEditIncidentModal(incident_id);
    }
  };

  return (
    <>
      <div className="min-h-[200px] overflow-visible">
        <DataTable
          error={error}
          handleRowClick={handleRowClick}
          loading={loading}
          table={table}
          total={total}
          tableId="LastIncidentsTable"
          sorting={{ ...filters }}
          loadingRows={pageSize}
        />
      </div>
    </>
  );
};

export default DTLastIncidents;
