import { atom } from 'recoil';

export interface ServiceFilters {
  limit?: number | null;
  offset?: number | null;
  licenseFilter?: string | null;
  statusFilter?: string | null;
  dateStartFilter?: string | null;
  dateEndFilter?: string | null;
  serviceFilter?: string | null;
  userIdFilter?: string | null;
  deletedFilter?: boolean | null;
  routeNameFilter?: string | null;
  orderBy?: string | null;
  orderDirection?: 'ASC' | 'DESC' | null;
  [key: string]: string | string[] | boolean | null | number | undefined;
}

export const serviceFiltersState = atom<ServiceFilters | null>({
  key: 'serviceFiltersStateAtom',
  default: null,
});
