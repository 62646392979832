import { gql } from '@apollo/client';

export const UPDATE_STOP_STATUS = gql`
  mutation UpdateStop($stopId: String!, $value: String!) {
    updateStop(key: "status", value: $value, stopId: $stopId, reason: null) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_STOP = gql`
  mutation UpdateStop(
    $key: String!
    $value: String!
    $stopId: String!
    $reason: String
  ) {
    updateStop(key: $key, value: $value, stopId: $stopId, reason: $reason) {
      status
      code
      data
      error
    }
  }
`;
