import { atom } from 'recoil';

export interface EditRouteFilters {
  limit?: number | undefined | null;
  offset?: number | undefined | null;
  client?: string | undefined | null;
  ready_to_visit?: boolean | undefined | null;
  availableDays?: string[] | undefined | null;
  last_serviceBefore?: string | undefined | null;
  estimated_gallons_to?: number | undefined | null;
  estimated_gallons_from?: number | undefined | null;
  serviceType?: string[] | string | undefined | null;
  route_zone?: string[] | undefined | null;
  orderBy?: string | undefined | null;
  orderDirection?: 'ASC' | 'DESC' | undefined | null;
  [key: string]: string | string[] | boolean | null | number | undefined;
}

export const editRouteFiltersState = atom<EditRouteFilters | null>({
  key: 'editRouteFiltersStateAtom',
  default: null,
});
