type Props = {
  title: string;
  value: string | number | boolean | undefined | null;
};

const NewStopCardElement = ({ title, value }: Props) => {
  return (
    <div className="flex min-w-[215px] justify-between gap-2 border-b-[1px] border-greenextralight">
      <span className="min-w-[120px] text-xs text-greenextralight">
        {title}
      </span>
      <span className="truncate text-xs">{value && value}</span>
    </div>
  );
};

export default NewStopCardElement;
