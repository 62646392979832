import { selector } from 'recoil';

import type { EditRouteFilters } from './editRouteFilter';
import { editRouteFiltersState } from './editRouteFilter';

export const cleanEditRouteFiltersState = selector({
  key: 'cleanEditRouteFilters',
  get: ({ get }) => {
    const currentFilters = get(editRouteFiltersState);

    // Clean the filters and return a new copy with everything undefined
    const cleanedFilters: EditRouteFilters = {};
    Object.keys(currentFilters || {}).forEach((key) => {
      cleanedFilters[key] = undefined;
    });

    return cleanedFilters;
  },
});
