import { useTranslation } from 'react-i18next';

import addIcon from '../../assets/icons/addIcon.svg';
import iconBreadcrumb from '../../assets/icons/dollar-square.svg';
import AddButton from '../../components/ui/common/AddButton';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import DTDisbursement from '../../components/ui/invoices/cashDeposit/DTDisbursement';
import DTPayments from '../../components/ui/invoices/cashDeposit/DTPayments';
import DTWithdraw from '../../components/ui/invoices/cashDeposit/DTWithdraw';
import { ImportantMetrics } from '../../components/ui/invoices/cashDeposit/ImportantMetrics';
import { useModal } from '../../hooks/useModal';

const CashDepositView = () => {
  const { t } = useTranslation('common');
  const { handleOpenNewWithDrawModal, handleOpenNewCashDisbursedModal } =
    useModal();

  return (
    <>
      <Breadcrumb image={iconBreadcrumb} title={t('cashDeposit.title-h1')} />

      <ImportantMetrics />
      <div className="flex flex-col gap-8 xl:flex-row ">
        <div className="w-full">
          <div className="mb-5 flex justify-between">
            <span className="font-inter text-xl font-bold">
              {t('withdraw.title-h1')}
            </span>
            <AddButton
              handleAction={handleOpenNewWithDrawModal}
              icon={addIcon}
              text={t('withdraw.button')}
            />
          </div>
          <DTWithdraw />
        </div>
        <div className="w-full">
          <div className="mb-5 flex justify-between">
            <span className="font-inter text-xl font-bold">
              {t('disbursed.title-h1')}
            </span>
            <AddButton
              handleAction={handleOpenNewCashDisbursedModal}
              icon={addIcon}
              text={t('disbursed.button')}
            />
          </div>
          <DTDisbursement />
        </div>
      </div>
      <div>
        <div className="mb-5 flex justify-between">
          <span className="font-inter text-xl font-bold">
            {t('cashPayments.title-h1')}
          </span>
        </div>
        <DTPayments />
      </div>
    </>
  );
};

export default CashDepositView;
