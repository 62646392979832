import 'react-datepicker/dist/react-datepicker.css';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import clientIcon from '../../../assets/icons/clients-small.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { statusClientOptions } from '../../../config/constants';
import { useModal } from '../../../hooks/useModal';
import { clientFiltersState } from '../../../recoil/clientsFilter';
import { needRefreshState } from '../../../recoil/needRefresh';
import { convertDateToString } from '../../../utils/convertDate';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import DateField from '../../ui/common/DateField';
import DropdownMultiple from '../../ui/common/DropdownMultiple';

const FilterClient = () => {
  const { t } = useTranslation('common');

  // Form title
  const breadcrumbTitle = `${t('clients.title-h1')}`;
  const breadcrumbSecondTitle = t('modalFilterClient.filters');

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  // Filters
  const [filters, setFilters] = useRecoilState(clientFiltersState);

  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [lastServiceStartDateFilter, setLastServiceStartDateFilter] =
    useState<string>('');
  const [lastServiceEndDateFilter, setLastServiceEndDateFilter] =
    useState<string>('');

  useEffect(() => {
    if (filters?.statusFilter) {
      const updatedStatusFilter = [...filters.statusFilter];

      setStatusFilter(updatedStatusFilter);
    }

    if (filters?.lastServiceStartDateFilter) {
      setLastServiceStartDateFilter(filters.lastServiceStartDateFilter);
    }

    if (filters?.lastServiceEndDateFilter) {
      setLastServiceEndDateFilter(filters.lastServiceEndDateFilter);
    }
  }, [filters]);

  const handleStatusFilterChange = (status: string[]) => {
    // const updatedFilter = statusFilter.includes(status)
    //   ? statusFilter.filter((item) => item !== status)
    //   : [...statusFilter, status];

    setStatusFilter(status);
  };

  const handleFiltersSave = () => {
    let newFilters: Record<string, any> = {};

    newFilters = { ...filters };

    if (statusFilter.length > 0) {
      newFilters.statusFilter = statusFilter;
    } else {
      newFilters.statusFilter = null;
    }

    if (lastServiceStartDateFilter) {
      newFilters.lastServiceStartDateFilter = lastServiceStartDateFilter;
    } else {
      newFilters.lastServiceStartDateFilter = null;
    }

    if (lastServiceEndDateFilter) {
      newFilters.lastServiceEndDateFilter = lastServiceEndDateFilter;
    } else {
      newFilters.lastServiceEndDateFilter = null;
    }

    if (Object.keys(newFilters).length > 0) {
      setFilters(newFilters);
    } else {
      setFilters(null);
    }
    closeOrder();
  };

  // const [statusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false);

  return (
    <>
      <Breadcrumb
        title={breadcrumbTitle}
        secondTitle={breadcrumbSecondTitle}
        image={clientIcon}
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalFilterClient.filters')}
      </h1>
      <div className="my-6 flex flex-col gap-2">
        {/* Status Dropdown */}
        <div className="h-[60px]">
          <DropdownMultiple
            options={statusClientOptions}
            onChange={handleStatusFilterChange}
            label={t('modalFilterClient.status')}
            selectedValues={statusFilter || []}
          />
        </div>
        {/* Start Date Filter Datepicker */}
        <DateField
          selectedValue={
            lastServiceStartDateFilter
              ? new Date(lastServiceStartDateFilter).getTime().toString()
              : ''
          }
          onSelectChange={(date: any) => {
            setLastServiceStartDateFilter(convertDateToString(date));
          }}
          label={t('modalFilterClient.startDateLastService')}
          placeholder={t('global.select-date')}
        />

        {/* End Date Filter Datepicker */}
        <DateField
          selectedValue={
            lastServiceEndDateFilter
              ? new Date(lastServiceEndDateFilter).getTime().toString()
              : ''
          }
          onSelectChange={(date: any) => {
            setLastServiceEndDateFilter(convertDateToString(date));
          }}
          label={t('modalFilterClient.endDateLastService')}
          placeholder={t('global.select-date')}
        />

        <div className="flex justify-end">
          <Button
            text={t('modalNewClient.save')}
            variant="green"
            icon={saveBigIcon}
            onClick={handleFiltersSave}
            type="button"
            id="save-client-filters"
          />
        </div>
      </div>
    </>
  );
};

export default FilterClient;
