import { atom } from 'recoil';

import type { TruckDrivers, TruckServices } from '../types/truck';

export const personSelectedToEdit = atom<string | null>({
  key: 'selectedPersonStateAtom',
  default: '',
});
export const equipmentSelectedToEdit = atom<string | null>({
  key: 'selectedEquipmentStateAtom',
  default: '',
});

export const serviceSelectedToEdit = atom<string | null>({
  key: 'selectedServiceStateAtom',
  default: '',
});

export const truckServiceToEdit = atom<TruckServices | null>({
  key: 'truckServiceToEdit',
  default: null,
});

export const truckDriversToEdit = atom<TruckDrivers | null>({
  key: 'truckDriversToEdit',
  default: null,
});

export const incidentSelectedToEdit = atom<{
  incidentId: string;
  truckId: string;
} | null>({
  key: 'incidentSelectedToEdit',
  default: { incidentId: '', truckId: '' },
});

export const cashDisbursedID = atom<string | null>({
  key: 'cashDisbursedID',
  default: '',
});
