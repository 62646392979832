import { atom } from 'recoil';

export interface WithDrawFilter {
  orderBy?: string | null;
  orderDirection?: 'ASC' | 'DESC' | null;
  limit?: number | null;
  offset?: number | null;
  [key: string]: string | string[] | boolean | null | number | undefined;
}

export const withDrawFilterState = atom<WithDrawFilter | null>({
  key: '_withDrawFiltersState',
  default: null,
});
