import { useRecoilValue } from 'recoil';

import type { ROLES } from '../config/permissions';
import { userRolesAtom } from '../recoil/userData';

export const usePermissions = () => {
  const userAuthority = useRecoilValue(userRolesAtom) as string[];

  const hasUserAuthority = () => {
    return userAuthority.length > 0;
  };

  const checkRole = (role: ROLES) => {
    return userAuthority.includes(role.valueOf());
  };

  return {
    hasUserAuthority,
    checkRole,
  };
};
