import { selector } from 'recoil';

import { type WorkerFilters, workerFiltersState } from './workersFilter';

export const cleanWorkersFiltersState = selector({
  key: 'cleanWorkersFilters',
  get: ({ get }) => {
    const currentFilters = get(workerFiltersState);

    // clean the filters and return a new copy with everything null
    const cleanedFilters: WorkerFilters = {};
    Object.keys(currentFilters || {}).forEach((key) => {
      cleanedFilters[key] = null;
    });

    return cleanedFilters;
  },
});
