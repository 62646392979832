import { WEEKDAYS_SHORTENED } from "../config/constants";

export function shortenWeekDay(day: string | string[]): string | null {
  if (typeof day === "string") {

    const shortenedDay = WEEKDAYS_SHORTENED[day];
    return shortenedDay || null;
  } else if (Array.isArray(day)) {

    const shortenedDays = day.map(
      (singleDay) => WEEKDAYS_SHORTENED[singleDay] || null
    );
    return shortenedDays.join(", ");
  } else {
    return null;
  }
}
