import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import saveIcon from '../../../assets/icons/save.svg';
import SignatureCanvas from './SignatureCanvas';
import WorkerSignature from './WorkerSignatureImage';

interface SignatureProps {
  setSignature: (val: any) => void;
  handleSignatureChange: () => void;
  signature?: string | null;
  canEditSignature?: boolean;
}
export const Signature = ({
  canEditSignature = false,
  setSignature,
  handleSignatureChange,
  signature,
}: SignatureProps) => {
  const { t } = useTranslation('common');
  const [editSignature, setEditSignature] = useState(false);

  const cancelSignature = () => {
    setEditSignature(false);
  };

  const onSave = () => {
    handleSignatureChange();
    setEditSignature(false);
  };

  return (
    <div className="flex flex-col gap-y-3.5 text-xl font-bold sm:h-[300px]">
      <p>{t('signature.signature')}</p>
      {editSignature ? (
        <div className="flex h-full w-full flex-col items-start justify-between gap-x-2">
          <SignatureCanvas
            onSignatureSave={setSignature}
            // clearText={t('signature.clear-canvas')}
          />
          <div className="flex w-full flex-col gap-x-2 sm:flex-row">
            <button
              onClick={onSave}
              id="saveCanvas"
              className="button-icon-reverse mt-2 inline-flex w-full items-center justify-center gap-2 rounded-[5px] border !bg-green p-2 text-white hover:border-transparent hover:!bg-gold hover:text-green"
            >
              <img className="h-[16px]" src={saveIcon} />
              <p className="text-[14px]">{t('signature.save-signature')}</p>
            </button>
            <button
              onClick={cancelSignature}
              className="mt-2 inline-flex w-full items-center justify-center gap-2 rounded-[5px] !bg-redish px-2.5 py-2 text-center text-sm font-semibold text-white hover:!bg-gold hover:!text-blacker"
            >
              <p className="text-[14px]">{t('signature.cancel-signature')}</p>
            </button>
          </div>
        </div>
      ) : (
        <div
          onClick={() => canEditSignature && setEditSignature(true)}
          className={`h-full ${canEditSignature ? 'cursor-pointer' : ''}`}
          id="editCanvas"
        >
          <WorkerSignature signature={signature} />
        </div>
      )}
    </div>
  );
};
