import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { needRefreshState } from '../../../../recoil/needRefresh';
import { GET_METRICS } from '../../../../services/graphql/cashdeposit/cashdeposit.queries';
import {
  formatNumber,
  formatNumberMoney,
} from '../../../../utils/numberFormatter';
import { cashLeftColor } from '../../../../utils/statusColors';

export const ImportantMetrics = () => {
  const { t } = useTranslation('common');

  const [data, setData] = useState<any>();

  const { data: queryData, refetch } = useQuery(GET_METRICS, {
    onCompleted: (info) => {
      setData(info.getImportantMetrics.data);
    },
    fetchPolicy: 'no-cache',
  });

  const [needRefresh] = useRecoilState(needRefreshState);

  useEffect(() => {
    refetch();
  }, [needRefresh]);

  useEffect(() => {
    if (queryData) {
      setData(queryData.getImportantMetrics.data);
    }
  }, [queryData]);

  return (
    <>
      <div className="mb-5 w-full">
        <span className="font-inter text-xl font-bold">
          {t('cashMetrics.title-h1')}
        </span>
        <div className="mt-5 flex flex-col md:flex-row gap-5">
          <div className="grid min-w-[70%] grid-cols-2 gap-5 pr-5">
            <div className="flex flex-col items-center rounded-xl p-2 shadow-custom">
              <span className="text-4xl font-[900] md:text-[38px]">
                {formatNumberMoney(data?.notDisbursed || 0)}
              </span>
              <p className="text-center text-xs">
                {t('cashMetrics.not-disbursed')}
              </p>
            </div>
            <div className="flex flex-col items-center rounded-xl p-2 shadow-custom">
              <span className="text-4xl font-[900] md:text-[38px]">
                {formatNumber(data?.diffWithdraw || 0)}{' '}
                {`Day${data?.diffWithdraw !== 1 ? 's' : ''}`}
              </span>
              <p className="text-center text-xs">
                {t('cashMetrics.last-withdraw')}
              </p>
            </div>
            <div className="flex flex-col items-center rounded-xl p-2 shadow-custom">
              <span className="text-4xl font-[900] md:text-[38px]">
                {formatNumberMoney(data?.cashLeft || 0)}
              </span>
              <p className="text-center text-xs">
                {t('cashMetrics.cash-disbursed')}
              </p>
            </div>
            <div className="flex flex-col items-center rounded-xl p-2 shadow-custom">
              <span className="text-4xl font-[900] md:text-[38px]">
                {formatNumber(data?.diffDisburse || 0)}{' '}
                {`Day${data?.diffDisburse !== 1 ? 's' : ''}`}
              </span>
              <p className="text-center text-xs">
                {t('cashMetrics.last-disbursement')}
              </p>
            </div>
          </div>
          <div className="flex max-h-[220px] w-full flex-col rounded-xl p-4 shadow-custom">
            <span className="font-inter text-xs font-normal">
              {t('cashMetrics.remaining-cash')}
            </span>
            <div className="customScroll overflow-y-scroll">
              {data?.drivers.map((driver: any) => {
                return (
                  <div
                    key={driver.driver}
                    className="my-2 flex items-center justify-between border-b-2 border-graylight"
                  >
                    <span className="font-inter text-xs font-bold">
                      {driver.driver}
                    </span>
                    <span
                      className={`mr-5 font-inter text-xs font-medium ${cashLeftColor(
                        driver.cash_left,
                      )}`}
                    >
                      {formatNumberMoney(driver.cash_left || 0)}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
