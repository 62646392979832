import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import actionIcon from '../../../assets/icons/action-button.svg';
import deleteIcon from '../../../assets/icons/trash.svg';

type Props = {
  handleRemove: () => void;
};

const DeleteButton = ({ handleRemove }: Props) => {
  const [deleteIconState, setDeleteIconState] = useState<boolean>(false);

  const { t } = useTranslation('common');

  return (
    <>
      <button
        onClick={() => setDeleteIconState(!deleteIconState)}
        className="button-icon-nohover inline-flex h-[41px] w-[46px] items-center justify-center rounded-[5px] border border-greenlight p-2"
      >
        <img src={actionIcon} alt="more icon" />
      </button>
      <button
        onClick={handleRemove}
        className={`absolute bottom-[-26px] right-0 h-[25px] items-center gap-1 rounded-md bg-white px-2 ${
          deleteIconState ? 'flex' : 'hidden'
        }`}
      >
        <img src={deleteIcon} alt="delete icon" />
        <span className="select-none text-sm font-normal">
          {t('clients.delete-button')}
        </span>
      </button>
    </>
  );
};

export default DeleteButton;
