import { useMutation, useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { CLIENTS_TABLES_MAX_RESULTS } from '../../../../config/constants';
import { useNotification } from '../../../../hooks/useNotification';
import { selectedClient } from '../../../../recoil/navigation';
import { serviceFiltersState } from '../../../../recoil/serviceFilter';
import { DOCUMENT_DELETE } from '../../../../services/graphql/document/document-mutations';
import { GET_SERVICE_VISITS } from '../../../../services/graphql/service/service-querys';
import { UPDATE_STOP_STATUS } from '../../../../services/graphql/stops/stops-mutation';
import type { GetServiceVisitsResponse } from '../../../../types/service';
import type { OilDisposalTable } from '../../../../types/tables';
import { convertToAmericanDateFormat } from '../../../../utils/convertTimestamp';
import DataTable from '../../common/DataTable/DataTable';
import DTHeader from '../../common/DataTable/DTHeader';
import DocumentActions from '../../common/DocumentActions';
import StopStatusDropdown from '../StopStatusDropdown';

type Props = {
  serviceId: string;
};

const DTFatBone = ({ serviceId }: Props) => {
  const { t } = useTranslation('common');
  const {
    notifySaveError,
    notifySaveSuccess,
    notifyDeleteError,
    notifyDeleteOk,
  } = useNotification();

  // States
  const [client] = useRecoilState(selectedClient);
  const [filters, setFilters] = useRecoilState(serviceFiltersState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: CLIENTS_TABLES_MAX_RESULTS,
  });
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  // Queries
  const {
    data: fetchData,
    loading,
    error,
    refetch,
  } = useQuery<GetServiceVisitsResponse>(GET_SERVICE_VISITS, {
    variables: {
      serviceId,
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'date',
      orderDirection: filters?.orderDirection || 'DESC',
    },
    fetchPolicy: 'no-cache',
  });

  // Mutations
  const [updateStop] = useMutation(UPDATE_STOP_STATUS, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: () => {
      notifySaveSuccess();
    },
  });

  const [deleteDocument] = useMutation(DOCUMENT_DELETE, {
    onError: () => {
      notifyDeleteError();
    },
    onCompleted: () => {
      refetch();
      notifyDeleteOk();
    },
  });

  useEffect(() => {
    if (!fetchData?.getServiceVisits.data) {
      return;
    }

    const formattedData = fetchData?.getServiceVisits?.data.map((item) => ({
      id: item.id,
      route_name: item.route_name,
      status: item.status,
      gallons_picked_up: item.gallons_picked_up,
      payments: item.payments,
      driver: item.driver,
      date: item.date,
      document: item.documents[0],
    }));

    setData(formattedData);
    setTotal(fetchData?.getServiceVisits?.count || 0);
  }, [fetchData, client]);

  const columnHelper = createColumnHelper<OilDisposalTable>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('route_name', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info: any) => (
          <span className="text-xs font-bold">{info.getValue()}</span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-oil-disposal.th-route-name')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="route_name"
          />
        ),
      }),
      columnHelper.accessor('status', {
        size: 150,
        minSize: 200,
        maxSize: 200,
        cell: (info) => (
          <div className="flex items-center">
            <StopStatusDropdown
              stopId={info.row.original.id}
              initialStatusValue={info.row.original.status}
              mutation={updateStop}
              key={info.row.original.id}
            />
          </div>
        ),

        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-oil-disposal.th-status')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="status"
          />
        ),
      }),
      columnHelper.accessor('driver', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-oil-disposal.th-driver')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="driver"
          />
        ),
      }),
      columnHelper.accessor('date', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() !== null
              ? convertToAmericanDateFormat(info.getValue() as string)
              : ''}
          </span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-oil-disposal.th-date')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="date"
          />
        ),
      }),
      columnHelper.accessor('document', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <DocumentActions
            documentId={info.row.original.document?.id}
            documentName={info.row.original.document?.name}
            url={info.row.original.document?.url}
            deleteDocument={deleteDocument}
          />
        ),
        header: () => (
          <DTHeader
            title={t('client-oil-disposal.th-document')}
            accessor="document"
          />
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: 'DESC',
      orderBy: 'date',
    }));
  }, []);

  useEffect(() => {
    refetch({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...filters,
    });
  }, [filters, pageIndex, pageSize]);

  return (
    <>
      <div className="min-h-[200px] min-w-[488px]">
        <DataTable
          error={error}
          loading={loading}
          table={table}
          total={total}
          tableId="fatboneTable"
          sorting={{ ...filters }}
          loadingRows={total || pageSize}
        />
      </div>
    </>
  );
};

export default DTFatBone;
