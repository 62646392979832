import { atom } from 'recoil';

export interface Filters {
  licenseFilter?: string[] | null | undefined;
  statusFilter?: string[] | null | undefined;
  dateStartFilter?: string | null | undefined;
  dateEndFilter?: string | null | undefined;
  serviceFilter?: string[] | null | undefined;
  userIdFilter?: string[] | null | undefined;
  deletedFilter?: boolean | null | undefined;
  routeNameFilter?: string | null | undefined;
  orderBy?: string | null;
  orderDirection?: 'ASC' | 'DESC' | null;
  [key: string]: string | string[] | boolean | null | undefined;
}

export const routeFilterState = atom<Filters | null>({
  key: '_routesFilterState',
  default: null,
});
