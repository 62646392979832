import { Auth0Provider } from '@auth0/auth0-react';
import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import ApolloWrapper from './ApolloWrapper';
import App from './App';
import common_es from './translations/en/common.json';

i18next.init({
  interpolation: {
    escapeValue: false,
  },
  lng: 'en',
  resources: {
    en: {
      common: common_es,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI || '',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
      }}
    >
      <RecoilRoot>
        <ApolloWrapper>
          <BrowserRouter>
            <I18nextProvider i18n={i18next}>
              <App />
            </I18nextProvider>
          </BrowserRouter>
        </ApolloWrapper>
      </RecoilRoot>
    </Auth0Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
