import { atom } from 'recoil';

export interface SettingEquipmentFilters {
  nameFilter?: string | null;
  orderBy?: string | null;
  orderDirection?: 'ASC' | 'DESC' | null;
  limit?: number | null;
  offset?: number | null;
  [key: string]: string | string[] | boolean | null | number | undefined;
}

export const settingEquipmentFilter = atom<SettingEquipmentFilters | null>({
  key: '_settingEquipmentFilters',
  default: null,
});
