export const extractIdFromNewInfo = (newInfo: string): string | null => {

    const infoObject = JSON.parse(newInfo);

    if (infoObject && infoObject.id) {

      return infoObject.id;
      
    } else {

      return null;
    }

};
