import { gql } from '@apollo/client';

export const CREATE_NOTE_MUTATION = gql`
  mutation CreateNote(
    $note: String!
    $userId: String!
    $routeId: String
    $clientId: String
  ) {
    createNote(
      note: $note
      userId: $userId
      routeId: $routeId
      clientId: $clientId
    ) {
      status
      code
      data
      error
    }
  }
`;
