import { gql } from '@apollo/client';

export const SET_AS_READED = gql`
  mutation SetNotificationReaded($notificationId: Int!) {
    setNotificationReaded(notificationId: $notificationId) {
      code
      data
      error
    }
  }
`;

export const SET_ALL_READED = gql`
  mutation SetAllNotificationReaded($userId: String!) {
    setAllNotificationReaded(userId: $userId) {
      status
      code
      data
      error
    }
  }
`;
