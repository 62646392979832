import iconWarning from '../../../assets/icons/warning-red.svg';

interface ErrorMessageProps {
  text: string;
  show: boolean;
}

export const ErrorMessage = ({ text, show }: ErrorMessageProps) => {
  if (!show) return <></>;
  return (
    <div className="flex flex-row items-center gap-x-1 rounded-md border border-redish bg-errorred px-2 py-0.5">
      <img src={iconWarning} alt="Warning icon" className="h-5 w-5" />
      <p className="text-sm text-redish">{text}</p>
    </div>
  );
};
