import { selector } from 'recoil';

import type { InvoicesFilters } from './invoicesFilter';
import { invoicesFiltersState } from './invoicesFilter';

export const cleanInvoicesFiltersState = selector({
  key: 'cleanInvoicesFilters',
  get: ({ get }) => {
    const currentFilters = get(invoicesFiltersState);

    // Clean the filters and return a new copy with everything null
    const cleanedFilters: InvoicesFilters = {};
    Object.keys(currentFilters || {}).forEach((key) => {
      cleanedFilters[key] = null;
    });

    return cleanedFilters;
  },
});
