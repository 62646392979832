import { atom } from 'recoil';

export interface SelectedRoute {
  route_id: string;
  route_name: string;
}

export const selectedRouteState = atom<SelectedRoute | null>({
  key: 'selectedRouteStateAtom',
});
