import { useMutation, useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { CLIENTS_TABLES_MAX_RESULTS } from '../../../../config/constants';
import { useNotification } from '../../../../hooks/useNotification';
import { selectedClient } from '../../../../recoil/navigation';
import { serviceFiltersState } from '../../../../recoil/serviceFilter';
import { DOCUMENT_DELETE } from '../../../../services/graphql/document/document-mutations';
import { GET_SERVICE_VISITS } from '../../../../services/graphql/service/service-querys';
import { UPDATE_STOP_STATUS } from '../../../../services/graphql/stops/stops-mutation';
import type { GetServiceVisitsResponse } from '../../../../types/service';
import type { HoodCleaningTable } from '../../../../types/tables';
import { convertToAmericanDateFormat } from '../../../../utils/convertTimestamp';
import DataTable from '../../common/DataTable/DataTable';
import DTHeader from '../../common/DataTable/DTHeader';
import DocumentActions from '../../common/DocumentActions';
import StopStatusDropdown from '../StopStatusDropdown';

type Props = {
  serviceId: string;
};

const DTHoodCleaning = ({ serviceId }: Props) => {
  const { t } = useTranslation('common');
  const {
    notifySaveError,
    notifySaveSuccess,
    notifyDeleteError,
    notifyDeleteOk,
  } = useNotification();

  // States
  const [client] = useRecoilState(selectedClient);
  const [filters, setFilters] = useRecoilState(serviceFiltersState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: CLIENTS_TABLES_MAX_RESULTS,
  });
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  // Queries
  const {
    data: fetchData,
    loading,
    error,
    refetch,
  } = useQuery<GetServiceVisitsResponse>(GET_SERVICE_VISITS, {
    variables: {
      serviceId,
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'date',
      orderDirection: filters?.orderDirection || 'DESC',
    },
    fetchPolicy: 'no-cache',
  });

  // Mutations
  const [updateStop] = useMutation(UPDATE_STOP_STATUS, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: () => {
      notifySaveSuccess();
    },
  });

  const [deleteDocument] = useMutation(DOCUMENT_DELETE, {
    onError: () => {
      notifyDeleteError();
    },
    onCompleted: () => {
      refetch();
      notifyDeleteOk();
    },
  });

  useEffect(() => {
    if (!fetchData?.getServiceVisits.data) {
      return;
    }

    const formattedData = fetchData?.getServiceVisits?.data.map((item) => ({
      id: item.id,
      route_name: item.route_name,
      status: item.status,
      driver: item.driver,
      date: item.date,
      document: item.documents[0],
    }));

    setData(formattedData);
    setTotal(fetchData?.getServiceVisits?.count || 0);
  }, [fetchData, client]);

  const columnHelper = createColumnHelper<HoodCleaningTable>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('route_name', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info: any) => (
          <span className="text-xs font-bold">{info.getValue()}</span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-hood-cleaning.th-route-name')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="route_name"
          />
        ),
      }),
      columnHelper.accessor('status', {
        size: 150,
        minSize: 200,
        maxSize: 200,
        cell: (info) => (
          <div className="flex items-center">
            <StopStatusDropdown
              stopId={info.row.original.id}
              initialStatusValue={info.row.original.status}
              mutation={updateStop}
              key={info.row.original.id}
            />
          </div>
        ),

        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-hood-cleaning.th-status')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="status"
          />
        ),
      }),
      columnHelper.accessor('driver', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-hood-cleaning.th-driver')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="driver"
          />
        ),
      }),
      columnHelper.accessor('date', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() !== null
              ? convertToAmericanDateFormat(info.getValue() as string)
              : ''}
          </span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-hood-cleaning.th-date')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="date"
          />
        ),
      }),
      columnHelper.accessor('document', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <DocumentActions
            documentId={info.row.original.document?.id}
            documentName={info.row.original.document?.name}
            url={info.row.original.document?.url}
            deleteDocument={deleteDocument}
          />
        ),
        header: () => (
          <DTHeader
            title={t('client-hood-cleaning.th-document')}
            accessor="document"
          />
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: 'DESC',
      orderBy: 'date',
    }));
  }, []);

  useEffect(() => {
    refetch({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...filters,
    });
  }, [filters, pageIndex, pageSize]);

  return (
    <>
      <div className="min-h-[200px] min-w-[488px]">
        {/* <table
          style={{ tableLayout: 'auto', width: '100%' }}
          className="w-full table-fixed"
        >
          <thead className="h-[37px] bg-green text-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  let className = '';

                  if (index === 0) {
                    className = 'rounded-l-lg';
                  } else if (index === headerGroup.headers.length - 1) {
                    className = 'rounded-r-lg';
                  }

                  return (
                    <th
                      colSpan={header.colSpan}
                      style={{
                        width: header.getSize(),
                      }}
                      key={header.id}
                      className={`px-2 text-left ${className}`}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td colSpan={table.getAllColumns().length}>
                  <p>Error: {error.message}</p>
                </td>
              </tr>
            ) : (
              total === 0 && (
                <tr className="h-[40px]">
                  <td colSpan={table.getAllColumns().length}>
                    <p className="text-xs ">{t('global.no-data')}</p>
                  </td>
                </tr>
              )
            )}

            {table.getRowModel().rows.map((row) => (
              <tr
                className="h-[59px] border-b-[1px] border-graylight hover:bg-graydark"
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="cell-class px-2">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header, index) => {
                  let className = '';

                  if (index === 0) {
                    className = 'rounded-l-lg';
                  } else if (index === footerGroup.headers.length - 1) {
                    className = 'rounded-r-lg';
                  }

                  return (
                    <th key={header.id} className={`px-2 ${className}`}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext(),
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        </table>
        <div className="my-6">
          <div className="flex items-center gap-2">
            <span className="text-[14px] font-medium">Page</span>
            <div className="flex h-[29px] items-center justify-center rounded-[5px] border border-greenlight">
              <button
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                <img src={beforemaxIcon} alt="before icon" />
              </button>
              <button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <img src={beforeIcon} alt="before icon" />
              </button>
              <div className="flex items-center gap-1">
                <div className="dropdown-container relative inline-flex">
                  <select
                    value={table.getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      table.setPageIndex(page);
                    }}
                    style={{
                      backgroundImage: `url(${arrowDownIcon})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right 2px center',
                      backgroundSize: '16px 16px',
                      cursor: 'pointer',
                    }}
                    className="inline-block h-[24px] w-[40px] border-none pr-1 text-center text-[14px] font-normal focus:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0"
                  >
                    {Array.from(
                      { length: table.getPageCount() },
                      (_, index) => (
                        <option
                          className="text-center"
                          key={index + 1}
                          value={index + 1}
                        >
                          {index + 1}
                        </option>
                      ),
                    )}
                  </select>
                </div>
              </div>
              <button
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <img src={nextIcon} alt="next icon" />
              </button>
              <button
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                <img src={nextmaxIcon} alt="next icon" />
              </button>
            </div>

            <span className="text-[14px] font-medium">Show</span>
            <select
              className="inline-block h-[28px] w-[63px] rounded-[5px] border border-greenlight pl-2 pr-4 text-center text-[14px] font-normal focus:outline-none focus:ring-0 focus:ring-offset-0"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
              style={{
                backgroundImage: `url(${arrowDownIcon})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right 10px center',
                backgroundSize: '16px 16px',
                cursor: 'pointer',
              }}
            >
              {[10, 20, 30, 40, 50].map((_pageSize) => (
                <option key={_pageSize} value={_pageSize}>
                  {_pageSize}
                </option>
              ))}
            </select>
            {loading && (
              <div>
                <LoaderSmall />
              </div>
            )}
          </div>
        </div> */}
        <DataTable
          error={error}
          loading={loading}
          table={table}
          total={total}
          tableId="HoodCleaningTable"
          sorting={{ ...filters }}
          loadingRows={total || pageSize}
        />
      </div>
    </>
  );
};

export default DTHoodCleaning;
