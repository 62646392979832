import { useRecoilState } from 'recoil';

import closeFilterIcon from '../../../assets/icons/close-filter.svg';
import { useFilterName } from '../../../hooks/useFilterName';
import { invoicesFiltersState } from '../../../recoil/invoicesFilter';

type Props = {
  filterName: string;
  filterValue: string | string[] | boolean | null | number;
};

const FilterMark = ({ filterName, filterValue }: Props) => {
  const [filters, setFilters] = useRecoilState(invoicesFiltersState);

  let finalFilterVale;

  const filterNameParsed = useFilterName(filterName);

  if (Array.isArray(filterValue)) {
    finalFilterVale = filterValue.join(', ');
  } else {
    finalFilterVale = filterValue;
  }

  const closeFilter = () => {
    const updatedFilters = { ...filters };
    updatedFilters[filterName] = null;
    setFilters(updatedFilters);
  };

  return (
    <div className="flex h-[29px] items-center justify-center gap-2 rounded-xl bg-green px-2 py-1 text-xs font-light text-white">
      <span>
        {filterNameParsed}: {finalFilterVale}
      </span>
      <button onClick={closeFilter}>
        <img src={closeFilterIcon} alt="Close filter" />
      </button>
    </div>
  );
};

export default FilterMark;
