import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import calendarAddIcon from '../../../../assets/icons/calendar-add.svg';
import phoneIcon from '../../../../assets/icons/call.svg';
import clockIcon from '../../../../assets/icons/clock.svg';
import editIcon from '../../../../assets/icons/edit.svg';
import emailIcon from '../../../../assets/icons/email.svg';
import locationIcon from '../../../../assets/icons/location.svg';
import saveIcon from '../../../../assets/icons/save-small.svg';
import { GET_GEOCODING_SUGGESTIONS } from '../../../../services/graphql/address/address-querys';
import type { ClientQuery } from '../../../../types/client';
import { convertToTwelveHourFormat } from '../../../../utils/convertHour';
import { convertToAmericanDateFormatWithTime } from '../../../../utils/convertTimestampWithTime';
import StatusDropdown from '../ClientStatusDropdown';

type Props = {
  data?: ClientQuery;
  updateClient: (arg: any) => void;
  client: string;
};

const ClientFixedCard = ({ updateClient, client, data }: Props) => {
  const { t } = useTranslation('common');

  // === VISUAL NOTIFICATIONS ===
  const notifyTimeSaveOk = () =>
    toast.success(t('clients-overall.notify-time-saved'));
  const notifyTimeSaveWarning = () =>
    toast.warn(t('clients-overall.notify-time-warning'));
  const notifySaveOk = () =>
    toast.success(t('clients-overall.notify-saved-ok'), {
      toastId: 'notifyStatusSaveOk',
    });

  // === STATES ===
  // Phone
  const [phone, setPhone] = useState<string>(
    data?.getClientById?.data.client.phone || '',
  );
  const [isEditingPhone, setIsEditingPhone] = useState<boolean>(false);
  const [isHoveringPhone, setIsHoveringPhone] = useState<boolean>(false);

  // Email
  const [email, setEmail] = useState<string>(
    data?.getClientById?.data.client.email || '',
  );
  const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);
  const [isHoveringEmail, setIsHoveringEmail] = useState<boolean>(false);

  // Address
  const [address, setAddress] = useState<string>(
    data?.getClientById?.data.client.address || '',
  );
  const [isEditingAddress, setIsEditingAddress] = useState<boolean>(false);
  const [isHoveringAddress, setIsHoveringAddress] = useState<boolean>(false);

  // Monday
  const [mondayOpeningTime, setMondayOpeningTime] = useState<string>(
    data?.getClientById?.data.client.monday_opening || '',
  );
  const [mondayClosingTime, setMondayClosingTime] = useState<string>(
    data?.getClientById?.data.client.monday_closing || '',
  );
  const [isEditingMonday, setIsEditingMonday] = useState<boolean>(false);
  const [isHoveringMonday, setIsHoveringMonday] = useState<boolean>(false);

  // Tuesday
  const [tuesdayOpeningTime, setTuesdayOpeningTime] = useState<string>(
    data?.getClientById?.data.client.tuesday_opening || '',
  );
  const [tuesdayClosingTime, setTuesdayClosingTime] = useState<string>(
    data?.getClientById?.data.client.tuesday_closing || '',
  );
  const [isEditingTuesday, setIsEditingTuesday] = useState<boolean>(false);
  const [isHoveringTuesday, setIsHoveringTuesday] = useState<boolean>(false);

  // Wednesday
  const [wednesdayOpeningTime, setWednesdayOpeningTime] = useState<string>(
    data?.getClientById?.data.client.wednesday_opening || '',
  );
  const [wednesdayClosingTime, setWednesdayClosingTime] = useState<string>(
    data?.getClientById?.data.client.wednesday_closing || '',
  );
  const [isEditingWednesday, setIsEditingWednesday] = useState<boolean>(false);
  const [isHoveringWednesday, setIsHoveringWednesday] =
    useState<boolean>(false);

  // Thursday
  const [thursdayOpeningTime, setThursdayOpeningTime] = useState<string>(
    data?.getClientById?.data.client.thursday_opening || '',
  );
  const [thursdayClosingTime, setThursdayClosingTime] = useState<string>(
    data?.getClientById?.data.client.thursday_closing || '',
  );
  const [isEditingThursday, setIsEditingThursday] = useState<boolean>(false);
  const [isHoveringThursday, setIsHoveringThursday] = useState<boolean>(false);

  // Friday
  const [fridayOpeningTime, setFridayOpeningTime] = useState<string>(
    data?.getClientById?.data.client.friday_opening || '',
  );
  const [fridayClosingTime, setFridayClosingTime] = useState<string>(
    data?.getClientById?.data.client.friday_closing || '',
  );
  const [isEditingFriday, setIsEditingFriday] = useState<boolean>(false);
  const [isHoveringFriday, setIsHoveringFriday] = useState<boolean>(false);

  // Saturday
  const [saturdayOpeningTime, setSaturdayOpeningTime] = useState<string>(
    data?.getClientById?.data.client.saturday_opening || '',
  );
  const [saturdayClosingTime, setSaturdayClosingTime] = useState<string>(
    data?.getClientById?.data.client.saturday_closing || '',
  );
  const [isEditingSaturday, setIsEditingSaturday] = useState<boolean>(false);
  const [isHoveringSaturday, setIsHoveringSaturday] = useState<boolean>(false);

  // Sunday
  const [sundayOpeningTime, setSundayOpeningTime] = useState<string>(
    data?.getClientById?.data.client.sunday_opening || '',
  );
  const [sundayClosingTime, setSundayClosingTime] = useState<string>(
    data?.getClientById?.data.client.sunday_closing || '',
  );
  const [isEditingSunday, setIsEditingSunday] = useState<boolean>(false);
  const [isHoveringSunday, setIsHoveringSunday] = useState<boolean>(false);

  // Original time table
  const [originalTimeTable, setOriginalTimeTable] = useState<any>({
    monday_opening: data?.getClientById?.data.client.monday_opening || '',
    monday_closing: data?.getClientById?.data.client.monday_closing || '',
    tuesday_opening: data?.getClientById?.data.client.tuesday_opening || '',
    tuesday_closing: data?.getClientById?.data.client.tuesday_closing || '',
    wednesday_opening: data?.getClientById?.data.client.wednesday_opening || '',
    wednesday_closing: data?.getClientById?.data.client.wednesday_closing || '',
    thursday_opening: data?.getClientById?.data.client.thursday_opening || '',
    thursday_closing: data?.getClientById?.data.client.thursday_closing || '',
    friday_opening: data?.getClientById?.data.client.friday_opening || '',
    friday_closing: data?.getClientById?.data.client.friday_closing || '',
    saturday_opening: data?.getClientById?.data.client.saturday_opening || '',
    saturday_closing: data?.getClientById?.data.client.saturday_closing || '',
    sunday_opening: data?.getClientById?.data.client.sunday_opening || '',
    sunday_closing: data?.getClientById?.data.client.sunday_closing || '',
  });

  // New time table
  const [newTimeTable, setNewTimeTable] = useState<any>({
    monday_opening: mondayOpeningTime,
    monday_closing: mondayClosingTime,
    tuesday_opening: tuesdayOpeningTime,
    tuesday_closing: tuesdayClosingTime,
    wednesday_opening: wednesdayOpeningTime,
    wednesday_closing: wednesdayClosingTime,
    thursday_opening: thursdayOpeningTime,
    thursday_closing: thursdayClosingTime,
    friday_opening: fridayOpeningTime,
    friday_closing: fridayClosingTime,
    saturday_opening: saturdayOpeningTime,
    saturday_closing: saturdayClosingTime,
    sunday_opening: sundayOpeningTime,
    sunday_closing: sundayClosingTime,
  });

  const handleMouseOver = (day: string) => {
    switch (day) {
      case 'monday':
        setIsHoveringMonday(true);
        break;
      case 'tuesday':
        setIsHoveringTuesday(true);
        break;
      case 'wednesday':
        setIsHoveringWednesday(true);
        break;
      case 'thursday':
        setIsHoveringThursday(true);
        break;
      case 'friday':
        setIsHoveringFriday(true);
        break;
      case 'saturday':
        setIsHoveringSaturday(true);
        break;
      case 'sunday':
        setIsHoveringSunday(true);
        break;
      default:
        break;
    }
  };

  const handleMouseLeave = (day: string) => {
    switch (day) {
      case 'monday':
        setIsHoveringMonday(false);
        break;
      case 'tuesday':
        setIsHoveringTuesday(false);
        break;
      case 'wednesday':
        setIsHoveringWednesday(false);
        break;
      case 'thursday':
        setIsHoveringThursday(false);
        break;
      case 'friday':
        setIsHoveringFriday(false);
        break;
      case 'saturday':
        setIsHoveringSaturday(false);
        break;
      case 'sunday':
        setIsHoveringSunday(false);
        break;
      default:
        break;
    }
  };

  const hasChangesTimeTable = () => {
    if (
      originalTimeTable.monday_opening === newTimeTable.monday_opening &&
      originalTimeTable.monday_closing === newTimeTable.monday_closing &&
      originalTimeTable.tuesday_opening === newTimeTable.tuesday_opening &&
      originalTimeTable.tuesday_closing === newTimeTable.tuesday_closing &&
      originalTimeTable.wednesday_opening === newTimeTable.wednesday_opening &&
      originalTimeTable.wednesday_closing === newTimeTable.wednesday_closing &&
      originalTimeTable.thursday_opening === newTimeTable.thursday_opening &&
      originalTimeTable.thursday_closing === newTimeTable.thursday_closing &&
      originalTimeTable.friday_opening === newTimeTable.friday_opening &&
      originalTimeTable.friday_closing === newTimeTable.friday_closing &&
      originalTimeTable.saturday_opening === newTimeTable.saturday_opening &&
      originalTimeTable.saturday_closing === newTimeTable.saturday_closing &&
      originalTimeTable.sunday_opening === newTimeTable.sunday_opening &&
      originalTimeTable.sunday_closing === newTimeTable.sunday_closing
    ) {
      return false;
    }

    return true;
  };

  const handleIsEditingTimeTable = () => {
    setIsEditingMonday(true);
    setIsEditingTuesday(true);
    setIsEditingWednesday(true);
    setIsEditingThursday(true);
    setIsEditingFriday(true);
    setIsEditingSaturday(true);
    setIsEditingSunday(true);
  };

  const resetIsEditingStates = () => {
    setIsEditingMonday(false);
    setIsEditingTuesday(false);
    setIsEditingWednesday(false);
    setIsEditingThursday(false);
    setIsEditingFriday(false);
    setIsEditingSaturday(false);
    setIsEditingSunday(false);
    setIsEditingPhone(false);
    setIsEditingEmail(false);
    setIsEditingAddress(false);
  };

  const handleSaveTimeTable = () => {
    if (!hasChangesTimeTable()) {
      notifyTimeSaveWarning();
      return;
    }

    // for each day we check if is different from the original and if it is we update it
    if (originalTimeTable.monday_opening !== newTimeTable.monday_opening) {
      updateClient({
        variables: {
          clientId: client,
          key: 'monday_opening',
          value: newTimeTable.monday_opening,
        },
      });
    }
    if (originalTimeTable.monday_closing !== newTimeTable.monday_closing) {
      updateClient({
        variables: {
          clientId: client,
          key: 'monday_closing',
          value: newTimeTable.monday_closing,
        },
      });
    }
    if (originalTimeTable.tuesday_opening !== newTimeTable.tuesday_opening) {
      updateClient({
        variables: {
          clientId: client,
          key: 'tuesday_opening',
          value: newTimeTable.tuesday_opening,
        },
      });
    }
    if (originalTimeTable.tuesday_closing !== newTimeTable.tuesday_closing) {
      updateClient({
        variables: {
          clientId: client,
          key: 'tuesday_closing',
          value: newTimeTable.tuesday_closing,
        },
      });
    }
    if (
      originalTimeTable.wednesday_opening !== newTimeTable.wednesday_opening
    ) {
      updateClient({
        variables: {
          clientId: client,
          key: 'wednesday_opening',
          value: newTimeTable.wednesday_opening,
        },
      });
    }
    if (
      originalTimeTable.wednesday_closing !== newTimeTable.wednesday_closing
    ) {
      updateClient({
        variables: {
          clientId: client,
          key: 'wednesday_closing',
          value: newTimeTable.wednesday_closing,
        },
      });
    }
    if (originalTimeTable.thursday_opening !== newTimeTable.thursday_opening) {
      updateClient({
        variables: {
          clientId: client,
          key: 'thursday_opening',
          value: newTimeTable.thursday_opening,
        },
      });
    }
    if (originalTimeTable.thursday_closing !== newTimeTable.thursday_closing) {
      updateClient({
        variables: {
          clientId: client,
          key: 'thursday_closing',
          value: newTimeTable.thursday_closing,
        },
      });
    }
    if (originalTimeTable.friday_opening !== newTimeTable.friday_opening) {
      updateClient({
        variables: {
          clientId: client,
          key: 'friday_opening',
          value: newTimeTable.friday_opening,
        },
      });
    }
    if (originalTimeTable.friday_closing !== newTimeTable.friday_closing) {
      updateClient({
        variables: {
          clientId: client,
          key: 'friday_closing',
          value: newTimeTable.friday_closing,
        },
      });
    }
    if (originalTimeTable.saturday_opening !== newTimeTable.saturday_opening) {
      updateClient({
        variables: {
          clientId: client,
          key: 'saturday_opening',
          value: newTimeTable.saturday_opening,
        },
      });
    }
    if (originalTimeTable.saturday_closing !== newTimeTable.saturday_closing) {
      updateClient({
        variables: {
          clientId: client,
          key: 'saturday_closing',
          value: newTimeTable.saturday_closing,
        },
      });
    }
    if (originalTimeTable.sunday_opening !== newTimeTable.sunday_opening) {
      updateClient({
        variables: {
          clientId: client,
          key: 'sunday_opening',
          value: newTimeTable.sunday_opening,
        },
      });
    }
    if (originalTimeTable.sunday_closing !== newTimeTable.sunday_closing) {
      updateClient({
        variables: {
          clientId: client,
          key: 'sunday_closing',
          value: newTimeTable.sunday_closing,
        },
      });
    }

    resetIsEditingStates();
    notifyTimeSaveOk();
  };

  const handleSavePhone = () => {
    updateClient({
      variables: {
        clientId: client,
        key: 'phone',
        value: phone,
      },
    });
    setIsEditingPhone(false);
    resetIsEditingStates();
    notifySaveOk();
  };

  useEffect(() => {
    setPhone(data?.getClientById?.data.client.phone || '');
    setEmail(data?.getClientById?.data.client.email || '');
    setAddress(data?.getClientById?.data.client.address || '');

    setMondayOpeningTime(data?.getClientById?.data.client.monday_opening || '');
    setMondayClosingTime(data?.getClientById?.data.client.monday_closing || '');
    setTuesdayOpeningTime(
      data?.getClientById?.data.client.tuesday_opening || '',
    );
    setTuesdayClosingTime(
      data?.getClientById?.data.client.tuesday_closing || '',
    );
    setWednesdayOpeningTime(
      data?.getClientById?.data.client.wednesday_opening || '',
    );
    setWednesdayClosingTime(
      data?.getClientById?.data.client.wednesday_closing || '',
    );
    setThursdayOpeningTime(
      data?.getClientById?.data.client.thursday_opening || '',
    );
    setThursdayClosingTime(
      data?.getClientById?.data.client.thursday_closing || '',
    );
    setFridayOpeningTime(data?.getClientById?.data.client.friday_opening || '');
    setFridayClosingTime(data?.getClientById?.data.client.friday_closing || '');
    setSaturdayOpeningTime(
      data?.getClientById?.data.client.saturday_opening || '',
    );
    setSaturdayClosingTime(
      data?.getClientById?.data.client.saturday_closing || '',
    );
    setSundayOpeningTime(data?.getClientById?.data.client.sunday_opening || '');
    setSundayClosingTime(data?.getClientById?.data.client.sunday_closing || '');

    setOriginalTimeTable({
      monday_opening: data?.getClientById?.data.client.monday_opening || '',
      monday_closing: data?.getClientById?.data.client.monday_closing || '',
      tuesday_opening: data?.getClientById?.data.client.tuesday_opening || '',
      tuesday_closing: data?.getClientById?.data.client.tuesday_closing || '',
      wednesday_opening:
        data?.getClientById?.data.client.wednesday_opening || '',
      wednesday_closing:
        data?.getClientById?.data.client.wednesday_closing || '',
      thursday_opening: data?.getClientById?.data.client.thursday_opening || '',
      thursday_closing: data?.getClientById?.data.client.thursday_closing || '',
      friday_opening: data?.getClientById?.data.client.friday_opening || '',
      friday_closing: data?.getClientById?.data.client.friday_closing || '',
      saturday_opening: data?.getClientById?.data.client.saturday_opening || '',
      saturday_closing: data?.getClientById?.data.client.saturday_closing || '',
      sunday_opening: data?.getClientById?.data.client.sunday_opening || '',
      sunday_closing: data?.getClientById?.data.client.sunday_closing || '',
    });
  }, [data]);

  useEffect(() => {
    setNewTimeTable({
      monday_opening: mondayOpeningTime,
      monday_closing: mondayClosingTime,
      tuesday_opening: tuesdayOpeningTime,
      tuesday_closing: tuesdayClosingTime,
      wednesday_opening: wednesdayOpeningTime,
      wednesday_closing: wednesdayClosingTime,
      thursday_opening: thursdayOpeningTime,
      thursday_closing: thursdayClosingTime,
      friday_opening: fridayOpeningTime,
      friday_closing: fridayClosingTime,
      saturday_opening: saturdayOpeningTime,
      saturday_closing: saturdayClosingTime,
      sunday_opening: sundayOpeningTime,
      sunday_closing: sundayClosingTime,
    });
  }, [
    mondayOpeningTime,
    mondayClosingTime,
    tuesdayOpeningTime,
    tuesdayClosingTime,
    wednesdayOpeningTime,
    wednesdayClosingTime,
    thursdayOpeningTime,
    thursdayClosingTime,
    fridayOpeningTime,
    fridayClosingTime,
    saturdayOpeningTime,
    saturdayClosingTime,
    sundayOpeningTime,
    sundayClosingTime,
  ]);

  // Geocoding

  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string>(
    data?.getClientById?.data.client.address || '',
  );
  const [showAddressList, setShowAddressList] = useState<boolean>(false);

  const { data: fetchAddressData } = useQuery<any>(GET_GEOCODING_SUGGESTIONS, {
    variables: {
      address: address || '',
    },
  });

  useEffect(() => {
    if (
      fetchAddressData &&
      fetchAddressData.getAddressSuggestions.code === 200
    ) {
      if (isEditingAddress) {
        setShowAddressList(true);
      }

      setAddressSuggestions(
        fetchAddressData.getAddressSuggestions.data.map(
          (item: any) => item.place,
        ),
      );
    }
  }, [fetchAddressData]);

  useEffect(() => {
    if (!isEditingAddress) {
      // We give time to hit the selected option
      setTimeout(() => {
        setShowAddressList(false);
      }, 200);
    }
    if (isEditingAddress && addressSuggestions.length > 0) {
      setShowAddressList(true);
    }
  }, [isEditingAddress]);

  const handleAddressSelection = (selectedOption: string) => {
    setAddress(selectedOption);
    setSelectedAddress(selectedOption);
    setShowAddressList(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputAddress = e.target.value;

    setAddress(inputAddress);
    setSelectedAddress(inputAddress);

    if (inputAddress.trim() === '') {
      setShowAddressList(false);
    }
  };

  const validateAddress = (_selectedAddress: string) => {
    return addressSuggestions.includes(_selectedAddress);
  };

  const handleSaveAddress = () => {
    if (!validateAddress(selectedAddress)) {
      toast.warn(t('clients-overall.notify-address-invalid'));
      return;
    }

    updateClient({
      variables: {
        clientId: client,
        key: 'address',
        value: selectedAddress,
      },
    });
    setIsEditingAddress(false);
    resetIsEditingStates();
    notifySaveOk();
  };

  return (
    <>
      <div className="relative min-w-[265px] max-w-[265px] ">
        <div className="z-20 flex w-[265px] flex-col gap-3 md:fixed">
          <div className="flex flex-col gap-2 rounded-lg bg-[#EBF0F1] pb-10 pl-6 pr-3 pt-6">
            <div>
              <h1 className="text-3xl font-[700]">
                {data?.getClientById?.data.client.name}
              </h1>
              <div className="flex items-center gap-2">
                <img src={calendarAddIcon} alt="calendar add icon" />
                <p className="text-sm font-medium">
                  {data?.getClientById?.data.client.createdAt &&
                    convertToAmericanDateFormatWithTime(
                      data?.getClientById?.data.client.createdAt,
                    )}
                </p>
              </div>
            </div>
            <div className="my-3">
              {data && data.getClientById && client && (
                <StatusDropdown
                  clientId={client}
                  mutation={updateClient}
                  initialStatusValue={
                    data.getClientById?.data.client.status || ''
                  }
                />
              )}
            </div>
            <div
              onMouseOver={() => setIsHoveringPhone(true)}
              onMouseLeave={() => setIsHoveringPhone(false)}
              className="mt-3 flex justify-between gap-2 pr-2"
            >
              {isEditingPhone ? (
                <div className="flex items-center gap-2">
                  <img className="h-[16px]" src={phoneIcon} alt="phone icon" />
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    id="phone"
                    className="focs:outline-none w-[180px] border-x-0 border-b-2 border-t-0 border-graydark bg-transparent p-0 pr-2 text-xs font-medium outline-none focus:ring-0"
                    value={phone}
                  />
                </div>
              ) : (
                <div className="flex w-[calc(100%-16px)] items-center gap-2">
                  <img className="h-[16px]" src={phoneIcon} alt="phone icon" />
                  <p className="text-xs font-medium">{phone}</p>
                </div>
              )}

              {isEditingPhone ? (
                <button
                  className={`${isEditingPhone ? 'min-w-[16px]' : 'hidden'}`}
                  onClick={handleSavePhone}
                  id="savePhone"
                >
                  <img src={saveIcon} alt="edit icon" />
                </button>
              ) : (
                <button
                  className={`${isHoveringPhone ? 'min-w-[16px]' : 'hidden'}`}
                  onClick={() => setIsEditingPhone(!isEditingPhone)}
                  id="editPhone"
                >
                  <img src={editIcon} alt="edit icon" />
                </button>
              )}
            </div>
            <div
              onMouseOver={() => setIsHoveringEmail(true)}
              onMouseLeave={() => setIsHoveringEmail(false)}
              className="flex justify-between gap-2 pr-2"
            >
              {isEditingEmail ? (
                <div className="flex items-center gap-2">
                  <img className="h-[16px]" src={emailIcon} alt="email icon" />
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    className="focs:outline-none w-[180px] border-x-0 border-b-2 border-t-0 border-graydark bg-transparent p-0 pr-2 text-xs font-medium outline-none focus:ring-0"
                    value={email}
                  />
                </div>
              ) : (
                <div className="flex w-[calc(100%-16px)] items-center gap-2 truncate ">
                  <img className="h-[16px]" src={emailIcon} alt="email icon" />
                  <p className="text-xs font-medium">{email}</p>
                </div>
              )}

              {isEditingEmail ? (
                <button
                  className={`${isEditingEmail ? 'min-w-[16px]' : 'hidden'}`}
                  onClick={() => {
                    updateClient({
                      variables: {
                        clientId: client,
                        key: 'email',
                        value: email,
                      },
                    });
                    setIsEditingEmail(false);
                    resetIsEditingStates();
                    notifySaveOk();
                  }}
                >
                  <img src={saveIcon} alt="edit icon" />
                </button>
              ) : (
                <button
                  className={`${isHoveringEmail ? 'min-w-[16px]' : 'hidden'}`}
                  onClick={() => setIsEditingEmail(!isEditingEmail)}
                >
                  <img src={editIcon} alt="edit icon" />
                </button>
              )}
            </div>
            <div
              onMouseOver={() => setIsHoveringAddress(true)}
              onMouseLeave={() => setIsHoveringAddress(false)}
              className="flex justify-between gap-2 pr-2"
            >
              {isEditingAddress ? (
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <img
                      className="h-[16px]"
                      src={locationIcon}
                      alt="location icon"
                    />
                    <input
                      onChange={handleInputChange}
                      autoComplete="off"
                      onFocus={() => setShowAddressList(true)}
                      onBlur={() => {
                        setTimeout(() => {
                          setShowAddressList(false);
                        }, 200);
                      }}
                      type="text"
                      className="focs:outline-none w-[180px] border-x-0 border-b-2 border-t-0 border-graydark bg-transparent p-0 pr-2 text-xs font-medium outline-none focus:ring-0"
                      value={address}
                    />
                  </div>
                  <div className="relative ">
                    {showAddressList && (
                      <div className="geocoding-datalist absolute left-[20px] top-[-5px] z-30 flex max-h-[160px] w-full flex-col overflow-y-auto border-[1px] border-graylight bg-white">
                        {addressSuggestions.map((option, index) => (
                          <div
                            className="flex h-full w-full cursor-pointer border-b-[1px] border-graydark p-1 text-[12px] font-medium hover:bg-graydark"
                            key={index}
                            onClick={() => handleAddressSelection(option)}
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex w-[calc(100%-16px)] items-center gap-2">
                  <img
                    className="h-[16px]"
                    src={locationIcon}
                    alt="location icon"
                  />
                  <p className="text-xs font-medium">{address}</p>
                </div>
              )}

              {isEditingAddress ? (
                <button
                  className={`${isEditingAddress ? 'min-w-[16px]' : 'hidden'}`}
                  onClick={handleSaveAddress}
                >
                  <img src={saveIcon} alt="edit icon" />
                </button>
              ) : (
                <button
                  className={`${isHoveringAddress ? 'min-w-[16px]' : 'hidden'}`}
                  onClick={() => setIsEditingAddress(!isEditingAddress)}
                >
                  <img src={editIcon} alt="edit icon" />
                </button>
              )}
            </div>

            <div className="flex items-start gap-2 pr-2">
              <img className="h-[16px]" src={clockIcon} alt="clock icon" />
              <div>
                <table className="table-auto">
                  <tbody>
                    <tr
                      onMouseOver={() => handleMouseOver('monday')}
                      onMouseLeave={() => handleMouseLeave('monday')}
                    >
                      <td className="text-xs font-medium">Mn: &nbsp;</td>
                      <td className="text-xs font-medium">
                        {isEditingMonday ? (
                          <input
                            className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                            type="time"
                            name="mondayTime"
                            id="mondayTime"
                            value={mondayOpeningTime}
                            onChange={(e) =>
                              setMondayOpeningTime(e.target.value)
                            }
                          />
                        ) : (
                          data?.getClientById?.data.client.monday_opening &&
                          convertToTwelveHourFormat(mondayOpeningTime)
                        )}
                        {!data?.getClientById?.data.client.monday_opening && (
                          <div className="min-w-[30px]"></div>
                        )}
                      </td>
                      <td className="text-xs font-medium">
                        <div className="flex gap-2">
                          -{' '}
                          {isEditingMonday ? (
                            <input
                              className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                              type="time"
                              name="mondayTimeClose"
                              id="mondayTimeClose"
                              value={mondayClosingTime}
                              onChange={(e) =>
                                setMondayClosingTime(e.target.value)
                              }
                            />
                          ) : (
                            data?.getClientById?.data.client.monday_closing &&
                            convertToTwelveHourFormat(mondayClosingTime)
                          )}
                          {!data?.getClientById?.data.client.monday_closing && (
                            <div className="min-w-[30px]"></div>
                          )}
                          <button
                            className={`${
                              (isHoveringMonday && !isEditingMonday) ||
                              (isHoveringTuesday && !isEditingTuesday) ||
                              (isHoveringWednesday && !isEditingWednesday) ||
                              (isHoveringThursday && !isEditingThursday) ||
                              (isHoveringFriday && !isEditingFriday) ||
                              (isHoveringSaturday && !isEditingSaturday) ||
                              (isHoveringSunday && !isEditingSunday)
                                ? ''
                                : 'hidden'
                            }`}
                            onClick={handleIsEditingTimeTable}
                          >
                            <img src={editIcon} alt="edit icon" />
                          </button>
                        </div>
                      </td>
                      <td></td>
                    </tr>

                    <tr
                      onMouseOver={() => handleMouseOver('tuesday')}
                      onMouseLeave={() => handleMouseLeave('tuesday')}
                    >
                      <td className="text-xs font-medium">Tu: &nbsp;</td>
                      <td className="text-xs font-medium">
                        {isEditingTuesday ? (
                          <input
                            className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                            type="time"
                            name="tuesdayTime"
                            id="tuesdayTime"
                            value={tuesdayOpeningTime}
                            onChange={(e) =>
                              setTuesdayOpeningTime(e.target.value)
                            }
                          />
                        ) : (
                          data?.getClientById?.data.client.tuesday_opening &&
                          convertToTwelveHourFormat(tuesdayOpeningTime)
                        )}
                      </td>
                      <td className="text-xs font-medium">
                        <div className="flex gap-2">
                          -{' '}
                          {isEditingTuesday ? (
                            <input
                              className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                              type="time"
                              name="tuesdayTimeClose"
                              id="tuesdayTimeClose"
                              value={tuesdayClosingTime}
                              onChange={(e) =>
                                setTuesdayClosingTime(e.target.value)
                              }
                            />
                          ) : (
                            data?.getClientById?.data.client.tuesday_closing &&
                            convertToTwelveHourFormat(tuesdayClosingTime)
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr
                      onMouseOver={() => handleMouseOver('wednesday')}
                      onMouseLeave={() => handleMouseLeave('wednesday')}
                    >
                      <td className="text-xs font-medium">We: &nbsp;</td>
                      <td className="text-xs font-medium">
                        {isEditingWednesday ? (
                          <input
                            className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                            type="time"
                            name="wednesdayTime"
                            id="wednesdayTime"
                            value={wednesdayOpeningTime}
                            onChange={(e) =>
                              setWednesdayOpeningTime(e.target.value)
                            }
                          />
                        ) : (
                          data?.getClientById?.data.client.wednesday_opening &&
                          convertToTwelveHourFormat(wednesdayOpeningTime)
                        )}
                      </td>
                      <td className="text-xs font-medium">
                        <div className="flex gap-2">
                          -{' '}
                          {isEditingWednesday ? (
                            <input
                              className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                              type="time"
                              name="wednesdayTimeClose"
                              id="wednesdayTimeClose"
                              value={wednesdayClosingTime}
                              onChange={(e) =>
                                setWednesdayClosingTime(e.target.value)
                              }
                            />
                          ) : (
                            data?.getClientById?.data.client
                              .wednesday_closing &&
                            convertToTwelveHourFormat(wednesdayClosingTime)
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr
                      onMouseOver={() => handleMouseOver('thursday')}
                      onMouseLeave={() => handleMouseLeave('thursday')}
                    >
                      <td className="text-xs font-medium">Th: &nbsp;</td>
                      <td className="text-xs font-medium">
                        {isEditingThursday ? (
                          <input
                            className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                            type="time"
                            name="thursdayTime"
                            id="thursdayTime"
                            value={thursdayOpeningTime}
                            onChange={(e) =>
                              setThursdayOpeningTime(e.target.value)
                            }
                          />
                        ) : (
                          data?.getClientById?.data.client.thursday_opening &&
                          convertToTwelveHourFormat(thursdayOpeningTime)
                        )}
                      </td>
                      <td className="text-xs font-medium">
                        <div className="flex gap-2">
                          -{' '}
                          {isEditingThursday ? (
                            <input
                              className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                              type="time"
                              name="thursdayTimeClose"
                              id="thursdayTimeClose"
                              value={thursdayClosingTime}
                              onChange={(e) =>
                                setThursdayClosingTime(e.target.value)
                              }
                            />
                          ) : (
                            data?.getClientById?.data.client.thursday_closing &&
                            convertToTwelveHourFormat(thursdayClosingTime)
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr
                      onMouseOver={() => handleMouseOver('friday')}
                      onMouseLeave={() => handleMouseLeave('friday')}
                    >
                      <td className="text-xs font-medium">Fr: &nbsp;</td>
                      <td className="text-xs font-medium">
                        {isEditingFriday ? (
                          <input
                            className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                            type="time"
                            name="fridayTime"
                            id="fridayTime"
                            value={fridayOpeningTime}
                            onChange={(e) =>
                              setFridayOpeningTime(e.target.value)
                            }
                          />
                        ) : (
                          data?.getClientById?.data.client.friday_opening &&
                          convertToTwelveHourFormat(fridayOpeningTime)
                        )}
                      </td>
                      <td className="text-xs font-medium">
                        <div className="flex gap-2">
                          -{' '}
                          {isEditingFriday ? (
                            <input
                              className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                              type="time"
                              name="fridayTimeClose"
                              id="fridayTimeClose"
                              value={fridayClosingTime}
                              onChange={(e) =>
                                setFridayClosingTime(e.target.value)
                              }
                            />
                          ) : (
                            data?.getClientById?.data.client.friday_closing &&
                            convertToTwelveHourFormat(fridayClosingTime)
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr
                      onMouseOver={() => handleMouseOver('saturday')}
                      onMouseLeave={() => handleMouseLeave('saturday')}
                    >
                      <td className="text-xs font-medium">Sa: &nbsp;</td>
                      <td className="text-xs font-medium">
                        {isEditingSaturday ? (
                          <input
                            className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                            type="time"
                            name="saturdayTime"
                            id="saturdayTime"
                            value={saturdayOpeningTime}
                            onChange={(e) =>
                              setSaturdayOpeningTime(e.target.value)
                            }
                          />
                        ) : (
                          data?.getClientById?.data.client.saturday_opening &&
                          convertToTwelveHourFormat(saturdayOpeningTime)
                        )}
                      </td>
                      <td className="text-xs font-medium">
                        <div className="flex gap-2">
                          -{' '}
                          {isEditingSaturday ? (
                            <input
                              className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                              type="time"
                              name="saturdayTimeClose"
                              id="saturdayTimeClose"
                              value={saturdayClosingTime}
                              onChange={(e) =>
                                setSaturdayClosingTime(e.target.value)
                              }
                            />
                          ) : (
                            data?.getClientById?.data.client.saturday_closing &&
                            convertToTwelveHourFormat(saturdayClosingTime)
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr
                      onMouseOver={() => handleMouseOver('sunday')}
                      onMouseLeave={() => handleMouseLeave('sunday')}
                    >
                      <td className="text-xs font-medium">Su: &nbsp;</td>
                      <td className="text-xs font-medium">
                        {isEditingSunday ? (
                          <input
                            className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                            type="time"
                            name="sundayTime"
                            id="sundayTime"
                            value={sundayOpeningTime}
                            onChange={(e) =>
                              setSundayOpeningTime(e.target.value)
                            }
                          />
                        ) : (
                          data?.getClientById?.data.client.sunday_opening &&
                          convertToTwelveHourFormat(sundayOpeningTime)
                        )}
                      </td>
                      <td className="text-xs font-medium">
                        <div className="flex gap-2">
                          -{' '}
                          {isEditingSunday ? (
                            <input
                              className="border-x-0 border-b-2 border-t-0 border-graydark bg-transparent text-xs focus:border-green focus:outline-none focus:ring-0"
                              type="time"
                              name="sundayTimeClose"
                              id="sundayTimeClose"
                              value={sundayClosingTime}
                              onChange={(e) =>
                                setSundayClosingTime(e.target.value)
                              }
                            />
                          ) : (
                            data?.getClientById?.data.client.sunday_closing &&
                            convertToTwelveHourFormat(sundayClosingTime)
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className={`${
                isEditingMonday ||
                isEditingTuesday ||
                isEditingWednesday ||
                isEditingThursday ||
                isEditingFriday ||
                isEditingSaturday ||
                isEditingSunday
                  ? ''
                  : 'hidden'
              } absolute bottom-4 left-6 z-0 w-[218px]  border-b-2 border-graydark`}
            >
              <div className="z-10 flex justify-end pb-1 pr-2">
                <button onClick={handleSaveTimeTable}>
                  <img src={saveIcon} alt="save icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientFixedCard;
