import { useTranslation } from 'react-i18next';

import iconBreadcrumb from '../../assets/icons/dollar-square.svg';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import DTInvoices from '../../components/ui/invoices/DTInvoices';

const InvoicesView = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <Breadcrumb image={iconBreadcrumb} title={t('invoices.title-h1')} />
      <div>
        <DTInvoices />
      </div>
    </>
  );
};

export default InvoicesView;
