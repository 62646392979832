import { useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  ExpandedState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { ChangeEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import ArrowExpand from '../../../../assets/icons/arrow-right-2.svg';
import { WITHDRAW_TABLES_MAX_RESULTS } from '../../../../config/constants';
import { useModal } from '../../../../hooks/useModal';
import { paymentFilterState } from '../../../../recoil/disburseFilter';
import { cleanDisburseFiltersState } from '../../../../recoil/disburseFilterClean';
// import { useNotification } from '../../../../hooks/useNotification';
import { needRefreshState } from '../../../../recoil/needRefresh';
import { GET_PAYMENTS } from '../../../../services/graphql/cashdeposit/cashdeposit.queries';
import type { CashPaymentTable } from '../../../../types/tables';
import { convertToAmericanDateFormat } from '../../../../utils/convertTimestamp';
import { formatNumberMoney } from '../../../../utils/numberFormatter';
import DataTable from '../../common/DataTable/DataTable';
import DTBoldCell from '../../common/DataTable/DTBoldCell';
import DTHeader from '../../common/DataTable/DTHeader';
import Searchbar from '../../common/Searchbar';

const DTPayments = () => {
  const { t } = useTranslation('common');
  const location = useLocation();
  // const { notifyDeleteError, notifyDeleteOk } = useNotification();

  // States
  const [filterValue, setFilterValue] = useState<string>('');

  const [filters, setFilters] = useRecoilState(paymentFilterState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const cleanFilters = useRecoilValue(cleanDisburseFiltersState);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: WITHDRAW_TABLES_MAX_RESULTS,
  });
  const [data, setData] = useState<CashPaymentTable[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
      filterName: filterValue,
    }));
  };

  const {
    data: fetchData,
    loading,
    error,
    refetch,
  } = useQuery(GET_PAYMENTS, {
    variables: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'payment_date',
      orderDirection: filters?.orderDirection || 'DESC',
      filterName: filterValue,
    },
  });
  const handleFilterChangeWithDebounce = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = event.target.value;
    setFilterValue(inputValue);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      let newFilters: Record<string, any> = {};

      newFilters = { ...filters };

      if (inputValue) {
        newFilters.nameFilter = inputValue;
      } else {
        newFilters.nameFilter = null;
      }

      setFilters(newFilters);
    }, 500);
  };

  const { handleOpenPaymentFilterModal } = useModal();

  useEffect(() => {
    if (fetchData && fetchData.getCashPayments?.data) {
      const updatedData: CashPaymentTable[] =
        fetchData.getCashPayments.data.map((payment: CashPaymentTable) => ({
          id: payment.id,
          subRows: payment.subRows || [],
          receiptId: payment.receiptId || '',
          amount: payment.amount || 0,
          payment_date: payment.payment_date || '',
          driver: payment.driver || '',
          route: payment.route || '',
          client: payment.client || '',
        }));
      setData(updatedData);
      setTotal(fetchData.getCashPayments.count);
    }
  }, [fetchData]);

  const columnHelper = createColumnHelper<CashPaymentTable>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('client', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => {
          return (
            <div>
              {info.row.getCanExpand() ? (
                <button
                  {...{
                    onClick: info.row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' },
                  }}
                >
                  {info.row.getIsExpanded() ? (
                    <div className="flex flex-row">
                      <div className="flex rotate-90 items-center justify-center">
                        <img
                          width={14}
                          height={14}
                          src={ArrowExpand}
                          className="object-cover"
                        />
                      </div>
                      <DTBoldCell content={info.getValue()} id={info.cell.id} />
                    </div>
                  ) : (
                    <div className="flex flex-row">
                      <div className="flex items-center justify-center">
                        <img
                          width={14}
                          height={14}
                          src={ArrowExpand}
                          className="object-cover"
                        />
                      </div>
                      <DTBoldCell content={info.getValue()} id={info.cell.id} />
                    </div>
                  )}
                </button>
              ) : (
                <DTBoldCell content={info.getValue()} id={info.cell.id} />
              )}{' '}
            </div>
          );
        },
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('cashPayments.th-client')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="client"
          />
        ),
      }),
      columnHelper.accessor('receiptId', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info: any) => {
          return (
            <button
              {...{
                onClick: info.row.getToggleExpandedHandler(),
                style: { cursor: 'pointer' },
              }}
            >
              <DTBoldCell
                content={info.getValue()}
                id={info.cell.id + info.getValue()}
              />
            </button>
          );
        },
        // footer: (props) => props.column.id,
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('cashPayments.th-receiptId')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="receiptId"
          />
        ),
      }),
      columnHelper.accessor('amount', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">
            {formatNumberMoney(info.getValue())}
          </span>
        ),

        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('cashPayments.th-amount')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="amount"
          />
        ),
      }),
      columnHelper.accessor('route', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
        header: ({ ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection('route')}
            title={t('cashPayments.th-route')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="route"
          />
        ),
      }),
      columnHelper.accessor('driver', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
        header: ({ ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection('driver')}
            title={t('cashPayments.th-driver')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="driver"
          />
        ),
      }),
      columnHelper.accessor('payment_date', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('cashPayments.th-date')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="payment_date"
          />
        ),
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() !== null
              ? convertToAmericanDateFormat(info.getValue() as string, true)
              : ''}
          </span>
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSubRows: (row: any) => row.subRows,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    onExpandedChange: setExpanded,
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
      expanded,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  // Need refresh (reload data on modal close, filters,...)

  const [needRefresh] = useRecoilState(needRefreshState);

  useEffect(() => {
    refetch({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...filters,
    });
  }, [filters, needRefresh, pageIndex, pageSize]);

  // On before leave
  useEffect(() => {
    // clean filters when leaving component
    setFilters(cleanFilters);
    setFilters((prev) => ({
      ...prev,
      orderDirection: 'DESC',
      orderBy: 'payment_date',
    }));
  }, [location.key]);

  return (
    <>
      <div className="my-2 flex justify-between gap-4">
        <Searchbar
          filterValue={filterValue}
          onChange={handleFilterChangeWithDebounce}
          handleOpenFilterModal={handleOpenPaymentFilterModal}
        />
      </div>
      <div className="min-h-[200px]">
        <DataTable
          error={error}
          loading={loading}
          table={table}
          total={total}
          tableId=""
          sorting={{ ...filters }}
          loadingRows={pageSize}
          tableLayout="auto"
        />
      </div>
    </>
  );
};

export default DTPayments;
