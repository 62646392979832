import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import closeIcon from '../../assets/icons/close-square.svg';
import dobleCheck from '../../assets/icons/dobleCheck.svg';
import smallNotification from '../../assets/icons/smallNotification.svg';
import { hasNotificationAtom } from '../../recoil/hasNotifications';
import { userStateAtom } from '../../recoil/userData';
import { GET_NOTIFICATIONS } from '../../services/graphql/notifications/notificationQuery';
import { SET_ALL_READED } from '../../services/graphql/notifications/notificationsMutation';
import Loader from '../ui/Loader';
import Notification from './Content/Notification';

interface ModalLogoutProps {
  handleClose: () => void;
  isMenuOpen?: boolean;
  clearNotification: () => void;
}

function ModelNotification({
  handleClose,
  isMenuOpen = false,
  clearNotification,
}: ModalLogoutProps) {
  const [userInfo] = useRecoilState(userStateAtom);
  const [notifications, setNotifications] = useState<any>([]);

  const [unreadNotifications, setUnreadNotifications] =
    useRecoilState(hasNotificationAtom);
  const { loading } = useQuery(GET_NOTIFICATIONS, {
    variables: { userId: userInfo.id },
    skip: !userInfo?.id,
    fetchPolicy: 'network-only', // Doesn't check cache before making a network request

    onCompleted: (notification) => {
      const unread = notification.getNotifications.data.filter(
        (noti: any) => noti.readAt === null,
      );
      setUnreadNotifications(unread.length > 0);
      setNotifications(notification.getNotifications.data);
    },
  });

  const [setAllReadedQuery] = useMutation<any>(SET_ALL_READED, {
    variables: {
      userId: userInfo.id,
    },
  });

  const setAllReaded = () => {
    if (!unreadNotifications) return;
    setAllReadedQuery();
    clearNotification();
    setNotifications(
      notifications.map((noti: any) => ({
        ...noti,
        readAt: new Date().toISOString(),
      })),
    );
    setUnreadNotifications(false);
  };

  const setAsReaded = (id: number) => {
    setNotifications(
      notifications.map((noti: any) => {
        if (noti.id === id) {
          return { ...noti, readAt: new Date().toISOString() };
        }
        return noti;
      }),
    );
    setUnreadNotifications(
      notifications.filter((noti: any) => noti.readAt === null).length > 1,
    );
  };
  return (
    <div>
      <div className="fixed inset-0 z-50 flex">
        <div
          className="fixed inset-0 bg-gray-800 opacity-50"
          onClick={handleClose}
        ></div>
        <div
          className={`fixed ${
            isMenuOpen ? 'left-[12%] top-[35%]' : 'left-[4%] top-[35%]'
          } max-h-[500px] min-h-[500px] w-[450px] overflow-hidden rounded-[20px] bg-white p-6 shadow-lg  md:p-8 `}
        >
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-2">
              <img src={smallNotification} alt="notification" />
              <p className="font-inter text-xs font-bold">Notifications</p>
            </div>
            <button
              onClick={handleClose}
              className="cursor-pointer text-lg text-gray-500"
            >
              <img src={closeIcon} alt="close icon" />
            </button>
          </div>
          <div className="mb-6 mt-3 h-[345px]">
            <div className="customScroll h-full w-full overflow-x-hidden pr-1">
              {loading && (
                <div className="flex h-full w-full items-center justify-center">
                  <Loader />
                </div>
              )}
              {notifications?.map((notification: any) => (
                <Notification
                  id={notification.id}
                  key={notification.id}
                  title={notification.title}
                  message={notification.message}
                  date={notification.createdAt}
                  readAt={notification.readAt}
                  readIt={() => setAsReaded(notification.id)}
                  type={notification.type}
                  link={notification.link}
                  handleClose={handleClose}
                ></Notification>
              ))}
            </div>
          </div>
          <button
            className="h-[41px] w-full rounded-md bg-green hover:bg-gold"
            onClick={() => setAllReaded()}
          >
            <div className="flex flex-row items-center justify-center gap-2">
              <img src={dobleCheck} alt="check icon" />
              <span className="font-inter text-xs text-white">
                Mark All as Read
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModelNotification;
