type Props = {
  label?: string;
  value?: string | number | null;
  placeholder?: string;
  type: string;
};

const DisabledInputField = ({ value, label, placeholder, type }: Props) => {
  return (
    <div>
      {label && (
        <label htmlFor="name" className="my-2 block text-[14px] font-[600]">
          {label}
        </label>
      )}
      <div className={`flex w-full`}>
        <input
          value={value || ''}
          type={type}
          id="name"
          disabled
          className={`block w-full border-none p-0 text-xs  placeholder:text-[#5A5A5A] focus:outline-none focus:ring-0`}
          placeholder={placeholder || label || ''}
        />
      </div>
    </div>
  );
};

export default DisabledInputField;
