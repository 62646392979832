import type { ChangeEvent, DragEvent } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import iconDnD from '../../../assets/icons/document-upload.svg';

type Props = {
  submitFile: (file: File) => void;
};

const ReceiptUploader = ({ submitFile }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation('common');
  const notifyFileError = () => toast.error(t('modalNewReceipt.fileError'));

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.dataTransfer.files) {
      const selectedFile = event.dataTransfer.files[0] || null;
      if (selectedFile) {
        const fileType = selectedFile.name.split('.').pop()?.toLowerCase();
        if (
          fileType === 'png' ||
          fileType === 'jpeg' ||
          fileType === 'jpg' ||
          fileType === 'heic'
        ) {
          setFile(selectedFile);
          submitFile(selectedFile);
        } else {
          notifyFileError();
        }
      }
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.target.files) {
      const selectedFile = event.target.files[0] || null;
      if (selectedFile) {
        const fileType = selectedFile.name.split('.').pop()?.toLowerCase();
        if (
          fileType === 'png' ||
          fileType === 'jpeg' ||
          fileType === 'jpg' ||
          fileType === 'heic'
        ) {
          setFile(selectedFile);
          submitFile(selectedFile);
        } else {
          notifyFileError();
        }
      }
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className="relative my-4 h-48 w-96 rounded-lg border-2 border-dashed border-greenlight"
    >
      <input
        type="file"
        onChange={handleFileChange}
        className="hidden"
        ref={fileInputRef}
        accept="image/*"
      />
      {file ? (
        <img
          src={URL.createObjectURL(file)}
          alt="preview"
          className="absolute left-0 top-0 h-full w-full object-contain"
        />
      ) : (
        <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center">
          <img className="h-[40px]" src={iconDnD} alt="drag and drop icon" />
          <div className="text-sm font-normal text-gray-500">
            {t('modalNewReceipt.selectImage')}
          </div>
          <div
            onClick={openFileDialog}
            className="my-2 inline-flex cursor-pointer items-center justify-center rounded bg-gray-700 px-4 py-2"
          >
            <span className="text-sm font-medium text-white">
              {t('modalNewReceipt.selectFile')}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReceiptUploader;
