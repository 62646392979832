import { useTranslation } from 'react-i18next';

export const useFilterName = (filterTag: string): string => {
  const { t } = useTranslation('common');

  let result = filterTag;

  switch (filterTag) {
    case 'clientNameFilter':
      result = t('filterMarks.clientNameFilter');
      break;

    case 'filterName':
      result = t('filterMarks.clientNameFilter');
      break;

    case 'statusFilter':
      result = t('filterMarks.statusFilter');
      break;

    case 'lastServiceStartDateFilter':
      result = t('filterMarks.lastServiceStartDateFilter');
      break;

    case 'lastServiceEndDateFilter':
      result = t('filterMarks.lastServiceEndDateFilter');
      break;

    case 'startDate':
      result = t('filterMarks.startDate');
      break;

    case 'endDate':
      result = t('filterMarks.endDate');
      break;

    case 'licenseFilter':
      result = t('filterMarks.license');
      break;

    case 'zoneFilter':
      result = t('filterMarks.zoneFilter');
      break;

    case 'readyToVisitFilter':
      result = t('filterMarks.readyToVisitFilter');
      break;

    case 'availableDays':
      result = t('filterMarks.availableDays');
      break;

    case 'driverFilter':
      result = t('filterMarks.driverFilter');
      break;

    case 'truckFilter':
      result = t('filterMarks.truckFilter');
      break;

    case 'dateFilter':
      result = t('filterMarks.dateFilter');
      break;

    case 'serviceFilter':
      result = t('filterMarks.serviceFilter');
      break;

    case 'typeFilter':
      result = t('filterMarks.typeFilter');
      break;

    case 'filterDateStart':
      result = t('filterMarks.filterDateStart');
      break;

    case 'dateStartFilter':
      result = t('filterMarks.filterDateStart');
      break;

    case 'filterDateEnd':
      result = t('filterMarks.filterDateEnd');
      break;

    case 'dateEndFilter':
      result = t('filterMarks.filterDateEnd');
      break;

    case 'filterClientName':
      result = t('filterMarks.filterClientName');
      break;

    case 'filterRouteName':
      result = t('filterMarks.filterRouteName');
      break;

    case 'filterStatus':
      result = t('filterMarks.filterStatus');
      break;

    case 'filterRole':
      result = t('filterMarks.filterRole');
      break;

    case 'status':
      result = t('filterMarks.status');
      break;

    case 'model':
      result = t('filterMarks.model');
      break;

    case 'deleted':
      result = t('filterMarks.deleted');
      break;

    case 'service':
      result = t('filterMarks.service');
      break;

    case 'mileage':
      result = t('filterMarks.mileage');
      break;

    case 'role':
      result = t('filterMarks.role');
      break;

    case 'last_serviceBefore':
      result = t('filterMarks.lastServiceBefore');
      break;

    case 'serviceType':
      result = t('filterMarks.service');
      break;

    case 'route_zone':
      result = t('filterMarks.zoneFilter');
      break;

    case 'estimated_gallons_to':
      result = t('filterMarks.estimated-gallons-to');
      break;

    case 'estimated_gallons_from':
      result = t('filterMarks.estimated-gallons-from');
      break;

    case 'ready_to_visit':
      result = t('filterMarks.readyToVisitFilter');
      break;

    default:
      break;
  }

  return result;
};
