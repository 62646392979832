import { useTranslation } from 'react-i18next';

type Props = {
  handleAction: () => void;
  icon: string;
  text?: string;
  emergency?: boolean;
  height?: number;
  id?: string;
};

const AddButton = ({
  handleAction,
  icon,
  text,
  emergency,
  height,
  id,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <button
      onClick={handleAction}
      id={id}
      className={`button-icon-reverse inline-flex items-center justify-center gap-2 rounded-[5px] border px-3 py-2 text-white hover:border-transparent hover:bg-gold hover:text-green ${
        emergency ? 'bg-ambar' : 'bg-green'
      }`}
      style={{ height: height ? `${height}px` : '41px' }}
    >
      <img src={icon} alt="add icon" className="h-[20px] w-[20px]" />
      <span className="text-[14px] font-normal ">
        {text || t('global.add-button')}
      </span>
    </button>
  );
};

export default AddButton;
