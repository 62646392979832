import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import type { RouteData } from '@/types/route';

import { ReactComponent as FatBoneIcon } from '../../../../../assets/icons/boneIcon.svg';
import { ReactComponent as HoodCleaningIcon } from '../../../../../assets/icons/cooker-hood.svg';
import { ReactComponent as GreaseTrapIcon } from '../../../../../assets/icons/grease-trap.svg';
import { ReactComponent as OilDisposalIcon } from '../../../../../assets/icons/oil-disposal.svg';
import {
  FAT_BONES_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
  routeOptions,
} from '../../../../../config/constants';
import { useDropdownData } from '../../../../../hooks/dropdownData';
import { useModal } from '../../../../../hooks/useModal';
import { useNotification } from '../../../../../hooks/useNotification';
import { driversListState } from '../../../../../recoil/driversList';
import { editModalState } from '../../../../../recoil/modalEdit';
import { trucksListState } from '../../../../../recoil/trucksList';
import { UPDATE_ROUTE } from '../../../../../services/graphql/route/route-mutations';
import { convertDateToString } from '../../../../../utils/convertDate';
import {
  formatNumber,
  formatNumberMoney,
  formatNumberPercentage,
} from '../../../../../utils/numberFormatter';
import DateField from '../../../common/DateField';
import DisabledInputField from '../../../common/DisabledInputField';
import Dropdown from '../../../common/Dropdown';

type Driver = {
  label: string;
  value: string;
};

type Props = {
  route: RouteData;
  refetchRoutes: () => void;
};

const RouteCard = ({ route, refetchRoutes }: Props) => {
  const { t } = useTranslation('common');
  const { id } = useParams();
  const { notifyUpdateError, notifyUpdateOk } = useNotification();

  // Recoil
  const drivers = useRecoilValue(driversListState);
  const trucks = useRecoilValue(trucksListState);

  // States
  const [selectedDriver, setSelectedDriver] = useState(route.driver_name);
  const [tempSelectedDriver, setTempSelectedDriver] = useState(
    route.driver_name,
  );
  const [dropdownDriver, setDropdownDriver] = useState<any>(route.user_id);
  const [selectedTruck, setSelectedTruck] = useState(route.truck_license);
  const [tempSelectedTruck, setTempSelectedTruck] = useState(
    route.truck_license,
  );
  const [dropdownTruck, setDropdownTruck] = useState<any>(route.truck_id);
  const [date, setDate] = useState<string>(route.date);
  const [tempDate, setTempDate] = useState<string>(route.date);
  const [routeUpdated, setRouteUpdated] = useState(false);
  const [dropdownKey, setDropdownKey] = useState(0);

  // Mutation

  const [updateRoute] = useMutation(UPDATE_ROUTE);

  // Constants

  const routeId = id;
  const editableStatuses = [routeOptions[0]?.value, routeOptions[1]?.value];
  // Modal
  const setEditModalState = useSetRecoilState(editModalState);
  const { handleCloseAllModals } = useModal();
  const closeOrder = () => {
    handleCloseAllModals();
  };

  // Hook for dropdown data
  useDropdownData(
    route.service,
    date,
    dropdownTruck,
    dropdownDriver,
    routeUpdated,
  );

  // Functions
  const totalEstimatedGallons = route.stops.reduce((total, stop) => {
    return total + (stop.stop.estimated_gallons || 0);
  }, 0);
  const totalPickedUpGallons = route.stops.reduce((total, stop) => {
    return total + (stop.stop.picked_up_gallons || 0);
  }, 0);

  const totalPickedUpBarrels = route.stops.reduce((total, stop) => {
    return total + (stop.stop.picked_up_barrel || 0);
  }, 0);
  const estimatedBarrels = route.stops.reduce((total, stop) => {
    return total + (stop.stop.estimated_barrel || 0);
  }, 0);

  useEffect(() => {
    setTempSelectedDriver(selectedDriver);
  }, [selectedDriver]);

  const handleDriverChange = (selectedOption: string) => {
    const newSelectedDriver: Driver = drivers.find(
      (driver: Driver) => driver.value === selectedOption,
    )!;

    if (!newSelectedDriver) {
      notifyUpdateError();
      return;
    }
    const newSelectedDriverLabel = newSelectedDriver.label;

    setTempSelectedDriver(newSelectedDriverLabel);

    if (newSelectedDriverLabel !== selectedDriver) {
      setEditModalState({
        isOpen: true,
        onConfirm: () => {
          const driverId = selectedOption;
          updateRoute({
            variables: {
              driverId,
              routeId,
            },
            onCompleted: () => {
              setRouteUpdated((prevRouteUpdated) => !prevRouteUpdated);
              setSelectedDriver(newSelectedDriverLabel);
              setDropdownDriver(driverId);
              refetchRoutes();
              closeOrder();
              notifyUpdateOk();
            },
            onError: () => {
              notifyUpdateError();
              setSelectedDriver(route.driver_name);
            },
          });
        },
        onCancel: () => {
          setSelectedDriver(route.driver_name);
          setDropdownKey(dropdownKey + 1);
        },
        message: t('global.edit-popup'),
        title: t('global.edit-popup-title'),
      });
    }
  };

  useEffect(() => {
    setTempSelectedTruck(selectedTruck);
  }, [selectedTruck]);

  const handleTruckChange = (selectedOption: string) => {
    const newSelectedTruck: any = trucks.find(
      (_truck: any) => _truck.value === selectedOption,
    );

    if (!newSelectedTruck) {
      notifyUpdateError();
      return;
    }

    const newSelectedTruckLabel = newSelectedTruck.label;

    setTempSelectedTruck(newSelectedTruckLabel);

    if (newSelectedTruckLabel !== selectedTruck) {
      setEditModalState({
        isOpen: true,
        onConfirm: () => {
          const truckId = newSelectedTruck.id;
          updateRoute({
            variables: {
              truckId,
              routeId,
            },
            onCompleted: () => {
              notifyUpdateOk();
              setRouteUpdated((prevRouteUpdated) => !prevRouteUpdated);
              refetchRoutes();
              setSelectedTruck(newSelectedTruckLabel);
              setDropdownTruck(truckId);
            },
            onError: () => {
              notifyUpdateError();
              setSelectedTruck(route.truck_license);
            },
          });
        },
        onCancel: () => {
          setSelectedTruck(route.truck_license);
          setTempSelectedTruck(route.truck_license);
          setDropdownKey(dropdownKey + 1);
        },
        message: t('global.edit-popup'),
        title: t('global.edit-popup-title'),
      });
    }
  };

  const handleDateChange = (selectedDate: string) => {
    if (selectedDate === tempDate) return;
    if (selectedDate) {
      setTempDate(selectedDate);

      setEditModalState({
        isOpen: true,
        onConfirm: () => {
          updateRoute({
            variables: {
              date: selectedDate,
              routeId,
            },
            onCompleted: () => {
              notifyUpdateOk();
              setRouteUpdated((prevRouteUpdated) => !prevRouteUpdated);
              refetchRoutes();
              setDate(tempDate);
            },
            onError: () => {
              setDate(route.date);
              notifyUpdateError();
            },
          });
        },
        onCancel: () => {
          setTempDate(date);
        },
        message: t('global.edit-popup'),
        title: t('global.edit-popup-title'),
      });
    }
  };

  return (
    <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3 md:gap-6 lg:grid-cols-4 xl:grid-cols-5">
      <div className="flex h-11 w-[200px] flex-col items-start justify-start">
        <label className="my-2 block text-[14px] font-[600]">
          {t('routes.route-card-service')}
        </label>
        <div className="mt-0.5 flex h-4 shrink grow basis-0 flex-row items-center gap-2 text-sm font-normal text-zinc-800">
          <div className="">
            {route.service === 'Oil Disposal' && (
              <OilDisposalIcon className="h-5 w-5" />
            )}
            {route.service === 'Grease Trap' && (
              <GreaseTrapIcon className="h-5 w-5" />
            )}{' '}
            {route.service === 'Hood Cleaning' && (
              <HoodCleaningIcon className="h-5 w-5" />
            )}
            {route.service === 'Meat, Fat & Bone' && (
              <FatBoneIcon className="h-5 w-5" />
            )}
          </div>
          <p
            className={`block w-full border-none p-0 text-xs  placeholder:text-[#5A5A5A] focus:outline-none focus:ring-0`}
          >
            {route.service}
          </p>
        </div>
      </div>
      <div className="flex h-11 w-[200px] flex-col items-start justify-start gap-2.5">
        <DateField
          label={t('routes.route-card-date')}
          placeholder="Date"
          selectedValue={
            tempDate
              ? new Date(tempDate).getTime().toString()
              : new Date(route.date).getTime().toString()
          }
          onSelectChange={(_date: any) => {
            const dateString = convertDateToString(_date);
            setTempDate(dateString);
            handleDateChange(dateString);
          }}
          onlyActualDate={true}
          disabled={!editableStatuses.includes(route.status)}
        />
      </div>
      <div className="min-h-[44px] w-[200px]">
        <Dropdown
          options={drivers}
          selectedValue={tempSelectedDriver}
          onSelectChange={handleDriverChange}
          placeholder={selectedDriver}
          key={dropdownKey}
          label={t('routes.route-card-driver')}
          disabled={!editableStatuses.includes(route.status)}
        />
      </div>
      <div className="min-h-[44px] w-[200px]">
        <Dropdown
          options={trucks}
          selectedValue={tempSelectedTruck}
          key={dropdownKey}
          onSelectChange={handleTruckChange}
          placeholder={selectedTruck}
          label={t('routes.route-card-truck')}
          disabled={!editableStatuses.includes(route.status)}
        />
      </div>

      <div className="min-h-[44px] w-[200px]">
        <DisabledInputField
          label={t('routes.route-card-mileage')}
          value={formatNumber(route.truck_mileage)}
          type="text"
        />
      </div>

      {route.service !== 'Hood Cleaning' &&
        route.service !== FAT_BONES_SERVICE_NAME && (
          <>
            <div className="min-h-[44px] w-[200px]">
              <DisabledInputField
                label={t('routes.route-card-estimated-gallons')}
                value={`${formatNumber(totalEstimatedGallons)} Gal`}
                type="text"
              />
            </div>
            <div className="min-h-[44px] w-[200px]">
              {route.service === OIL_DISPOSAL_SERVICE_NAME && (
                <DisabledInputField
                  label={t('routes.route-card-picked-up-gallons')}
                  value={`${formatNumber(totalPickedUpGallons)} Gal`}
                  type="text"
                />
              )}
            </div>
          </>
        )}
      {route.service === FAT_BONES_SERVICE_NAME && (
        <>
          <div className="min-h-[44px] w-[200px]">
            <DisabledInputField
              label={t('routes.route-card-estimated-barrel')}
              value={`${formatNumber(estimatedBarrels)}`}
              type="text"
            />
          </div>
          <div className="min-h-[44px] w-[200px]">
            <DisabledInputField
              label={t('routes.route-card-picked-up-barrel')}
              value={`${formatNumber(totalPickedUpBarrels)}`}
              type="text"
            />
          </div>
        </>
      )}
      {route.service === 'Oil Disposal' && (
        <>
          <div className="min-h-[44px] w-[200px]">
            <DisabledInputField
              label={t('routes.route-card-%-picked-up')}
              value={`${formatNumberPercentage(
                route.percentage_picked_up || 0,
              )}`}
              type="text"
            />
          </div>
          <div className="min-h-[44px] w-[200px]">
            <DisabledInputField
              label={t('routes.route-card-paid-to-clients')}
              value={`${formatNumberMoney(route.paid_to_clients)}`}
              type="text"
            />
          </div>
        </>
      )}

      <div className="min-h-[44px] w-[200px]">
        <DisabledInputField
          label={t('routes.route-card-drivers-spent')}
          value={`${formatNumberMoney(route.driver_spent)}`}
          type="text"
        />
      </div>
      {route.service !== OIL_DISPOSAL_SERVICE_NAME && (
        <div className="min-h-[44px] w-[200px]">
          <DisabledInputField
            label={t('routes.route-card-total-received')}
            value={`${formatNumberMoney(route.total_received)}`}
            type="text"
          />
        </div>
      )}
      {route.service === OIL_DISPOSAL_SERVICE_NAME && (
        <div className="min-h-[44px] w-[200px]">
          <DisabledInputField
            label={t('routes.route-card-total-spent')}
            value={`${formatNumberMoney(route.total_spent)}`}
            type="text"
          />
        </div>
      )}
    </div>
  );
};

export default RouteCard;
