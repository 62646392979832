import { NavLink } from 'react-router-dom';

import equipment from '../../../assets/icons/equipment.svg';
import licensing from '../../../assets/icons/licensing.svg';

type Props = {
  sidebarOpen: boolean;
  isSecondarySettingsOpen: boolean;
};

const SecondarySideBarSettings = ({
  sidebarOpen,
  isSecondarySettingsOpen,
}: Props) => {
  return (
    <div
      className={`fixed z-30 hidden h-full w-[58px] transition-all duration-1000 ${
        sidebarOpen ? 'ml-[212px]' : 'ml-[58px]'
      }  ${isSecondarySettingsOpen ? 'flex flex-row ' : 'hidden'}`}
    >
      <nav className="flex w-full flex-col justify-center bg-greenextralight pt-6 text-white">
        <div className="relative my-10">
          <div className="flex items-center justify-center">
            <ul className="flex flex-col items-center gap-6">
              <li id="dashboard" className="mb-6">
                <NavLink
                  to={`/dashboard/settings`}
                  end
                  className={({ isActive }) =>
                    isActive
                      ? 'navLinkActive flex gap-2 items-center titleAttr'
                      : 'navLinkInactive flex gap-2 titleAttr'
                  }
                  data-title="Licensing"
                >
                  <img className="h-[22px]" src={licensing} alt="licensing" />
                </NavLink>
              </li>

              <li className="mb-6">
                <NavLink
                  to={`/dashboard/settings/equipment`}
                  className={({ isActive }) =>
                    isActive
                      ? 'navLinkActive flex gap-2 items-center titleAttr'
                      : 'navLinkInactive flex gap-2 items-center titleAttr'
                  }
                  data-title="Equipment"
                >
                  <img
                    className="h-[22px]"
                    src={equipment}
                    alt="equipment icon"
                  />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SecondarySideBarSettings;
