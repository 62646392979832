import { gql, useMutation } from '@apollo/client';

const CREATE_WITHDRAW = gql`
  mutation CreateWithDraw($file: Upload!, $amount: Float!) {
    createWithDraw(file: $file, amount: $amount) {
      status
      code
      data
      error
    }
  }
`;

export function useCreateWithDraw() {
  const [createWithDraw, { data, loading, error }] =
    useMutation(CREATE_WITHDRAW);

  return { createWithDraw, data, loading, error };
}

const CREATE_DISBURSEMENT = gql`
  mutation CreateDisbursement(
    $amount: Float!
    $driverId: String!
    $receiptId: String!
  ) {
    createDisbursement(
      amount: $amount
      driverId: $driverId
      receiptId: $receiptId
    ) {
      status
      code
      data
      error
    }
  }
`;
export function useCreateDisbursement() {
  const [createDisbursement, { data, loading, error }] =
    useMutation(CREATE_DISBURSEMENT);

  return { createDisbursement, data, loading, error };
}
