import { useState } from 'react';
import Select from 'react-select';

import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import { statusTruckIncidentOptions } from '../../../config/constants';
import {
  colourStyles,
  CustomOption,
  CustomSingleValue,
} from '../../../styles/statusDropdownStyle';

type Props = {
  initialStatusValue: string;
  mutation: (arg: any) => void;
  incidentId: string;
  truckId: string;
};

const TruckIncidentStatusDropdown = ({
  initialStatusValue,
  mutation,
  incidentId,
  truckId,
}: Props) => {
  const [status, setStatus] = useState<string>(initialStatusValue);

  const handleChangeStatus = (selectedOption: any) => {
    const newStatus = selectedOption.value;
    setStatus(newStatus);

    mutation({
      variables: {
        status: selectedOption.value,
        incidentId,
        truckId,
      },
    });
  };

  return (
    <>
      <div className="absolute flex text-xs font-medium">
        <Select
          styles={colourStyles(status, statusTruckIncidentOptions)}
          components={{
            DropdownIndicator: () => <ArrowDownIcon />,
            IndicatorSeparator: () => null,
            Option: CustomOption,
            SingleValue: CustomSingleValue,
          }}
          options={statusTruckIncidentOptions}
          onChange={handleChangeStatus}
          id="TruckIncidentStatusDropdown"
          value={statusTruckIncidentOptions.find(
            (option) => option.value === status,
          )}
          isSearchable={false}
        />
      </div>
    </>
  );
};

export default TruckIncidentStatusDropdown;
