import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import iconAll from '../../assets/icons/activity.svg';
import iconAdd from '../../assets/icons/add-circle.svg';
import clientLogo from '../../assets/icons/clients-small.svg';
import iconDocument from '../../assets/icons/document.svg';
import iconNotes from '../../assets/icons/note.svg';
import iconVisits from '../../assets/icons/routing.svg';
import ClientFixedCard from '../../components/ui/clients/overall/ClientFixedCard';
import ClientPeopleCard from '../../components/ui/clients/overall/ClientPeopleCard';
import ClientServiceCard from '../../components/ui/clients/overall/ClientServiceCard';
import TimelineCard from '../../components/ui/clients/overall/TimelineCard';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import FileUploader from '../../components/ui/common/FileUploader';
import SentryInfiniteScroll from '../../components/ui/common/SentryInfiniteScroll';
import LoaderSmall from '../../components/ui/LoaderSmall';
import {
  FAT_BONES_SERVICE_NAME,
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
} from '../../config/constants';
import { useClientSelected } from '../../hooks/useClientSelected';
import { useModal } from '../../hooks/useModal';
import useTimeline from '../../hooks/useTimeline';
import { useUserData } from '../../hooks/useUserData';
import { needRefreshState } from '../../recoil/needRefresh';
import { UPDATE_CLIENT } from '../../services/graphql/client/client-mutations';
import { GET_CLIENT_BY_ID } from '../../services/graphql/client/client-querys';
import {
  DOCUMENT_CLIENT_UPLOAD,
  DOCUMENT_DELETE,
} from '../../services/graphql/document/document-mutations';
import { CREATE_NOTE_MUTATION } from '../../services/graphql/notes/notesMutation';
import type { ClientQuery } from '../../types/client';
import { convertToAmericanDateFormatWithTime } from '../../utils/convertTimestampWithTime';
import { extractIdFromNewInfo } from '../../utils/extractIdFromNewInfo';

const ClientDetailView = () => {
  const { t } = useTranslation('common');
  const { client } = useParams();

  // === STATES ===
  const [needRefresh, setNeedRefresh] = useRecoilState(needRefreshState);
  const [note, setNote] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
  const [filterStyle, setFilterStyle] = useState<string>('All');
  const [openDragAndDrop, setOpenDragAndDrop] = useState<boolean>(false);

  // === VISUAL NOTIFICATIONS ===

  const notifySaveError = () =>
    toast.error(t('clients-overall.notify-saved-error'));
  const notifyNoteError = () =>
    toast.error(t('clients-overall.notify-note-error'));
  const notifyNoteSaveOk = () =>
    toast.success(t('clients-overall.notify-note-saved'), {
      toastId: 'notifyNoteSaveOk',
    });
  const notifyDocumentError = () =>
    toast.error(t('clients-overall.notify-document-error'));
  const notifyDocumentUploaded = () =>
    toast.success(t('clients-overall.notify-document-uploaded'), {
      toastId: 'notifyDocumentUploaded',
    });
  const notifyDocumentDeleteError = () =>
    toast.error(t('clients-overall.notify-document-delete-error'));
  const notifyDocumentDeleteOk = () =>
    toast.success(t('clients-overall.notify-document-deleted'), {
      toastId: 'notifyDocumentDeleted',
    });

  // === QUERIES ===

  const { data, refetch } = useQuery<ClientQuery>(GET_CLIENT_BY_ID, {
    variables: { clientId: client },
  });

  // This is necessary to load client info on reload on this page.
  // This hook needs to be on every single client page so the global state
  // knows what to show on the secondary menu for the client we currently are in.
  useClientSelected(data);

  const {
    fetchMoreTimelines,
    loading,
    timelines,
    refetchTimeline,
    hasNextPage,
    errorTimeline,
  } = useTimeline();

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMoreTimelines,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!errorTimeline,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 20px 0px',
  });

  // === MUTATIONS ===
  const [updateClient] = useMutation<any>(UPDATE_CLIENT, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: () => {
      refetch();
      refetchTimeline('All');
      setFilterStyle('All');
    },
  });

  const [createNote] = useMutation<any>(CREATE_NOTE_MUTATION, {
    onError: () => {
      notifyNoteError();
    },
    onCompleted: () => {
      setNote('');
      notifyNoteSaveOk();
      refetchTimeline('Notes');
      setFilterStyle('Notes');
    },
  });

  const [uploadDocument] = useMutation<any>(DOCUMENT_CLIENT_UPLOAD, {
    onError: () => {
      notifyDocumentError();
    },
    onCompleted: () => {
      setNote('');
      notifyDocumentUploaded();
      setTimeout(() => {
        refetchTimeline('Document');
        setFilterStyle('Document');
      }, 1000);
    },
  });

  const [deleteDocument] = useMutation<any>(DOCUMENT_DELETE, {
    onError: () => {
      notifyDocumentDeleteError();
    },
    onCompleted: (result) => {
      if (result.deleteDocument.status === 'error') {
        notifyDocumentDeleteError();
        return;
      }

      setNote('');
      notifyDocumentDeleteOk();
      refetchTimeline('All');
      setFilterStyle('All');
    },
  });

  const { id } = useUserData();

  const handleSaveNote = () => {
    if (note) {
      createNote({
        variables: {
          note,
          clientId: client,
          userId: id,
          routeId: null,
        },
      });
    } else {
      notifyNoteError();
    }
  };

  // Modals
  const { handleOpenNewPersonModal, handleOpenNewServiceModal } = useModal();

  const handleOpenModalNewPeople = () => {
    handleOpenNewPersonModal();
  };

  const handleOpenModalNewService = () => {
    handleOpenNewServiceModal();
  };

  const handleFileChange = (file: File | null) => {
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleTypeChange = (type: string | null) => {
    setSelectedFileType(type);
  };

  const openInputDragAndDrop = () => {
    setOpenDragAndDrop(true);
  };
  const closeInputDragAndDrop = () => {
    setOpenDragAndDrop(false);
  };

  const submitFile = async () => {
    if (selectedFile) {
      uploadDocument({
        variables: {
          file: selectedFile,
          clientId: client,
          type: selectedFileType,
        },
        context: {
          headers: {
            'content-type': 'multipart/form-data',
            'x-apollo-operation-name': 'uploadFile',
          },
        },
      });
      setSelectedFile(null);
      setOpenDragAndDrop(false);
    }
  };

  const applyTimelineFilter = (type: string) => () => {
    if (type === filterStyle) return;
    const filter = type === 'All' ? undefined : type;
    // refetchTimeline({ type: filter });
    refetchTimeline(filter);
    setFilterStyle(type);
  };

  useEffect(() => {
    if (needRefresh) {
      refetch();

      setTimeout(() => {
        setNeedRefresh(false);
      }, 1000);
    }
  }, [needRefresh]);

  return (
    <>
      <section>
        <div className="fixed top-0 z-20 w-full bg-white">
          <Breadcrumb
            title={`${t('clients.title-h1')}`}
            secondTitle={data?.getClientById?.data.client.name}
            image={clientLogo}
          />
        </div>
        <div className="mt-[30px] flex w-full flex-col md:flex-row">
          {/* Client Business Card */}
          <div className="flex w-[260px]">
            {data && client && (
              <ClientFixedCard
                client={client}
                data={data}
                updateClient={updateClient}
              />
            )}
          </div>

          <div className="flex w-full flex-col px-6 pt-6">
            {/* People */}
            <div className="w-full">
              <h1 className="mb-2 text-xl font-semibold">
                {t('clients-overall.people')}
              </h1>
              <div className="flex w-full flex-wrap gap-3">
                {data &&
                  data?.getClientById?.data.clientPeople &&
                  data?.getClientById?.data.clientPeople.length === 0 && (
                    <div className="box-shadow flex h-[122px] w-[250px] items-center justify-center rounded-lg">
                      <p className="text-xs italic">
                        {t('clients-overall.add-people')}
                      </p>
                    </div>
                  )}
                {data &&
                  data?.getClientById?.data.clientPeople &&
                  data?.getClientById?.data.clientPeople.length > 0 &&
                  data?.getClientById?.data.clientPeople.map((person) => (
                    <ClientPeopleCard person={person} key={person.id} />
                  ))}
                <button
                  onClick={handleOpenModalNewPeople}
                  id="addPeople"
                  className="box-shadow flex h-[122px] w-[74px] items-center justify-center rounded-lg"
                >
                  <img src={iconAdd} alt="add icon" />
                </button>
              </div>
            </div>

            {/* Services */}
            <div className="w-full">
              <h1 className="my-2 text-xl font-semibold">
                {t('clients-overall.services')}
              </h1>
              <div className="flex w-full flex-wrap gap-3">
                {data &&
                  data?.getClientById?.data.clientService &&
                  data?.getClientById?.data.clientService.length === 0 && (
                    <div className="box-shadow flex h-[122px] w-[250px] items-center justify-center">
                      <p className="text-xs italic">
                        {t('clients-overall.add-service')}
                      </p>
                    </div>
                  )}
                {data &&
                  data?.getClientById?.data.clientService &&
                  data?.getClientById?.data.clientService.length > 0 && (
                    <>
                      {/* First service equal to "Oil Disposal" */}
                      {data?.getClientById?.data.clientService.find(
                        (service) => service.name === OIL_DISPOSAL_SERVICE_NAME,
                      ) && (
                        <ClientServiceCard
                          client={client || ''}
                          service={
                            data.getClientById.data.clientService.find(
                              (service) =>
                                service.name === OIL_DISPOSAL_SERVICE_NAME,
                            )!
                          }
                          key={'oilDisposal'}
                          id="oilDisposal"
                        />
                      )}

                      {/* First service equal to  "Hood Cleaning" */}
                      {data?.getClientById?.data.clientService.find(
                        (service) =>
                          service.name === HOOD_CLEANING_SERVICE_NAME,
                      ) && (
                        <ClientServiceCard
                          client={client || ''}
                          service={
                            data?.getClientById?.data.clientService.find(
                              (service) =>
                                service.name === HOOD_CLEANING_SERVICE_NAME,
                            )!
                          }
                          key={'hoodCleaning'}
                          id="hoodCleaning"
                        />
                      )}

                      {/* First service equal to  "Grease Trap Cleaning" */}
                      {data?.getClientById?.data.clientService.find(
                        (service) => service.name === GREASE_TRAP_SERVICE_NAME,
                      ) && (
                        <ClientServiceCard
                          client={client || ''}
                          service={
                            data?.getClientById?.data.clientService.find(
                              (service) =>
                                service.name === GREASE_TRAP_SERVICE_NAME,
                            )!
                          }
                          key={'greaseTrapCleaning'}
                          id="greaseTrapCleaning"
                        />
                      )}

                      {/* First service equal to  "Fat Bone" */}
                      {data?.getClientById?.data.clientService.find(
                        (service) => service.name === FAT_BONES_SERVICE_NAME,
                      ) && (
                        <ClientServiceCard
                          client={client || ''}
                          service={
                            data?.getClientById?.data.clientService.find(
                              (service) =>
                                service.name === FAT_BONES_SERVICE_NAME,
                            )!
                          }
                          key={'fatBones'}
                          id="fatBones"
                        />
                      )}
                    </>
                  )}
                {data?.getClientById?.data.clientService.find(
                  (service) => service.name === GREASE_TRAP_SERVICE_NAME,
                ) &&
                data?.getClientById?.data.clientService.find(
                  (service) => service.name === HOOD_CLEANING_SERVICE_NAME,
                ) &&
                data?.getClientById?.data.clientService.find(
                  (service) => service.name === FAT_BONES_SERVICE_NAME,
                ) &&
                data?.getClientById?.data.clientService.find(
                  (service) => service.name === OIL_DISPOSAL_SERVICE_NAME,
                ) ? null : (
                  <button
                    onClick={handleOpenModalNewService}
                    id="addService"
                    className="box-shadow flex h-[122px] w-[74px] items-center justify-center rounded-lg"
                  >
                    <img src={iconAdd} alt="add icon" />
                  </button>
                )}
              </div>
            </div>

            {/* Timeline */}
            <div className="w-full">
              <h1 className="my-2 text-xl font-semibold">
                {t('clients-overall.timeline')}
              </h1>
              <div className="flex h-[30px] w-full items-start gap-6">
                <button
                  onClick={applyTimelineFilter('All')}
                  className={`flex items-center gap-1 border-green hover:border-b-2 ${
                    filterStyle === 'All' ? 'border-b-2' : ''
                  }`}
                >
                  <img src={iconAll} alt="all timeline icon" />
                  <span className="text-xs">{t('clients-overall.all')}</span>
                </button>
                <button
                  onClick={applyTimelineFilter('Visits')}
                  className={`flex items-center gap-1 border-green hover:border-b-2 ${
                    filterStyle === 'Visits' ? 'border-b-2' : ''
                  }`}
                >
                  <img src={iconVisits} alt="visits timeline icon" />
                  <span className="text-xs">{t('clients-overall.visits')}</span>
                </button>
                <button
                  onClick={applyTimelineFilter('Notes')}
                  className={`flex items-center gap-1 border-green hover:border-b-2 ${
                    filterStyle === 'Notes' ? 'border-b-2' : ''
                  }`}
                >
                  <img src={iconNotes} alt="notes timeline icon" />
                  <span className="text-xs">{t('clients-overall.notes')}</span>
                </button>
                <button
                  onClick={applyTimelineFilter('Document')}
                  className={`flex items-center gap-1 border-green hover:border-b-2 ${
                    filterStyle === 'Document' ? 'border-b-2' : ''
                  }`}
                >
                  <img src={iconDocument} alt="document timeline icon" />
                  <span className="text-xs">
                    {t('clients-overall.documents')}
                  </span>
                </button>
              </div>
              {openDragAndDrop ? (
                <FileUploader
                  onFileChange={handleFileChange}
                  onFileTypeChange={handleTypeChange}
                  closeFileUploader={closeInputDragAndDrop}
                  submitFile={submitFile}
                  view="clients"
                />
              ) : (
                <div className="relative my-2">
                  <textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    autoCorrect="off"
                    className="box-shadow h-[120px] w-full rounded-lg border-none py-4 pl-4 text-blacker placeholder:text-greenextralight focus:shadow-custom focus:ring-0 md:h-[80px] md:pr-[225px]"
                    name="timelineTextearea"
                    id="timelineTextearea"
                    placeholder={t('clients-overall.add-text')}
                  ></textarea>
                  <div className="absolute bottom-5 right-5 flex items-center justify-center gap-3">
                    <button
                      onClick={openInputDragAndDrop}
                      id="uploadDocument"
                      className="flex items-center justify-center rounded-md border-[1px] border-green bg-white px-2 py-1 text-xs hover:bg-green hover:text-white"
                    >
                      {t('clients-overall.upload')}
                    </button>
                    <button
                      onClick={handleSaveNote}
                      id="saveNote"
                      className="button-icon-reverse flex items-center justify-center rounded-md border-[1px] border-green bg-green px-2 py-1 text-xs text-white hover:border-transparent hover:bg-gold hover:text-green"
                    >
                      {t('clients-overall.save')}
                    </button>
                  </div>
                </div>
              )}

              <div className="flex w-full flex-col">
                {/* Timeline items */}
                {timelines && timelines.length === 0 && (
                  <div className="flex justify-center">
                    <p className="text-center text-xs italic">
                      {t('clients-overall.no-timeline-items')}
                    </p>
                  </div>
                )}
                {loading && <LoaderSmall />}
                {timelines &&
                  [...timelines].map((timeline) => (
                    <TimelineCard
                      key={
                        timeline.createdAt +
                        timeline.timeline_title +
                        Math.random()
                      }
                      title={timeline.timeline_title}
                      description={timeline.timeline_description || ''}
                      author={timeline.user_name || ''}
                      date={convertToAmericanDateFormatWithTime(
                        timeline.createdAt,
                      )}
                      type={timeline.type}
                      id={
                        timeline.new_info &&
                        extractIdFromNewInfo(timeline.new_info)
                      }
                      deleteDocument={deleteDocument}
                      documentDeleted={timeline.document_deleted || false}
                    />
                  ))}
                {(loading || hasNextPage) && (
                  <SentryInfiniteScroll ref={sentryRef}>
                    <LoaderSmall />
                  </SentryInfiniteScroll>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientDetailView;
