import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import clientIcon from '../../../assets/icons/clients-small.svg';
import iconZone from '../../../assets/icons/location.svg';
import iconService from '../../../assets/icons/receipt-item.svg';
import iconFrequency from '../../../assets/icons/repeat-circle.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import {
  FAT_BONES_SERVICE_NAME,
  serviceClientOptions,
  serviceFrequencyOptions,
  serviceZoneOptions,
} from '../../../config/constants';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { selectedClient } from '../../../recoil/navigation';
import { needRefreshState } from '../../../recoil/needRefresh';
import { GET_CLIENT_BY_ID } from '../../../services/graphql/client/client-querys';
import { CREATE_SERVICE } from '../../../services/graphql/service/service-mutations';
import type { ClientQuery } from '../../../types/client';
import Breadcrumb from '../../ui/common/Breadcrumb';
import Dropdown from '../../ui/common/Dropdown';
import LoaderSmall from '../../ui/LoaderSmall';

const NewService = () => {
  const { t } = useTranslation('common');
  const {
    notifyEmptyWarn,
    notifyExistsWarn,
    notifySaveSuccess,
    notifySaveError,
  } = useNotification();

  const [client, setClient] = useRecoilState(selectedClient);

  // States
  const [serviceSelectedValue, setServiceSelectedValue] = useState('');
  const [frequencySelectedValue, setFrequencySelectedValue] = useState('');
  const [zoneSelectedValue, setZoneSelectedValue] = useState('');
  const [frequencyBlocked, setFrequencyBlocked] = useState(false);

  const breadcrumbTitle = t('clients.title-h1');
  const breadcrumbSecondTitle = client.name;
  const breadcrumbThirdTitle = t('modalNewService.newServiceBreadcrumb');

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  // Querys
  const { data } = useQuery<ClientQuery>(GET_CLIENT_BY_ID, {
    variables: { clientId: client.id },
  });

  // Mutations
  const [createService, { loading: createServiceLoading }] = useMutation<any>(
    CREATE_SERVICE,
    {
      variables: {
        service: serviceSelectedValue,
        frequency: frequencySelectedValue,
        zone: zoneSelectedValue,
        clientId: client.id,
      },
      onCompleted: () => {
        setClient((prev) => ({
          ...prev,
          services: [...(prev.services || []), serviceSelectedValue],
        }));

        notifySaveSuccess();
        closeOrder();
      },
      onError: () => {
        notifySaveError();
      },
    },
  );

  const handleServiceDropdownChange = (selectedOption: string) => {
    setServiceSelectedValue(selectedOption);
    setFrequencyBlocked(false);
    if (selectedOption === FAT_BONES_SERVICE_NAME) {
      setFrequencySelectedValue('Weekly');
      setFrequencyBlocked(true);
    }
  };

  const handleFrequencyDropdownChange = (selectedOption: string) => {
    setFrequencySelectedValue(selectedOption);
  };

  const handleZoneDropdownChange = (selectedOption: string) => {
    setZoneSelectedValue(selectedOption);
  };

  // On Submit
  const onSubmit = (e: any) => {
    e.preventDefault();

    // If the serviceSelectedValue is present in data then return
    const serviceExist = data?.getClientById?.data.clientService?.find(
      (service) => service.name === serviceSelectedValue,
    );

    if (serviceExist) {
      notifyExistsWarn();
      return;
    }

    if (serviceSelectedValue || frequencySelectedValue || zoneSelectedValue) {
      createService();
    } else {
      notifyEmptyWarn();
    }
  };

  // Remove the services that are already in the client
  const existingServiceNames = data?.getClientById?.data.clientService.map(
    (service) => service.name,
  );

  const filteredServiceClientOptions = serviceClientOptions.filter(
    (option) => !existingServiceNames?.some((name) => name === option.value),
  );

  return (
    <>
      <Breadcrumb
        title={breadcrumbTitle}
        secondTitle={breadcrumbSecondTitle}
        thirdTitle={breadcrumbThirdTitle}
        image={clientIcon}
        id="modalService"
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalNewService.newServiceTitle')}
      </h1>
      <form className="my-6">
        <Dropdown
          label={t('modalNewService.service')}
          selectedValue={serviceSelectedValue}
          onSelectChange={handleServiceDropdownChange}
          options={filteredServiceClientOptions}
          icon={iconService}
          id="service"
        />

        <Dropdown
          label={t('modalNewService.frequency')}
          selectedValue={frequencySelectedValue}
          onSelectChange={handleFrequencyDropdownChange}
          options={serviceFrequencyOptions}
          icon={iconFrequency}
          disabled={frequencyBlocked}
          id="frequency"
        />

        <Dropdown
          label={t('modalNewService.zone')}
          selectedValue={zoneSelectedValue}
          onSelectChange={handleZoneDropdownChange}
          options={serviceZoneOptions}
          icon={iconZone}
          id="zone"
        />

        <div className="mt-12 flex justify-end">
          <button
            onClick={onSubmit}
            disabled={createServiceLoading}
            id="saveService"
            className="button-icon-reverse flex h-[41px] w-[84px] items-center justify-center gap-2 rounded-md bg-green p-2 text-xs text-white  hover:bg-gold hover:text-green"
          >
            {createServiceLoading ? (
              <LoaderSmall />
            ) : (
              <>
                <img src={saveBigIcon} alt="save icon" />
                <p>{t('modalNewClient.save')}</p>
              </>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default NewService;
