import { selector } from 'recoil';

import type { DisbursementFilter } from './disburseFilter';
import { disburseFilterState } from './disburseFilter';

export const cleanDisburseFiltersState = selector({
  key: 'cleanDisburseFilters',
  get: ({ get }) => {
    const currentFilters = get(disburseFilterState);

    // Clean the filters and return a new copy with everything null
    const cleanedFilters: DisbursementFilter = {};
    Object.keys(currentFilters || {}).forEach((key) => {
      cleanedFilters[key] = null;
    });

    return cleanedFilters;
  },
});
