import { gql } from '@apollo/client';

export const GET_ROUTES_BY_STATUS = gql`
  query GetRoutes(
    $statusFilter: [String]
    $limit: Int
    $orderBy: String
    $orderDirection: String
    $offset: Int
  ) {
    getRoutes(
      statusFilter: $statusFilter
      limit: $limit
      orderBy: $orderBy
      orderDirection: $orderDirection
      offset: $offset
    ) {
      code
      count
      data {
        id
        truck_id
        truck_license
        route_name
        driver
        date
        service
        status
        createdAt
        updatedAt
      }
      status
    }
  }
`;

export const GET_SCHEDULED_ROUTES_TODAY = gql`
  query GetRoutes(
    $statusFilter: [String]
    $dateStartFilter: String!
    $dateEndFilter: String!
  ) {
    getRoutes(
      statusFilter: $statusFilter
      dateStartFilter: $dateStartFilter
      dateEndFilter: $dateEndFilter
    ) {
      code
      count
      status
    }
  }
`;

export const GET_STOPS_YESTERDAY = gql`
  query GetRoutes($dateStartFilter: String!, $dateEndFilter: String!) {
    getRoutes(
      dateStartFilter: $dateStartFilter
      dateEndFilter: $dateEndFilter
    ) {
      code
      count
      data {
        stops {
          status
        }
      }
      status
    }
  }
`;

export const GET_ROUTE_BY_ID = gql`
  query GetRouteById($routeId: String!) {
    getRouteById(routeId: $routeId) {
      status
      code
      error
      data {
        route_name
        date
        estimated_gallons
        picked_up_gallons
        estimated_barrel
        picked_up_barrel
        percentage_picked_up
        service
        status
        truck_id
        truck_license
        truck_mileage
        driver_name
        user_id
        driver_spent
        paid_to_clients
        total_spent
        total_received
        payment_status
        stops {
          stop {
            id
            route_id
            client_id
            date_time_arrived
            estimated_gallons
            picked_up_gallons
            picked_up_barrel
            estimated_barrel
            payments
            payment_type
            payment_status
            invoice_id
            status
            createdAt
            updatedAt
          }
          client_service {
            id
            client_id
            name
            frequency
            desired_day
            last_service
            available_days
            next_scheduled
            container_size
            container_location
            barrel_size
            barrel_number
            paid_amount
            route_zone
            ready_to_visit
            paid_client
            createdAt
            updatedAt
            client_name
            client_address
            client_phone
            client_coords {
              type
              coordinates
            }
          }
          documents {
            id
            client_id
            name
            stop_id
            truck_id
            type
            url
            date
            createdAt
            updatedAt
            deletedAt
          }
        }
        driverReceipts {
          id
          route_id
          amount
          createdAt
          updatedAt
          image
          category
          date
        }
      }
    }
  }
`;

export const GET_ALL_ROUTES = gql`
  query GetRoutes(
    $licenseFilter: [String]
    $statusFilter: [String]
    $dateStartFilter: String
    $dateEndFilter: String
    $serviceFilter: [String]
    $userIdFilter: [String]
    $deletedFilter: Boolean
    $routeNameFilter: String
    $orderBy: String
    $orderDirection: String
    $offset: Int
    $limit: Int
  ) {
    getRoutes(
      limit: $limit
      offset: $offset
      licenseFilter: $licenseFilter
      statusFilter: $statusFilter
      dateStartFilter: $dateStartFilter
      dateEndFilter: $dateEndFilter
      serviceFilter: $serviceFilter
      userIdFilter: $userIdFilter
      deletedFilter: $deletedFilter
      routeNameFilter: $routeNameFilter
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      count
      data {
        id
        user_id
        truck_id
        route_name
        date
        estimated_gallons
        picked_up_gallons
        service
        truck_mileage
        status
        payment_status
        createdAt
        updatedAt
        truck_license
        driver
        truck_incidents
        stops {
          id
          route_id
          client_id
          date_time_arrived
          estimated_gallons
          picked_up_gallons
          picked_up_barrel
          estimated_barrel
          payments
          payment_type
          status
          createdAt
          updatedAt
        }
      }
      error
    }
  }
`;

export const GET_ALL_ROUTES_FULL = gql`
  query GetRoutes(
    $limit: Int
    $offset: Int
    $licenseFilter: [String]
    $statusFilter: [String]
    $dateStartFilter: String
    $dateEndFilter: String
    $serviceFilter: [String]
    $userIdFilter: [String]
    $deletedFilter: Boolean
    $routeNameFilter: String
    $orderBy: String
    $orderDirection: String
  ) {
    getRoutes(
      limit: $limit
      offset: $offset
      licenseFilter: $licenseFilter
      statusFilter: $statusFilter
      dateStartFilter: $dateStartFilter
      dateEndFilter: $dateEndFilter
      serviceFilter: $serviceFilter
      userIdFilter: $userIdFilter
      deletedFilter: $deletedFilter
      routeNameFilter: $routeNameFilter
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      count
      data {
        id
        user_id
        truck_id
        route_name
        date
        estimated_gallons
        picked_up_gallons
        service
        truck_mileage
        status
        payment_status
        createdAt
        updatedAt
        truck_license
        driver
        stops {
          id
          route_id
          client_id
          date_time_arrived
          estimated_gallons
          picked_up_gallons
          picked_up_barrel
          estimated_barrel
          payments
          payment_type
          status
          createdAt
          updatedAt
        }
      }
      error
    }
  }
`;
