import { atom } from 'recoil';

export interface Filters {
  clientNameFilter?: string | null;
  statusFilter?: string[] | null;
  lastServiceStartDateFilter?: string | null;
  lastServiceEndDateFilter?: string | null;
  zoneFilter?: string[] | null;
  readyToVisitFilter?: boolean | null;
  availableDays?: string[] | null;
  orderBy?: string | null;
  orderDirection?: 'ASC' | 'DESC' | null;
  [key: string]: string | string[] | boolean | null | undefined;
}

export const clientFiltersState = atom<Filters | null>({
  key: '_clientsFiltersState',
  default: null,
});
