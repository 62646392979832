import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import closeIcon from '../../assets/icons/close-square.svg';
import { tokenStateAtom } from '../../recoil/authState';
import { isImageModalOpenAtom } from '../../recoil/modalImage';
import Loader from '../ui/Loader';

type Props = {
  toggleModal: () => void;
};

function ModalFullImage({ toggleModal }: Props) {
  const { t } = useTranslation('common');

  const token = useRecoilValue(tokenStateAtom);
  const imageObject = useRecoilValue(isImageModalOpenAtom);
  const [imgSrc, setImgSrc] = useState<string>('');
  const [pdfSrc, setPdfSrc] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (token && imageObject?.documentUrl) {
      setLoading(true);
      setError(null);

      const url = imageObject.isQR
        ? process.env.REACT_APP_QR_URL
        : process.env.REACT_APP_FILE_URL;

      fetch(`${url}${imageObject?.documentUrl}`, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          'apollo-require-preflight': 'true',
          'Accept-Encoding': 'gzip, deflate, br',
        }),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Failed to load the resource (HTTP ${res.status})`);
          }
          return res.blob();
        })
        .then((blob) => {
          if (imageObject.documentUrl.includes('.pdf')) {
            const pdfURL = URL.createObjectURL(
              new Blob([blob], { type: 'application/pdf' }),
            );
            setPdfSrc(pdfURL);
          } else {
            const imgURL = URL.createObjectURL(blob);
            setImgSrc(imgURL);
          }

          setLoading(false);
        })
        .catch(() => {
          setError(t('global.failed-to-load'));
          setLoading(false);
        });
    }
  }, []);

  return (
    <div>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="fixed inset-0 bg-gray-800 opacity-50"
          onClick={toggleModal}
        ></div>
        <div className="fixed left-1/2 top-1/2 max-h-[80%] min-h-[50%] w-[80%] -translate-x-1/2 -translate-y-1/2 overflow-auto rounded-[20px] bg-white p-6 shadow-lg md:px-12 md:py-16">
          <button
            onClick={toggleModal}
            className="absolute right-2 top-2 m-2 cursor-pointer text-lg text-gray-500"
          >
            <img src={closeIcon} alt="close icon" />
          </button>
          <div className="flex items-center justify-center">
            {loading && <Loader />}
            {error && <p className="text-redish">{error}</p>}
            {!loading && !error && imgSrc && <img src={imgSrc} alt="image" />}
            {!loading && !error && pdfSrc && (
              <embed src={pdfSrc} width="100%" className="h-[65vh]" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalFullImage;
