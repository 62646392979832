import { gql } from '@apollo/client';

export const HAS_NOTIFICATIONS = gql`
  query GetRouteById($routeId: String!) {
    getRouteById(routeId: $routeId) {
      status
      code
      error
      data {
        notes {
          id
          user_id
          route_id
          note
          createdAt
          updatedAt
          deletedAt
          client_id
          user_name
        }
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($userId: String!) {
    getNotifications(userId: $userId) {
      status
      code
      count
      error
      data {
        id
        user_id
        message
        title
        type
        link
        object_id
        createdAt
        readAt
        updatedAt
      }
    }
  }
`;

export const HAS_UNREAD = gql`
  query HasUnreadedNotifications($userId: String!) {
    hasUnreadedNotifications(userId: $userId) {
      status
      code
      data
      error
    }
  }
`;
