/* eslint-disable unused-imports/no-unused-vars */
import 'react-datepicker/dist/react-datepicker.css';

import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import newWithDrawIcon from '../../../assets/icons/newWithDrawIcon.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { useCreateDisbursement } from '../../../hooks/createWithDraw';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { cashDisbursedID } from '../../../recoil/dataToEdit';
import { needRefreshState } from '../../../recoil/needRefresh';
import { GET_WITHDRAWS } from '../../../services/graphql/cashdeposit/cashdeposit.queries';
import { GET_USERS } from '../../../services/graphql/user/user-query';
import type { WithDrawTable } from '../../../types/tables';
import type { UsersQuery } from '../../../types/user';
import { formatNumberMoney } from '../../../utils/numberFormatter';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import Dropdown from '../../ui/common/Dropdown';
import InputField from '../../ui/common/InputField';

const NewCashDisburse = () => {
  const { t } = useTranslation('common');
  const { notifySaveError, notifySaveSuccess } = useNotification();
  const [data, setData] = useState([]);
  const cashDisburseId = useRecoilState(cashDisbursedID);
  const [receipt, setReceipt] = useState(cashDisburseId[0] || '');
  const [driver, setDriver] = useState('');
  const [drivers, setDrivers] = useState([{ label: '', value: '' }]);
  const [maxValue, setMaxValue] = useState(0);
  const [selectedReceiptId, setSelectedReceiptId] = useState(cashDisburseId[0]);
  const setCashDisburseId = useSetRecoilState(cashDisbursedID);

  const { data: fetchData, refetch } = useQuery(GET_WITHDRAWS, {
    variables: {
      orderBy: 'withdraw_date',
      orderDirection: 'DESC',
      minimun: 0,
    },
  });

  const { data: fetchDrivers } = useQuery<UsersQuery>(GET_USERS, {
    variables: {
      orderBy: 'name',
      orderDirection: 'ASC',
      filterRole: 'Driver',
    },
  });

  useEffect(() => {
    if (fetchData && fetchData.getWithdraw?.data) {
      const updatedData = fetchData.getWithdraw.data.map(
        (withdraw: WithDrawTable) => ({
          value: withdraw.id,
          label: withdraw.receiptId || '',
          notDisbursed: withdraw.notDisbursed || 0,
        }),
      );
      setData(updatedData);
    }
    if (fetchDrivers && fetchDrivers.getUsers) {
      const updatedData = fetchDrivers.getUsers.data.map((user) => ({
        value: user.id,
        label: user.name,
      }));
      setDrivers(updatedData);
    }
  }, [fetchData, fetchDrivers]);

  const [disburseAmount, setDisburseAmount] = useState<number>(maxValue);
  const { createDisbursement, loading: createWithDrawLoading } =
    useCreateDisbursement();
  const { handleCloseAllModals } = useModal();
  const setNeedRefresh = useSetRecoilState(needRefreshState);
  const refreshOrder = () => {
    setNeedRefresh('cashDisburse');
  };
  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
    setCashDisburseId(null);
    setSelectedReceiptId('');
  };

  const handleAmountChange = (value: number | string) => {
    setDisburseAmount(Number(value));
  };

  const handleSubmit = () => {
    if (!disburseAmount || !receipt || !driver) {
      notifySaveError();
      return;
    }
    createDisbursement({
      variables: {
        amount: disburseAmount,
        receiptId: receipt,
        driverId: driver,
      },
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
        refetch();
        setCashDisburseId('');
        setSelectedReceiptId('');
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  const selectReceiptId = (value: string) => {
    setReceipt(value);
    const selectedReceipt: any = data.find((item: any) => item.value === value);
    setMaxValue(selectedReceipt?.notDisbursed);
  };

  useEffect(() => {
    if (selectedReceiptId && data) {
      const selectedReceipt: any = data.find(
        (item: any) => item.value === selectedReceiptId,
      );
      setMaxValue(selectedReceipt?.notDisbursed);
    }
  }, [selectedReceiptId, data]);

  return (
    <>
      <Breadcrumb
        title={t('modalNewCashDisburse.newReceiptBreadcrumbTitle')}
        secondTitle={t('modalNewCashDisburse.newReceiptBreadcrumb')}
        image={newWithDrawIcon}
      />
      <p className="my-6 text-xl font-[600]">
        {t('modalNewCashDisburse.newReceiptTitle')}
      </p>
      <div className="flex flex-col">
        <Dropdown
          label={t('modalNewCashDisburse.receipt')}
          id="receiptID"
          onSelectChange={selectReceiptId}
          options={data}
          selectedValue={selectedReceiptId || 'Choose an Option'}
          placeholder="Choose an option"
        />
        <span className="flex justify-end font-inter text-[12px] italic text-greenlight">
          {t('modalNewCashDisburse.amountNotDisbursed')}:{' '}
          {formatNumberMoney(maxValue)}
        </span>
      </div>

      <InputField
        label={t('modalNewCashDisburse.amount')}
        type="number"
        onSelectChange={handleAmountChange}
        placeholder={formatNumberMoney(maxValue)}
        doNotUseSaveSelfButton={true}
        max={maxValue}
      />
      <Dropdown
        label={t('modalNewCashDisburse.driver')}
        id="driver"
        onSelectChange={(value) => {
          setDriver(value);
        }}
        options={drivers}
        selectedValue="Choose an option"
        placeholder="Choose an option"
      />

      <div className="mt-6 flex justify-end">
        <Button
          text={t('modalNewCashDisburse.save')}
          variant="green"
          icon={saveBigIcon}
          disabled={createWithDrawLoading}
          loading={createWithDrawLoading}
          onClick={handleSubmit}
          type="button"
          id="save-new-receipt"
        />
      </div>
    </>
  );
};

export default NewCashDisburse;
