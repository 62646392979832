import { gql } from '@apollo/client';

export const GET_GEOCODING_SUGGESTIONS = gql`
  query GetAddressSuggestions($address: String!) {
    getAddressSuggestions(address: $address) {
      status
      code
      data {
        place
        coords
      }
      error
    }
  }
`;
