import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as NotFound } from '../assets/images/not-found.svg';

const NotFoundView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const handleReturnToDashboard = () => {
    navigate('/dashboard');
  };
  return (
    <div className="flex h-[90vh] items-center justify-center">
      <div className="m-auto flex flex-col items-center justify-center gap-6">
        <NotFound />
        <p className="text-3xl font-bold">{t('notFound.title')}</p>
        <p className="w-1/2 text-center text-sm font-normal text-black">
          {t('notFound.message')}
        </p>
        <button
          onClick={handleReturnToDashboard}
          className="h-[41px] w-[164px] items-center justify-center rounded-[5px] bg-gray-700 p-2 text-sm text-white"
        >
          {t('notFound.button')}
        </button>
      </div>
    </div>
  );
};

export default NotFoundView;
