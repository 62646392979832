import { atom } from 'recoil';

export interface WorkerFilters {
  filterName?: string | null;
  filterStatus?: string[] | null;
  filterRole?: string | null;
  filterDeleted?: boolean | null;
  orderBy?: string | null;
  orderDirection?: 'ASC' | 'DESC' | null;
  limit?: number | null;
  offset?: number | null;
  [key: string]: string | string[] | boolean | number | null | undefined;
}

export const workerFiltersState = atom<WorkerFilters | null>({
  key: '_workersFiltersState',
  default: null,
});
