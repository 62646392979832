import { useEffect, useState } from 'react';

import editIcon from '../../../assets/icons/edit.svg';
import saveIcon from '../../../assets/icons/save-small.svg';

type Props = {
  label?: string;
  selectedValue?: string | number | null;
  onSelectChange?: (value: string | number) => void;
  placeholder?: string | number;
  doNotUseSaveSelfButton?: boolean;
  type: string;
  register?: any;
  registerName?: string;
  validationSchema?: any;
  errors?: any;
  icon?: any;
  id?: string;
  unitGal?: boolean;
  max?: number;
};

const InputField = ({
  selectedValue,
  onSelectChange,
  label,
  placeholder,
  type,
  doNotUseSaveSelfButton,
  register,
  registerName,
  validationSchema,
  errors,
  icon,
  unitGal,
  id,
  max,
}: Props) => {
  const [inputHovered, setInputHovered] = useState<boolean>(false);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string | number>(
    selectedValue || '',
  );

  const handleBlurSave = () => {
    if (doNotUseSaveSelfButton) return;
    setInputFocused(false);
    if (inputValue !== selectedValue) {
      onSelectChange?.(inputValue);
    }
  };

  useEffect(() => {
    if (
      selectedValue !== null &&
      selectedValue !== undefined &&
      selectedValue !== inputValue
    )
      setInputValue(selectedValue as string | number);
  }, [selectedValue]);

  if (type === 'checkbox') {
    return (
      <div className="flex flex-row items-center gap-x-2 text-[14px]">
        <input
          checked={inputValue === 'true'}
          onChange={() =>
            onSelectChange &&
            onSelectChange(inputValue === 'true' ? 'false' : 'true')
          }
          // onClick={() => onSelectChange && onSelectChange(inputValue)}
          type="checkbox"
          name={(label || '') + (placeholder || '')}
          id={label?.replace(/\s/g, '') || ''}
          className="checkbox flex h-[18px] w-[18px] items-center justify-center rounded-md border  bg-white focus:ring-0 focus:ring-transparent focus:ring-offset-0"
        />
        <label htmlFor={(label || '') + (placeholder || '')}>{label}</label>
      </div>
    );
  }
  return (
    <div>
      {label && (
        <label htmlFor={label} className="my-2 block text-[14px] font-[600]">
          {label}
        </label>
      )}
      <div
        onMouseEnter={() => setInputHovered(true)}
        onMouseLeave={() => setInputHovered(false)}
        className={`relative flex w-full items-center ${
          inputFocused || doNotUseSaveSelfButton
            ? 'border-x-0 border-b-2 border-t-0 border-b-graydark'
            : ''
        } ${errors ? 'border-b-2 border-b-redish' : ''}  `}
      >
        {icon && <img src={icon} className="mr-2 h-[16px] w-[16px]" />}
        <div className="flex items-center justify-start">
          <input
            value={inputValue || ''}
            onChange={(e) => {
              if (type === 'number' && e.target.value.length > 8) {
                return;
              }
              if (
                max &&
                type === 'number' &&
                (Number(e.target.value) > max || Number(e.target.value) < 0)
              ) {
                return;
              }
              if (!doNotUseSaveSelfButton) {
                setInputValue(e.target.value);
              } else {
                setInputValue(e.target.value);
                if (onSelectChange) onSelectChange(e.target.value);
              }
            }}
            onFocus={() => setInputFocused(true)}
            onBlur={handleBlurSave}
            maxLength={type === 'number' ? 15 : 40}
            type={type}
            id={label}
            className={`block w-full border-none p-0 text-xs  placeholder:text-[#5A5A5A] focus:outline-none focus:ring-0`}
            placeholder={placeholder || label || ''}
            {...(register &&
              registerName &&
              register(registerName, validationSchema))}
          />
          {unitGal && <p className="text-xs">Gal</p>}
        </div>
        {errors && registerName && (
          <span className="absolute right-0 top-[-19px] text-[12px] font-bold text-redish">
            {errors.message}
          </span>
        )}

        {inputHovered && !inputFocused && !doNotUseSaveSelfButton && (
          <img src={editIcon} alt="edit icon" />
        )}
        {inputFocused && !doNotUseSaveSelfButton && (
          <button
            type="button"
            id={`${id}Save`}
            onClick={() => {
              if (!doNotUseSaveSelfButton) {
                if (onSelectChange) onSelectChange(inputValue);
                setInputFocused(false);
              } else {
                setInputFocused(false);
              }
            }}
          >
            <img src={saveIcon} alt="save icon" />
          </button>
        )}
      </div>
    </div>
  );
};

export default InputField;
