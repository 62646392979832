import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import licensing from '../../../assets/icons/licensing.svg';
import routeIcon from '../../../assets/icons/routeIcon.svg';
import truckIcon from '../../../assets/icons/trucks.svg';
import {
  LICENSING_NOTIFICATIONS,
  ROUTE_NOTIFICATIONS,
  TRUCK_NOTIFICATIONS,
} from '../../../config/constants';
import { SET_AS_READED } from '../../../services/graphql/notifications/notificationsMutation';

type NotificationProps = {
  title: string;
  message: string;
  date: string;
  id: string;
  readAt: string;
  readIt: () => void;
  handleClose: () => void;
  type: string;
  link: string;
};

function formatTimestamp(inputTimestamp: number) {
  // Create a new variable, adjusting if the timestamp is in seconds
  let timestamp = inputTimestamp;
  if (timestamp.toString().length === 10) {
    timestamp *= 1000;
  }

  const date = new Date(timestamp);

  // Check for invalid date
  if (Number.isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  const options = {
    year: 'numeric' as const,
    month: 'short' as const,
    day: 'numeric' as const,
    hour: '2-digit' as const,
    minute: '2-digit' as const,
    hour12: true,
  };

  let formattedDate = date.toLocaleString('en-US', options);

  formattedDate = formattedDate.replace(/,([^,]*)$/, ' ·$1');

  return formattedDate;
}

const Notification = ({
  title,
  message,
  date,
  id,
  readAt,
  readIt,
  handleClose,
  link,
  type,
}: NotificationProps) => {
  const navigate = useNavigate();
  const [readed, setReaded] = useState(readAt !== null);
  const [setAsReaded] = useMutation<any>(SET_AS_READED, {
    variables: {
      notificationId: Number(id),
    },
    onCompleted: () => {
      setReaded(true);
    },
  });

  const onClick = () => {
    if (readed) return;
    setAsReaded();
    readIt();
  };
  useEffect(() => {
    setReaded(readAt !== null);
  }, [readAt]);

  const onClickAnchor = () => {
    if (!readAt) setAsReaded();
    if (link) {
      navigate(link);
      handleClose();
    }
  };

  const icon = () => {
    if (LICENSING_NOTIFICATIONS.includes(type))
      return (
        <img
          className="img-color w-[15px] object-contain"
          src={licensing}
          alt="notification"
        />
      );
    if (ROUTE_NOTIFICATIONS.includes(type))
      return (
        <img
          className="img-color w-[15px] object-contain"
          src={routeIcon}
          alt="notification"
        />
      );
    if (TRUCK_NOTIFICATIONS.includes(type))
      return (
        <img
          className="img-color w-[15px] object-contain"
          src={truckIcon}
          alt="notification"
        />
      );
    return (
      <img
        className="img-color w-[15px] object-contain"
        src={truckIcon}
        alt="notification"
      />
    );
  };

  return (
    <a
      onClick={onClickAnchor}
      className={`flex cursor-pointer flex-row gap-3 border-b-[1px] border-greenextralight px-1 py-2 hover:bg-greenextralight`}
    >
      <div className="mt-1 flex h-[21px] items-center justify-center rounded-full bg-greenextralight px-[4px] py-[6px]">
        {icon()}
      </div>
      <div className="mb-2 flex w-full flex-col gap-1">
        <p className="font-inter text-xs font-bold text-black">
          {title || 'Route Confirmed'}
        </p>
        <div className="flex flex-row items-center justify-between">
          <p className="font-inter text-xs text-black">
            {message ||
              'John Doe has confirmed the scheduled route OIL132312 for 07-02-2024'}
          </p>
          <div
            onClick={onClick}
            className={`mr-2 flex ${!readed ? 'visible' : 'invisible'} ${
              readed ? 'cursor-default' : 'cursor-pointer'
            } z-[5] h-[11px] w-[11px] rounded-full bg-[#C5950B] p-[5px]`}
          ></div>
        </div>
        <span className="font-inter text-[12px] text-blacker ">
          {formatTimestamp(Number(date))}
        </span>
      </div>
    </a>
  );
};

export default Notification;
