import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  cashDisbursedID,
  equipmentSelectedToEdit,
  incidentSelectedToEdit,
  personSelectedToEdit,
  truckDriversToEdit,
  truckServiceToEdit,
} from '../recoil/dataToEdit';
import { isClientDocumentsFilterModalOpenAtom } from '../recoil/modalClientDocuments';
import { isClientsFilterModalOpenAtom } from '../recoil/modalClientsFilter';
import { isRightSideModalOpenState } from '../recoil/modalContainer';
import { isWhiteModalContainerOpenState } from '../recoil/modalContainerWhite';
import { isEditReceiptModalOpenAtom } from '../recoil/modalEditReceipt';
import { isEditRouteFilterModalOpenAtom } from '../recoil/modalEditRouteFilter';
import { isInvoicesFilterModalOpenAtom } from '../recoil/modalInvoicesFilter';
import { isNewCashDisbursedModalOpenAtom } from '../recoil/modalNewCashDisbursed';
import { isNewClientModalOpenAtom } from '../recoil/modalNewClient';
import { isNewEquipmentModalOpenAtom } from '../recoil/modalNewEquipment';
import { isNewIncidentModalOpenAtom } from '../recoil/modalNewIncident';
import { isNewPersonModalOpenAtom } from '../recoil/modalNewPerson';
import { isNewReceiptModalOpenAtom } from '../recoil/modalNewReceipt';
import { isNewRouteModalOpenAtom } from '../recoil/modalNewRoute';
import { isNewServiceModalOpenAtom } from '../recoil/modalNewService';
import { isNewTruckModalOpenAtom } from '../recoil/modalNewTruck';
import { isNewWithDrawModalOpenAtom } from '../recoil/modalNewWithDraw';
import { isNewWorkerModalOpenAtom } from '../recoil/modalNewWorker';
import { isPaymentFilterModalOpenAtom } from '../recoil/modalPaymentFilter';
import { isRoutesFilterModalOpenAtom } from '../recoil/modalRoutesFilter';
import { isTruckDriversModalOpenAtom } from '../recoil/modalTruckDrivers';
import { isTruckServiceModalOpenAtom } from '../recoil/modalTruckService';
import { isTrucksFilterModalOpenAtom } from '../recoil/modalTrucksFilter';
import { isWorkersFilterModalOpenAtom } from '../recoil/modalWorkersFilter';

export const useModal = () => {
  // ==== States ====

  const [isWhiteModalContainerOpen, setIsWhiteModalContainerOpen] =
    useRecoilState(isWhiteModalContainerOpenState);

  const [isRightSideModalOpen, setIsRightSideModalOpen] = useRecoilState(
    isRightSideModalOpenState,
  );

  const [isNewClientModalOpen, setIsNewClientModalStateOpen] = useRecoilState(
    isNewClientModalOpenAtom,
  );

  const [isNewEquipmentModalOpen, setIsNewEquipmentModalOpen] = useRecoilState(
    isNewEquipmentModalOpenAtom,
  );

  const [isClientsFilterModalOpen, setIsClientsFilterModalOpen] =
    useRecoilState(isClientsFilterModalOpenAtom);

  const [isNewPersonModalOpen, setIsNewPersonModalOpen] = useRecoilState(
    isNewPersonModalOpenAtom,
  );

  const [isNewServiceModalOpen, setIsNewServiceModalOpen] = useRecoilState(
    isNewServiceModalOpenAtom,
  );

  const [isNewRouteModalOpen, setIsNewRouteModalStateOpen] = useRecoilState(
    isNewRouteModalOpenAtom,
  );

  const [isRoutesFilterModalOpen, setIsRoutesFilterModalOpen] = useRecoilState(
    isRoutesFilterModalOpenAtom,
  );

  const [
    isModalFilterClientDocumentsOpen,
    setIsModalFilterClientDocumentsOpen,
  ] = useRecoilState(isClientDocumentsFilterModalOpenAtom);

  const [isInvoicesFilterModalOpen, setIsInvoicesFilterModalOpen] =
    useRecoilState(isInvoicesFilterModalOpenAtom);

  const [isTrucksFilterModalOpen, setIsTrucksFilterModalOpen] = useRecoilState(
    isTrucksFilterModalOpenAtom,
  );

  const [isNewTruckModalOpen, setIsNewTruckModalOpen] = useRecoilState(
    isNewTruckModalOpenAtom,
  );

  const [isWorkersFilterModalOpen, setIsWorkersFilterModalOpen] =
    useRecoilState(isWorkersFilterModalOpenAtom);

  const [isNewWorkerModalOpen, setIsNewWorkerModalOpen] = useRecoilState(
    isNewWorkerModalOpenAtom,
  );

  const [isNewReceiptModalOpen, setIsNewReceiptModalOpen] = useRecoilState(
    isNewReceiptModalOpenAtom,
  );

  const [isEditReceiptModalOpen, setIsEditReceiptModalOpen] = useRecoilState(
    isEditReceiptModalOpenAtom,
  );

  const [isEditRouteFilterModalOpen, setIsEditRouteFilterModalOpen] =
    useRecoilState(isEditRouteFilterModalOpenAtom);

  const [isTruckServiceModalOpen, setIsTruckServiceModalOpen] = useRecoilState(
    isTruckServiceModalOpenAtom,
  );

  const [isTruckDriversModalOpen, setIsTruckDriversModalOpen] = useRecoilState(
    isTruckDriversModalOpenAtom,
  );

  const [isNewIncidentModalOpen, setIsNewIncidentModalOpen] = useRecoilState(
    isNewIncidentModalOpenAtom,
  );
  const [isNewWithDrawModalOpen, setIsNewWithDrawModalOpen] = useRecoilState(
    isNewWithDrawModalOpenAtom,
  );
  const [isNewCashDisbursedModalOpen, setIsNewCashDisbursedModalOpen] =
    useRecoilState(isNewCashDisbursedModalOpenAtom);

  const [isPaymentFilterModalOpen, setIsPaymentFilterModalOpen] =
    useRecoilState(isPaymentFilterModalOpenAtom);

  const setPersonToEdit = useSetRecoilState(personSelectedToEdit);
  const setTruckServiceToEdit = useSetRecoilState(truckServiceToEdit);
  const setTruckDriversToEdit = useSetRecoilState(truckDriversToEdit);
  const setIncidentToEdit = useSetRecoilState(incidentSelectedToEdit);
  const setEquipmentToEdit = useSetRecoilState(equipmentSelectedToEdit);
  const setCashDisburseId = useSetRecoilState(cashDisbursedID);

  // ===== Functions  =====

  const openWhiteModalWithDelay = () => {
    setTimeout(() => {
      setIsWhiteModalContainerOpen(true);
    }, 50);
  };

  const handleOpenNewPersonModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isNewPersonModalOpen) {
      setIsNewPersonModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenNewServiceModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isNewServiceModalOpen) {
      setIsNewServiceModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenNewClientModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isNewClientModalOpen) {
      setIsNewClientModalStateOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenNewEquipmentModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isNewClientModalOpen) {
      setIsNewEquipmentModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenClientsFilterModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isClientsFilterModalOpen) {
      setIsClientsFilterModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenRoutesFilterModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isRoutesFilterModalOpen) {
      setIsRoutesFilterModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenNewRouteModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isNewClientModalOpen) {
      setIsNewRouteModalStateOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenClientDocumentsModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isModalFilterClientDocumentsOpen) {
      setIsModalFilterClientDocumentsOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenInvoicesFilterModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isInvoicesFilterModalOpen) {
      setIsInvoicesFilterModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenPaymentFilterModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isInvoicesFilterModalOpen) {
      setIsPaymentFilterModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenTrucksFilterModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isTrucksFilterModalOpen) {
      setIsTrucksFilterModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenNewTruckModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isNewTruckModalOpen) {
      setIsNewTruckModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenWorkersFilterModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isWorkersFilterModalOpen) {
      setIsWorkersFilterModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenNewWorkerModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isNewWorkerModalOpen) {
      setIsNewWorkerModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenNewReceiptModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (isEditReceiptModalOpen) {
      setIsEditReceiptModalOpen(false);
    }
    if (!isNewReceiptModalOpen) {
      setIsNewReceiptModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenEditReceiptModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (isNewReceiptModalOpen) {
      setIsNewReceiptModalOpen(false);
    }
    if (!isEditReceiptModalOpen) {
      setIsEditReceiptModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenEditRouteModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isEditRouteFilterModalOpen) {
      setIsEditRouteFilterModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenTruckServiceModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isTruckServiceModalOpen) {
      setIsTruckServiceModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenTruckDriversModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isTruckDriversModalOpen) {
      setIsTruckDriversModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenNewIncidentModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isNewIncidentModalOpen) {
      setIsNewIncidentModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenNewWithDrawModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isNewIncidentModalOpen) {
      setIsNewWithDrawModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleOpenNewCashDisbursedModal = () => {
    if (!isRightSideModalOpen) {
      setIsRightSideModalOpen(true);
    }
    if (!isNewIncidentModalOpen) {
      setIsNewCashDisbursedModalOpen(true);
    }

    openWhiteModalWithDelay();
  };

  const handleCloseAllModals = () => {
    // Close white container first
    setIsWhiteModalContainerOpen(false);

    // Close dark modal area with delay
    setTimeout(() => {
      // Close all content modals. Important to add here all created modals

      if (isNewClientModalOpen) {
        setIsNewClientModalStateOpen(false);
      }
      if (isClientsFilterModalOpen) {
        setIsClientsFilterModalOpen(false);
      }
      if (isNewPersonModalOpen) {
        setIsNewPersonModalOpen(false);
      }
      if (isNewServiceModalOpen) {
        setIsNewServiceModalOpen(false);
      }
      // routes
      if (isNewRouteModalOpen) {
        setIsNewRouteModalStateOpen(false);
      }
      if (isRoutesFilterModalOpen) {
        setIsRoutesFilterModalOpen(false);
      }
      if (isModalFilterClientDocumentsOpen) {
        setIsModalFilterClientDocumentsOpen(false);
      }
      if (isInvoicesFilterModalOpen) {
        setIsInvoicesFilterModalOpen(false);
      }
      if (isTrucksFilterModalOpen) {
        setIsTrucksFilterModalOpen(false);
      }
      if (isNewTruckModalOpen) {
        setIsNewTruckModalOpen(false);
      }
      if (isWorkersFilterModalOpen) {
        setIsWorkersFilterModalOpen(false);
      }
      if (isNewWorkerModalOpen) {
        setIsNewWorkerModalOpen(false);
      }
      if (isNewReceiptModalOpen) {
        setIsNewReceiptModalOpen(false);
      }
      if (isEditReceiptModalOpen) {
        setIsEditReceiptModalOpen(false);
      }
      if (isEditRouteFilterModalOpen) {
        setIsEditRouteFilterModalOpen(false);
      }
      if (isTruckServiceModalOpen) {
        setIsTruckServiceModalOpen(false);
      }
      if (isTruckDriversModalOpen) {
        setIsTruckDriversModalOpen(false);
      }
      if (isNewIncidentModalOpen) {
        setIsNewIncidentModalOpen(false);
      }
      if (isNewEquipmentModalOpen) {
        setIsNewEquipmentModalOpen(false);
      }
      if (isNewWithDrawModalOpen) {
        setIsNewWithDrawModalOpen(false);
      }
      if (isNewCashDisbursedModalOpen) {
        setIsNewCashDisbursedModalOpen(false);
      }
      if (isPaymentFilterModalOpen) {
        setIsPaymentFilterModalOpen(false);
      }

      // Empty stored data to edit
      setPersonToEdit(null);

      setTruckServiceToEdit(null);
      setTruckDriversToEdit(null);
      setIncidentToEdit(null);
      setEquipmentToEdit(null);

      setCashDisburseId(null);

      setIsRightSideModalOpen(false);
    }, 400);
  };

  return {
    handleOpenNewWorkerModal,
    handleOpenWorkersFilterModal,
    handleOpenNewTruckModal,
    handleOpenTrucksFilterModal,
    handleOpenInvoicesFilterModal,
    handleOpenClientDocumentsModal,
    handleOpenNewClientModal,
    handleOpenClientsFilterModal,
    handleOpenNewPersonModal,
    handleOpenNewServiceModal,
    handleCloseAllModals,
    handleOpenRoutesFilterModal,
    handleOpenNewRouteModal,
    handleOpenNewReceiptModal,
    handleOpenEditReceiptModal,
    handleOpenPaymentFilterModal,
    isWhiteModalContainerOpen,
    handleOpenEditRouteModal,
    handleOpenTruckServiceModal,
    handleOpenTruckDriversModal,
    handleOpenNewIncidentModal,
    handleOpenNewEquipmentModal,
    handleOpenNewWithDrawModal,
    handleOpenNewCashDisbursedModal,
  };
};
