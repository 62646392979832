import type { ReactNode } from 'react';
import { forwardRef } from 'react';

interface Props {
  children: ReactNode;
}

const SentryInfiniteScroll = forwardRef<HTMLDivElement, Props>(
  ({ children }, ref) => {
    return <div ref={ref}>{children}</div>;
  },
);

export default SentryInfiniteScroll;
