import { gql } from '@apollo/client';

export const CREATE_EQUIPMENT = gql`
  mutation CreateEquipment($name: String!, $quantity: Int!, $cost: Float!) {
    createEquipment(name: $name, quantity: $quantity, cost: $cost) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_EQUIPMENT = gql`
  mutation DeleteEquipment($id: String!) {
    deleteEquipment(id: $id) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_EQUIPMENT = gql`
  mutation UpdateEquipment(
    $id: String!
    $name: String!
    $quantity: Int!
    $cost: Float!
  ) {
    updateEquipment(id: $id, name: $name, quantity: $quantity, cost: $cost) {
      status
      code
      data
      error
    }
  }
`;
