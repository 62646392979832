import { useSetRecoilState } from 'recoil';

import iconPhone from '../../../../assets/icons/call.svg';
import iconMail from '../../../../assets/icons/email.svg';
import iconWorker from '../../../../assets/icons/workers.svg';
import { useModal } from '../../../../hooks/useModal';
import { personSelectedToEdit } from '../../../../recoil/dataToEdit';
import type { ClientPeople } from '../../../../types/client';

type Props = {
  person: ClientPeople;
};

const ClientPeopleCard = ({ person }: Props) => {
  const setPersonSelectedId = useSetRecoilState(personSelectedToEdit);
  const { handleOpenNewPersonModal } = useModal();

  const handleOpenModalEditPeople = (id: string) => () => {
    setPersonSelectedId(id);
    handleOpenNewPersonModal();
  };

  return (
    <button
      onClick={handleOpenModalEditPeople(person.id)}
      id={person.full_name.replace(/\s/g, '')}
      className="box-shadow flex h-[122px] w-[250px]  flex-col items-center gap-[0.2rem] rounded-lg px-6 py-4"
    >
      <p className="text-xs font-semibold ">{person.full_name}</p>
      <div className="flex items-center justify-center gap-2">
        <img className="h-[16px]" src={iconWorker} alt="icon worker" />
        <p className="text-xs">{person.role}</p>
      </div>
      <div className="flex items-center justify-center gap-2">
        <img className="h-[16px]" src={iconMail} alt="icon email" />
        <p className="text-xs">{person.email}</p>
      </div>
      <div className="flex items-center justify-center gap-2">
        <img className="h-[16px]" src={iconPhone} alt="icon phone" />
        <p className="text-xs">{person.phone}</p>
      </div>
    </button>
  );
};

export default ClientPeopleCard;
