import { useState } from 'react';
import Select from 'react-select';

import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import { statusWorkerOptions } from '../../../config/constants';
import {
  colourStyles,
  CustomOption,
  CustomSingleValue,
} from '../../../styles/statusDropdownStyle';

type Props = {
  initialStatusValue: string;
  mutation: (arg: any) => void;
  userId: string;
};

const WorkerStatusDropdown = ({
  initialStatusValue,
  mutation,
  userId,
}: Props) => {
  const [status, setStatus] = useState<string>(initialStatusValue);

  const handleChangeStatus = (selectedOption: any) => {
    const newStatus = selectedOption.value;
    setStatus(newStatus);

    mutation({
      variables: {
        key: 'status',
        value: selectedOption.value,
        userid: userId,
      },
    });
  };

  return (
    <>
      <div className="absolute flex text-xs font-medium">
        <Select
          styles={colourStyles(status, statusWorkerOptions)}
          components={{
            DropdownIndicator: () => <ArrowDownIcon />,
            IndicatorSeparator: () => null,
            Option: CustomOption,
            SingleValue: CustomSingleValue,
          }}
          options={statusWorkerOptions}
          onChange={handleChangeStatus}
          id="WorkerStatusDropdown"
          value={statusWorkerOptions.find((option) => option.value === status)}
          isSearchable={false}
        />
      </div>
    </>
  );
};

export default WorkerStatusDropdown;
