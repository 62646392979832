import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

import Loader from '../components/ui/Loader';

const RequireAuth = ({ children }: any) => {
  const { isAuthenticated, isLoading, user } = useAuth0();

  if (isLoading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Loader />
      </div>
    );
  }
  if (!isAuthenticated && !isLoading) {
    return (
      <Navigate
        to={{
          pathname: '/',
        }}
        replace={true}
      />
    );
  }
  if (
    isAuthenticated &&
    !user?.user_roles.includes('Manager') &&
    !user?.user_roles.includes('Supervisor') &&
    !user?.user_roles.includes('Superadmin')
  ) {
    return (
      <Navigate
        to={{
          pathname: '/',
        }}
        replace={true}
      />
    );
  }

  return children;
};

export default RequireAuth;
