import { gql } from '@apollo/client';

export const DOCUMENT_CLIENT_UPLOAD = gql`
  mutation UploadFile(
    $file: Upload!
    $clientId: String!
    $type: String!
    $stopId: String
  ) {
    uploadFile(file: $file, clientId: $clientId, type: $type, stopId: $stopId) {
      status
      code
      data
      error
    }
  }
`;

export const DOCUMENT_DELETE = gql`
  mutation DeleteDocument($id: [String]) {
    deleteDocument(id: $id) {
      status
      code
      data
      error
    }
  }
`;
