import { gql } from '@apollo/client';

export const GET_CLIENTS = gql`
  query GetClients(
    $limit: Int
    $offset: Int
    $statusFilter: [String]
    $zoneFilter: [String]
    $readyToVisitFilter: Boolean
    $availableDays: [String]
    $deletedFilter: Boolean
    $lastServiceStartDateFilter: String
    $lastServiceEndDateFilter: String
    $nextServiceStartDateFilter: String
    $nextServiceEndDateFilter: String
    $clientNameFilter: String
    $orderBy: String
    $orderDirection: String
  ) {
    getClients(
      limit: $limit
      offset: $offset
      statusFilter: $statusFilter
      zoneFilter: $zoneFilter
      readyToVisitFilter: $readyToVisitFilter
      availableDays: $availableDays
      deletedFilter: $deletedFilter
      lastServiceStartDateFilter: $lastServiceStartDateFilter
      lastServiceEndDateFilter: $lastServiceEndDateFilter
      nextServiceStartDateFilter: $nextServiceStartDateFilter
      nextServiceEndDateFilter: $nextServiceEndDateFilter
      clientNameFilter: $clientNameFilter
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      count
      data {
        client {
          id
          name
          status
          address
          monday_opening
          monday_closing
          tuesday_opening
          tuesday_closing
          wednesday_opening
          wednesday_closing
          thursday_opening
          thursday_closing
          friday_opening
          friday_closing
          saturday_opening
          saturday_closing
          sunday_opening
          sunday_closing
          phone
          email
          createdAt
          updatedAt
          lastVisit
        }
        clientService {
          id
          client_id
          name
          frequency
          desired_day
          last_service
          available_days
          next_scheduled
          container_size
          container_location
          barrel_size
          barrel_number
          paid_amount
          route_zone
          ready_to_visit
          createdAt
          updatedAt
          client_name
          client_address
          client_phone
        }
      }
      error
    }
  }
`;

export const GET_CLIENTS_TABLE = gql`
  query GetClients(
    $limit: Int
    $offset: Int
    $statusFilter: [String]
    $zoneFilter: [String]
    $readyToVisitFilter: Boolean
    $availableDays: [String]
    $deletedFilter: Boolean
    $lastServiceStartDateFilter: String
    $lastServiceEndDateFilter: String
    $nextServiceStartDateFilter: String
    $nextServiceEndDateFilter: String
    $clientNameFilter: String
    $orderBy: String
    $orderDirection: String
  ) {
    getClients(
      limit: $limit
      offset: $offset
      statusFilter: $statusFilter
      zoneFilter: $zoneFilter
      readyToVisitFilter: $readyToVisitFilter
      availableDays: $availableDays
      deletedFilter: $deletedFilter
      lastServiceStartDateFilter: $lastServiceStartDateFilter
      lastServiceEndDateFilter: $lastServiceEndDateFilter
      nextServiceStartDateFilter: $nextServiceStartDateFilter
      nextServiceEndDateFilter: $nextServiceEndDateFilter
      clientNameFilter: $clientNameFilter
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      count
      data {
        client {
          id
          name
          status
          lastVisit
        }
        clientService {
          name
        }
      }
      error
    }
  }
`;

export const GET_CLIENT_BY_ID = gql`
  query GetClientById($clientId: String!) {
    getClientById(clientId: $clientId) {
      status
      code
      data {
        clientDocuments {
          id
          client_id
          name
          stop_id
          type
          url
          date
          createdAt
          updatedAt
          deletedAt
        }
        clientPeople {
          id
          full_name
          role
          client_id
          phone
          email
          createdAt
          updatedAt
        }
        clientService {
          id
          client_id
          name
          frequency
          desired_day
          last_service
          available_days
          next_scheduled
          container_size
          container_location
          barrel_size
          barrel_number
          paid_amount
          route_zone
          ready_to_visit
          createdAt
          updatedAt
          client_name
          paid_client
        }
        client {
          id
          name
          status
          address
          monday_opening
          monday_closing
          tuesday_opening
          tuesday_closing
          wednesday_opening
          wednesday_closing
          thursday_opening
          thursday_closing
          friday_opening
          friday_closing
          saturday_opening
          saturday_closing
          sunday_opening
          sunday_closing
          phone
          email
          createdAt
          updatedAt
        }
      }
      error
    }
  }
`;

export const GET_CLIENT_TIMELINE = gql`
  query GetClientTimeLine(
    $clientId: String!
    $type: [String]
    $limit: Int
    $offset: Int
  ) {
    getClientTimeLine(
      clientId: $clientId
      type: $type
      limit: $limit
      offset: $offset
    ) {
      status
      code
      count
      error
      data {
        timeline_title
        timeline_description
        client_id
        createdAt
        updatedAt
        type
        user_name
        new_info
        document_deleted
      }
    }
  }
`;
