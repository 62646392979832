import LoaderSmallLightGreen from '../LoaderSmallLightGreen';

interface Props {
  text: string;
  icon?: any;
  variant: 'green' | 'yellow' | 'white';
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  id?: string;
}

export const Button = ({
  text,
  onClick,
  variant,
  icon,
  type,
  loading,
  disabled,
  id,
}: Props) => {
  if (variant === 'green')
    return (
      <button
        type={type}
        onClick={() => onClick && onClick()}
        disabled={loading || disabled}
        id={id}
        className="button-icon-reverse flex h-[41px] w-[84px] items-center justify-center gap-2 rounded-md !bg-green p-2 text-xs text-white  hover:!bg-gold hover:text-green"
      >
        {loading ? (
          <LoaderSmallLightGreen />
        ) : (
          <>
            {icon && <img src={icon} alt={text} />}
            <p>{text}</p>
          </>
        )}
      </button>
    );
  return (
    <button
      type={type}
      onClick={() => onClick && onClick()}
      disabled={loading || disabled}
      id={id}
      className="button-icon-reverse flex h-[41px] w-[84px] items-center justify-center gap-2 rounded-md !bg-green p-2 text-xs text-white  hover:!bg-gold hover:text-green"
    >
      {loading ? (
        <LoaderSmallLightGreen />
      ) : (
        <>
          <img src={icon} alt="save icon" />
          <p>{text}</p>
        </>
      )}
    </button>
  );
};
