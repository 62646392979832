import { ReactComponent as VectorIcon } from '../../../assets/icons/Vector.svg';

type Props = {
  title: string;
  secondTitle?: string;
  thirdTitle?: string;
  image: string;
  id?: string;
};

const Breadcrumb = ({ title, image, secondTitle, thirdTitle, id }: Props) => {
  if (thirdTitle) {
    return (
      <div
        className="flex h-[57px] w-full items-center gap-2 text-base font-semibold"
        id={id}
      >
        <img src={image} className="h-[16px] w-[16px]" alt="clients icon" />
        <h1>{title}</h1>
        <VectorIcon />

        <h1>{secondTitle}</h1>
        <VectorIcon />

        <h1>{thirdTitle}</h1>
      </div>
    );
  }
  if (secondTitle) {
    return (
      <div
        className="flex h-[57px] w-full items-center gap-2 text-base font-semibold"
        id={id}
      >
        <img src={image} className="h-[16px] w-[16px]" alt="clients icon" />
        <h1>{title}</h1>
        <VectorIcon />

        <h1>{secondTitle}</h1>
      </div>
    );
  }
  return (
    <div
      className="flex h-[57px] w-full items-center gap-2 text-base font-semibold"
      id={id}
    >
      <img src={image} className="h-[16px] w-[16px]" alt="clients icon" />
      <h1>{title}</h1>
    </div>
  );
};

export default Breadcrumb;
