import { useMutation, useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import type {
  DocumentToShow,
  GetDocumentByTruckIdQuery,
} from '@/types/document';

import { TRUCK_DOCUMENTS_TABLE_MAX_RESULTS } from '../../../config/constants';
import { useNotification } from '../../../hooks/useNotification';
import { needRefreshState } from '../../../recoil/needRefresh';
import { GET_DOCUMENTS_BY_TRUCK_ID } from '../../../services/graphql/document/document.queries';
import { DOCUMENT_DELETE } from '../../../services/graphql/document/document-mutations';
import type { ClientsDocumentTable } from '../../../types/tables';
import { convertToAmericanDateTimeFormat } from '../../../utils/convertTimestamp';
import DataTable from '../common/DataTable/DataTable';
import DTBoldCell from '../common/DataTable/DTBoldCell';
import DTHeader from '../common/DataTable/DTHeader';
import DocumentActions from '../common/DocumentActions';

interface Props {
  truckId: string;
}

const DTDocuments = ({ truckId }: Props) => {
  const { t } = useTranslation('common');
  const { notifyDeleteError, notifyDeleteOk } = useNotification();
  // States
  const [filters, setFilters] = useState({
    orderBy: 'date',
    orderDirection: 'DESC',
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: TRUCK_DOCUMENTS_TABLE_MAX_RESULTS,
  });
  const [data, setData] = useState<DocumentToShow[]>([]);
  const [total, setTotal] = useState<number>(0);
  const needRefresh = useRecoilValue(needRefreshState);

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  const {
    data: fetchData,
    loading,
    error,
    refetch: refetchDocuments,
  } = useQuery<GetDocumentByTruckIdQuery>(GET_DOCUMENTS_BY_TRUCK_ID, {
    variables: {
      truckId,
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'date',
      orderDirection: filters?.orderDirection || 'DESC',
    },
    skip: !truckId,
  });

  useEffect(() => {
    if (
      fetchData &&
      fetchData.getDocumentsByTruckId &&
      fetchData.getDocumentsByTruckId.data
    ) {
      const updatedData: DocumentToShow[] =
        fetchData.getDocumentsByTruckId.data.map((document) => ({
          id: document.id,
          name: document.name || '',
          type: document.type || '',
          date: document.date || '',
          url: document.url || '',
        }));

      setData(updatedData);
      setTotal(fetchData.getDocumentsByTruckId.count);
    }
  }, [fetchData]);

  // Mutations
  const [deleteDocument] = useMutation(DOCUMENT_DELETE, {
    onError: () => {
      notifyDeleteOk();
    },
    onCompleted: () => {
      notifyDeleteError();
      refetchDocuments({
        clientId: [truckId],
        limit: pageSize,
        offset: pageIndex * pageSize,
        orderBy: filters?.orderBy || 'date',
        orderDirection: filters?.orderDirection || 'DESC',
      });
    },
  });

  const columnHelper = createColumnHelper<ClientsDocumentTable>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        size: 200,
        minSize: 200,
        maxSize: 250,
        cell: (info) => (
          <DTBoldCell content={info.getValue()} id={info.cell.id} />
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-documents.th-document-name')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="name"
          />
        ),
      }),
      columnHelper.accessor('type', {
        size: 100,
        minSize: 100,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-documents.th-document-type')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="type"
          />
        ),
      }),
      columnHelper.accessor('date', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('client-documents.th-date')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="date"
          />
        ),
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() !== null
              ? convertToAmericanDateTimeFormat(info.getValue() as string)
              : ''}
          </span>
        ),
      }),
      columnHelper.accessor('url', {
        size: 100,
        minSize: 100,
        maxSize: 150,
        cell: (info) => (
          <DocumentActions
            documentId={info.row.original.id}
            documentName={info.row.original.name}
            url={info.getValue()}
            deleteDocument={deleteDocument}
          />
        ),
        header: () => (
          <DTHeader title={t('client-documents.th-document')} accessor="url" />
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  // EFFECTS
  useEffect(() => {
    if (needRefresh === 'documents') refetchDocuments();
  }, [needRefresh]);

  return (
    <>
      <div className="min-h-[200px] overflow-auto">
        <DataTable
          error={error}
          handleRowClick={() => {}}
          loading={loading}
          table={table}
          total={total}
          tableId="TruckDocumentsTable"
          sorting={{ ...filters }}
          loadingRows={pageSize}
        />
      </div>
    </>
  );
};

export default DTDocuments;
