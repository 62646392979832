import { gql } from '@apollo/client';

export const UPDATE_SETTING = gql`
  mutation UpdateSetting($id: String!, $value: String!) {
    updateSetting(id: $id, value: $value) {
      status
      code
      data
      error
    }
  }
`;
