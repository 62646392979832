import 'react-datepicker/dist/react-datepicker.css';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import workerIcon from '../../../assets/icons/personalcard.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { statusWorkerOptions, USER_ROLES } from '../../../config/constants';
import { useModal } from '../../../hooks/useModal';
import { needRefreshState } from '../../../recoil/needRefresh';
import { workerFiltersState } from '../../../recoil/workersFilter';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import Dropdown from '../../ui/common/Dropdown';
import DropdownMultiple from '../../ui/common/DropdownMultiple';

const FilterWorker = () => {
  const { t } = useTranslation('common');

  // Form title
  const breadcrumbTitle = `${t('modalFilterWorkers.workers')}`;
  const breadcrumbSecondTitle = t('modalFilterWorkers.filters');

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  // Filters
  const [filters, setFilters] = useRecoilState(workerFiltersState);

  const [status, setStatus] = useState<string[] | null>(null);
  const [role, setRole] = useState<string | null>(null);

  const handleFiltersSave = () => {
    let newFilters: Record<string, any> = {};

    newFilters = { ...filters };

    if (status) {
      newFilters.filterStatus = status;
    } else {
      newFilters.filterStatus = null;
    }

    if (role) {
      newFilters.filterRole = role;
    } else {
      newFilters.filterRole = null;
    }
    if (Object.keys(newFilters).length > 0) {
      setFilters(newFilters);
    } else {
      setFilters(null);
    }
    closeOrder();
  };

  const handleStatusChange = (selected: string[]) => {
    if (selected.length > 0) {
      setStatus(selected);
    } else {
      setStatus(null);
    }
  };

  const handleRoleChange = (selected: string) => {
    if (selected) {
      setRole(selected);
    } else {
      setRole(null);
    }
  };

  useEffect(() => {
    if (filters) {
      if (filters.filterStatus) {
        setStatus(filters.filterStatus);
      }
      if (filters.filterRole) {
        setRole(filters.filterRole);
      }
    }
  }, [filters]);

  return (
    <>
      <Breadcrumb
        title={breadcrumbTitle}
        secondTitle={breadcrumbSecondTitle}
        image={workerIcon}
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalFilterWorkers.filters')}
      </h1>
      <div className="my-6 flex flex-col gap-2">
        {/* Status Dropdown */}
        <div className="h-[60px]">
          <DropdownMultiple
            options={statusWorkerOptions}
            onChange={handleStatusChange}
            label={t('modalFilterWorkers.status')}
            selectedValues={status || []}
          />
        </div>
        {/* Role Dropdown */}
        <div className="h-[60px]">
          <Dropdown
            options={USER_ROLES}
            label={t('modalFilterWorkers.role')}
            selectedValue={role || ''}
            onSelectChange={handleRoleChange}
          />
        </div>

        <div className="mt-6 flex justify-end">
          <Button
            text={t('modalTrucksFilter.save')}
            variant="green"
            icon={saveBigIcon}
            onClick={handleFiltersSave}
            type="button"
            id="save-worker-filters"
          />
        </div>
      </div>
    </>
  );
};

export default FilterWorker;
