import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import type { DriversQuery } from '@/types/user';

import saveBigIcon from '../../../assets/icons/save.svg';
import truckIcon from '../../../assets/icons/truck.svg';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { truckDriversToEdit } from '../../../recoil/dataToEdit';
import { needRefreshState } from '../../../recoil/needRefresh';
import { GET_ALL_DRIVERS } from '../../../services/graphql/drivers/drivers-query';
import { UPDATE_TRUCK_DRIVERS } from '../../../services/graphql/trucks/trucks-mutations';
import Breadcrumb from '../../ui/common/Breadcrumb';
import InputField from '../../ui/common/InputField';
import LoaderSmall from '../../ui/LoaderSmall';
// import LoaderSmall from '../../ui/LoaderSmall';

const TruckDrivers = () => {
  const { t } = useTranslation('common');
  const { notifySaveSuccess, notifySaveError } = useNotification();

  const [truckDrivers, setTruckDrivers] = useRecoilState(truckDriversToEdit);

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh('drivers');
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  // Querys
  const { data } = useQuery<DriversQuery>(GET_ALL_DRIVERS, {});

  // Mutations
  const [updateTruck, { loading: updateTruckLoading }] = useMutation<any>(
    UPDATE_TRUCK_DRIVERS,
    {
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
      },
      onError: () => {
        notifySaveError();
      },
    },
  );

  // On Submit
  const onSubmit = (e: any) => {
    e.preventDefault();
    updateTruck({
      variables: {
        truckId: truckDrivers?.id,
        drivers: truckDrivers?.truckDrivers,
      },
    });
  };
  return (
    <>
      <Breadcrumb
        title={t('modalAssociateTruckDrivers.breadcrumps-1')}
        secondTitle={truckDrivers?.license}
        thirdTitle={t('modalAssociateTruckDrivers.breadcrumps-3')}
        image={truckIcon}
        id="modalService"
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalAssociateTruckDrivers.title')}
      </h1>
      <form className="my-6 flex flex-col gap-y-4">
        {data?.getDrivers.data.map((d) => (
          <div key={d.id} className="w-full">
            <InputField
              type="checkbox"
              label={d.name}
              placeholder={d.id}
              selectedValue={
                truckDrivers?.truckDrivers.includes(d.id) ? 'true' : 'false'
              }
              onSelectChange={(val) => {
                if (val === 'true') {
                  setTruckDrivers({
                    ...truckDrivers!,
                    truckDrivers: [...truckDrivers!.truckDrivers, d.id],
                  });
                } else {
                  setTruckDrivers({
                    ...truckDrivers!,
                    truckDrivers: [...truckDrivers!.truckDrivers].filter(
                      (td) => td !== d.id,
                    ),
                  });
                }
              }}
            />
          </div>
        ))}
        {/*  */}
        <div className="mt-12 flex justify-end">
          <button
            onClick={onSubmit}
            // disabled={updateTruckLoading}
            id="saveDrivers"
            className="button-icon-reverse flex h-[41px] w-[84px] items-center justify-center gap-2 rounded-md bg-green p-2 text-xs text-white  hover:bg-gold hover:text-green"
          >
            {updateTruckLoading ? (
              <LoaderSmall />
            ) : (
              <>
                <img src={saveBigIcon} alt="save icon" />
                <p>{t('modalAssociateTruckService.save')}</p>
              </>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default TruckDrivers;
