import iconCapacity from '../../../assets/icons/capacity.svg';
import iconHoodCleaning from '../../../assets/icons/cooker-hood-btn.svg';
import fatBones from '../../../assets/icons/fat-bones-btn.svg';
import iconGreaseTrap from '../../../assets/icons/grease-trap-btn.svg';
import iconOilDisposal from '../../../assets/icons/oil-disposal-btn.svg';
import {
  FAT_BONES_SERVICE_NAME,
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
} from '../../../config/constants';
import { formatNumber } from '../../../utils/numberFormatter';

type Props = {
  service: string;
  capacity: number;
};

const TruckServiceCard = ({ service, capacity }: Props) => {
  const switchIcon = () => {
    switch (service) {
      case OIL_DISPOSAL_SERVICE_NAME:
        return iconOilDisposal;
      case HOOD_CLEANING_SERVICE_NAME:
        return iconHoodCleaning;
      case GREASE_TRAP_SERVICE_NAME:
        return iconGreaseTrap;
      case FAT_BONES_SERVICE_NAME:
        return fatBones;
      default:
        return iconOilDisposal;
    }
  };

  return (
    <div
      className="box-shadow flex h-[122px] w-[150px] flex-col items-center gap-[0.2rem] rounded-lg px-6 py-4"
      id={`${service.replace(/\s/g, '')}Card`}
    >
      <img src={switchIcon()} alt={service} />
      <div className="mt-1 flex items-center justify-center gap-1">
        <img className="h-[16px]" src={iconCapacity} alt="icon worker" />
        <p className="text-xs">{formatNumber(capacity)}</p>
      </div>
    </div>
  );
};

export default TruckServiceCard;
