import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import type { RouteData } from '@/types/route';

import { ReactComponent as HoodIcon } from '../../../../assets/icons/cooker-hood.svg';
import { ReactComponent as GreaseTrapIcon } from '../../../../assets/icons/grease-trap.svg';
import { ReactComponent as OilIcon } from '../../../../assets/icons/oil-disposal.svg';
import { routeOptions } from '../../../../config/constants';
import { useDropdownData } from '../../../../hooks/dropdownData';
import { useNotification } from '../../../../hooks/useNotification';
import { driversListState } from '../../../../recoil/driversList';
import { routeUpdateState } from '../../../../recoil/routeUpdate';
import { trucksListState } from '../../../../recoil/trucksList';
import { UPDATE_ROUTE } from '../../../../services/graphql/route/route-mutations';
import { convertDateToString } from '../../../../utils/convertDate';
import DateField from '../../common/DateField';
import Dropdown from '../../common/Dropdown';

type Driver = {
  label: string;
  value: string;
};

type Props = {
  route: RouteData;
  refetchRoutes: () => void;
};

const RouteEditCard = ({ route, refetchRoutes }: Props) => {
  const { t } = useTranslation('common');
  const { notifyUpdateError, notifyUpdateOk } = useNotification();
  const { id } = useParams();

  const drivers = useRecoilValue(driversListState);
  const trucks = useRecoilValue(trucksListState);
  const [updateRoute] = useMutation(UPDATE_ROUTE);
  const [date, setDate] = useState<string>(route.date);
  const [truckDriver, setTruckDriver] = useState<string>(route.driver_name);
  const [truckId, setTruck] = useState<string>(route.truck_id);
  const [, setRouteUpdated] = useRecoilState(routeUpdateState);
  const routeId = id;
  useDropdownData(route.service, date, truckId, truckDriver);

  const editableStatuses = [routeOptions[0]?.value, routeOptions[1]?.value];

  const handleDriverChange = async (selectedOption: string) => {
    const selectedDriver: Driver = drivers.find(
      (driver: Driver) => driver.value === selectedOption,
    )!;

    if (!selectedDriver) {
      notifyUpdateError();
      return;
    }

    if (selectedOption !== route.user_id) {
      setTruckDriver(selectedOption);
      await updateRoute({
        variables: {
          driverId: selectedOption,
          routeId,
        },
        onCompleted: () => {
          notifyUpdateOk();
          setRouteUpdated(true);
          refetchRoutes();
        },
        onError: () => {
          notifyUpdateError();
        },
      });
    }
  };

  const handleTruckChange = async (selectedOption: string) => {
    if (selectedOption === route.truck_license) return;
    const truck: any = trucks.find(
      (_truck: any) => _truck.value === selectedOption,
    );
    const selectedTruckId = truck ? truck.id : undefined;
    if (selectedTruckId) {
      setTruck(selectedTruckId);
      await updateRoute({
        variables: {
          truckId: selectedTruckId,
          routeId,
        },
        onCompleted: () => {
          notifyUpdateOk();
          setRouteUpdated(true);
          refetchRoutes();
        },
        onError: () => {
          notifyUpdateError();
        },
      });
    }
  };

  const handleDateChange = async (selectedDate: string) => {
    if (selectedDate === route.date) return;
    if (selectedDate) {
      await updateRoute({
        variables: {
          date: selectedDate,
          routeId,
        },
        onCompleted: () => {
          notifyUpdateOk();
          setRouteUpdated(true);
          refetchRoutes();
        },
        onError: () => {
          notifyUpdateError();
        },
      });
    }
  };

  return (
    <div className="grid w-full grid-cols-1 gap-4 md:gap-y-3">
      <div className="flex w-[200px] flex-col items-start justify-start">
        <label className="my-2 block text-[14px] font-[600]">
          {t('routes.route-card-service')}
        </label>
        <div
          className={`flex w-full flex-row items-center border-none p-0 text-xs  placeholder:text-[#5A5A5A] focus:outline-none focus:ring-0`}
        >
          {route.service === 'Grease Trap' && <GreaseTrapIcon />}
          {route.service === 'Hood Cleaning' && <HoodIcon />}
          {route.service === 'Oil Disposal' && <OilIcon />}
          {route.service}
        </div>
      </div>
      <div className="flex w-[200px] flex-col items-start justify-start gap-2.5">
        <DateField
          label={t('routes.route-card-date')}
          placeholder="Date"
          selectedValue={
            date
              ? new Date(date).getTime().toString()
              : new Date(route.date).getTime().toString()
          }
          onSelectChange={(_date: any) => {
            setDate(convertDateToString(_date));
            handleDateChange(convertDateToString(_date));
          }}
          onlyActualDate={true}
          disabled={!editableStatuses.includes(route.status)}
        />
      </div>
      <div className="w-[200px]">
        <Dropdown
          options={drivers}
          label={t('routes.route-card-driver')}
          selectedValue={route.driver_name}
          onSelectChange={handleDriverChange}
          placeholder={route.driver_name}
          disabled={!editableStatuses.includes(route.status)}
        />
      </div>
      <div className="w-[200px]">
        <Dropdown
          options={trucks}
          label={t('routes.route-card-truck')}
          selectedValue={route.truck_license}
          onSelectChange={handleTruckChange}
          placeholder={route.truck_license}
          disabled={!editableStatuses.includes(route.status)}
        />
      </div>
    </div>
  );
};

export default RouteEditCard;
