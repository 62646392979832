import './styles/App.css';
import './styles/index.css';
import './styles/custom.css';
import 'react-toastify/dist/ReactToastify.css';

import { useDatadogRum } from './hooks/useDatadogRum';
import { useUserData } from './hooks/useUserData';
import AppRouter from './router/AppRouter';

function App() {
  useUserData();
  useDatadogRum();
  return <AppRouter />;
}

export default App;
