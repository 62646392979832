import { useState } from 'react';
import Select from 'react-select';

import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import { stopOptions } from '../../../config/constants';
import {
  colourStyles,
  CustomOption,
  CustomSingleValue,
} from '../../../styles/statusDropdownStyle';

type Props = {
  initialStatusValue: string;
  mutation: (arg: any) => void;
  stopId: string;
};

const StopStatusDropdown = ({
  initialStatusValue,
  mutation,
  stopId,
}: Props) => {
  const [status, setStatus] = useState<string>(initialStatusValue);

  const handleChangeStatus = (selectedOption: any) => {
    const newStatus = selectedOption.value;
    setStatus(newStatus);

    mutation({
      variables: {
        key: 'status',
        value: selectedOption.value,
        stopId,
      },
    });
  };

  return (
    <>
      <div className="absolute flex text-xs font-medium">
        <Select
          styles={colourStyles(status, stopOptions)}
          components={{
            DropdownIndicator: () => <ArrowDownIcon />,
            IndicatorSeparator: () => null,
            Option: CustomOption,
            SingleValue: CustomSingleValue,
          }}
          options={stopOptions}
          onChange={handleChangeStatus}
          id="StopStatusDropdown"
          value={stopOptions.find((option) => option.value === status)}
          isSearchable={false}
        />
      </div>
    </>
  );
};

export default StopStatusDropdown;
