import type { ApolloError } from '@apollo/client';
import type { Table } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import arrowDownIcon from '../../../../assets/icons/arrow-down.svg';
import beforeIcon from '../../../../assets/icons/before.svg';
import beforemaxIcon from '../../../../assets/icons/beforemax.svg';
import nextIcon from '../../../../assets/icons/next.svg';
import nextmaxIcon from '../../../../assets/icons/nextmax.svg';
import LoaderSmall from '../../LoaderSmall';

interface Props {
  table: Table<any>;
  handleRowClick?: (val: string, event: any) => void;
  handleRowClickClient?: (
    val: string,
    val2: string,
    event: any,
    val3: string[] | undefined,
  ) => void;
  error: ApolloError | undefined;
  total: number;
  loading: boolean;
  tableId: string;
  sorting: { orderBy?: string | null; orderDirection?: string | null };
  loadingRows: number;
  pagination?: boolean;
  tableLayout?: 'auto' | 'fixed';
}

const widths = [
  'w-[35%]',
  'w-[45%]',
  'w-[55%]',
  'w-[65%]',
  'w-[75%]',
  'w-[85%]',
  'w-full',
];

const DataTable = ({
  table,
  handleRowClick,
  handleRowClickClient,
  error,
  total,
  loading,
  tableId,
  sorting,
  loadingRows,
  pagination = true,
  tableLayout = 'fixed',
}: Props) => {
  const { t } = useTranslation('common');
  const rowId = (row: any) => {
    if (row.original.license) return row.original.license;
    if (row.original.name) return row.original.name;
    return row.original.id;
  };

  const getRandomWidth = () => {
    const rnd = Math.floor(Math.random() * (7 + 1));
    return widths[rnd];
  };

  const loadingSkeleton = useMemo(
    () =>
      Array.from({ length: loadingRows }, (_, i) => i + 1).map((_i) => (
        <tr key={_i} className="h-[45px] border-b-[1px] border-b-transparent">
          {table.getHeaderGroups()[0]?.headers.map((h) => (
            <td key={h.id} className="cell-class truncate px-2">
              <div
                className={`h-[15px] animate-skeleton rounded-[5px] ${
                  h.id === 'select' ? 'w-1/2' : getRandomWidth()
                }`}
              ></div>
            </td>
          ))}
        </tr>
      )),
    [loading],
  );

  return (
    <>
      <table
        style={{ tableLayout, width: '100%' }}
        className="w-full table-fixed"
        id={tableId}
      >
        <thead className="h-[37px] bg-green text-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                let className = '';
                if (index === 0) {
                  className = 'rounded-l-lg';
                } else if (index === headerGroup.headers.length - 1) {
                  className = 'rounded-r-lg';
                }
                return (
                  <th
                    colSpan={header.colSpan}
                    style={{
                      width: header.getSize(),
                    }}
                    key={header.id}
                    className={`px-2 text-left ${className}`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, {
                          ...header.getContext(),
                          ...sorting,
                        })}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {loading && loadingSkeleton}
          {!loading && error ? (
            <tr>
              <td colSpan={table.getAllColumns().length}>
                <p>Error: {error.message}</p>
              </td>
            </tr>
          ) : (
            total === 0 && (
              <tr className="h-[70px]">
                <td colSpan={table.getAllColumns().length}>
                  <p className="w-full text-center text-xs italic">
                    {t('global.no-data')}
                  </p>
                </td>
              </tr>
            )
          )}

          {!loading &&
            table.getRowModel().rows.map((row) => {
              return (
                <tr
                  onClick={(event) => {
                    if (handleRowClickClient) {
                      handleRowClickClient(
                        row.original.id as string,
                        row.original.name as string,
                        event,
                        row.original.services,
                      );
                      return;
                    }

                    if (handleRowClick) {
                      handleRowClick(row.original.id as string, event);
                    }
                  }}
                  className={`relative h-[45px] overflow-auto border-b-[1px] border-graylight hover:bg-graydark ${
                    handleRowClick || handleRowClickClient
                      ? 'hover:cursor-pointer'
                      : ''
                  } ${row.depth > 0 ? '!bg-[#EBF0F1]' : ''}`}
                  key={row.id}
                  id={rowId(row).replace(/\s/g, '')}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="cell-class truncate px-2">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header, index) => {
                let className = '';

                if (index === 0) {
                  className = 'rounded-l-lg';
                } else if (index === footerGroup.headers.length - 1) {
                  className = 'rounded-r-lg';
                }

                return (
                  <th key={header.id} className={`px-2 ${className}`}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </th>
                );
              })}
            </tr>
          ))}
        </tfoot>
      </table>
      {/* Pagination */}
      {pagination && (
        <div className="my-6">
          <div className="flex items-center gap-2">
            <span className="text-[14px] font-medium">Page</span>
            <div className="flex h-[29px] items-center justify-center rounded-[5px] border border-greenlight">
              <button
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
                className={`${!table.getCanPreviousPage() ? 'opacity-30' : ''}`}
              >
                <img src={beforemaxIcon} alt="before icon" />
              </button>
              <button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                className={`${!table.getCanPreviousPage() ? 'opacity-30' : ''}`}
              >
                <img src={beforeIcon} alt="before icon" />
              </button>
              <div className="flex items-center gap-1">
                <div className="dropdown-container relative inline-flex">
                  <select
                    value={table.getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      table.setPageIndex(page);
                    }}
                    style={{
                      backgroundImage: `url(${arrowDownIcon})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right 2px center',
                      backgroundSize: '16px 16px',
                      cursor: 'pointer',
                    }}
                    className="inline-block h-[24px] w-[40px] border-none pr-1 text-center text-[14px] font-normal focus:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0"
                  >
                    {Array.from(
                      { length: table.getPageCount() },
                      (_, index) => (
                        <option
                          className="text-center"
                          key={index + 1}
                          value={index + 1}
                        >
                          {index + 1}
                        </option>
                      ),
                    )}
                  </select>
                </div>
              </div>
              <button
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                className={`${!table.getCanNextPage() ? 'opacity-30' : ''}`}
              >
                <img src={nextIcon} alt="next icon" />
              </button>
              <button
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
                className={`${!table.getCanNextPage() ? 'opacity-30' : ''}`}
              >
                <img src={nextmaxIcon} alt="next icon" />
              </button>
            </div>

            <span className="text-[14px] font-medium">Show</span>
            <select
              className="inline-block h-[28px] w-[63px] rounded-[5px] border border-greenlight pl-2 pr-4 text-center text-[14px] font-normal focus:outline-none focus:ring-0 focus:ring-offset-0"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
              style={{
                backgroundImage: `url(${arrowDownIcon})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right 10px center',
                backgroundSize: '16px 16px',
                cursor: 'pointer',
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((_pageSize) => (
                <option key={_pageSize} value={_pageSize}>
                  {_pageSize}
                </option>
              ))}
            </select>
            {loading && (
              <div>
                <LoaderSmall />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DataTable;
