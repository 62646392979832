import {
  damageGradeOptions,
  tireStatusOptions,
} from '../../../config/constants';

type Props = {
  label: string;
  level: string | number;
  tireStatus?: boolean;
};

const LevelIndicator = ({ level, label }: Props) => {
  const bulletColor =
    tireStatusOptions.find((option) => option.value === level)?.color ||
    damageGradeOptions.find((option) => option.value === level)?.color ||
    'graydark';
  const bullet = `bg-[${bulletColor}]`;
  return (
    <div>
      {label && (
        <label htmlFor="name" className="my-2 block text-[14px] font-[600]">
          {label}
        </label>
      )}
      <div className="flex items-center gap-2">
        {bullet && (
          <span
            style={{ backgroundColor: bulletColor }}
            className={`flex h-[6px] w-[6px] rounded-full`}
          ></span>
        )}
        <span className={`relative flex w-full text-xs`}>{level}</span>
      </div>
    </div>
  );
};

export default LevelIndicator;
