import { gql } from '@apollo/client';

export const GET_ALL_TRUCKS = gql`
  query GetAllTruck(
    $offset: Int
    $limit: Int
    $orderBy: String
    $orderDirection: String
    $nameFilter: String
    $status: [String]
    $deleted: Boolean
    $model: [String]
    $service: [String]
  ) {
    getAllTruck(
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      orderDirection: $orderDirection
      nameFilter: $nameFilter
      status: $status
      deleted: $deleted
      model: $model
      service: $service
    ) {
      status
      code
      count
      data {
        id
        license
        mileage
        make
        oil_service
        oil_service_gallons
        grease_service
        grease_service_gallons
        fatBones_service
        fatBones_service_gallons
        hood_service
        hood_service_gallons
        tk
        model
        status
        createdAt
        updatedAt
        deletedAt
        last_used_by
      }
      error
    }
  }
`;

export const GET_TRUCK_CAPACITY_BY_ID = gql`
  query GetTruckById($truckId: String!) {
    getTruckById(truckId: $truckId) {
      status
      code
      data {
        truck {
          oil_service_gallons
          grease_service_gallons
          fatBones_service_gallons
          hood_service_gallons
        }
      }
      error
    }
  }
`;

export const GET_TRUCK_BY_ID = gql`
  query GetTruckById($truckId: String!) {
    getTruckById(truckId: $truckId) {
      status
      code
      data {
        documents {
          id
          client_id
          name
          stop_id
          truck_id
          type
          url
          date
          createdAt
          updatedAt
          deletedAt
        }
        truck {
          id
          license
          mileage
          oil_level
          antifreeze_level
          tire_status
          def_level
          gas_level
          oil_service
          oil_service_gallons
          grease_service
          grease_service_gallons
          fatBones_service
          fatBones_service_gallons
          hood_service
          hood_service_gallons
          make
          tk
          model
          status
          createdAt
          updatedAt
          deletedAt
        }
        incidents {
          id
          truck_id
          route_id
          repairedAt
          status
          incident_category
          incident_grade
          cost
          driver
          createdAt
          updatedAt
        }
        truckDrivers {
          user_id
          truck_id
          createdAt
          updatedAt
          deletedAt
          driver
        }
      }
      error
    }
  }
`;

export const GET_TRUCK_BY_ID_DETAILS_VIEW = gql`
  query GetTruckById($truckId: String!) {
    getTruckById(truckId: $truckId) {
      status
      code
      data {
        truck {
          id
          license
          mileage
          oil_level
          antifreeze_level
          tire_status
          TKEXPDATE
          def_level
          gas_level
          oil_service
          oil_service_gallons
          grease_service
          grease_service_gallons
          fatBones_service
          fatBones_service_gallons
          hood_service
          hood_service_gallons
          make
          tk
          model
          status
          createdAt
          updatedAt
          deletedAt
        }
        truckDrivers {
          user_id
          truck_id
          createdAt
          updatedAt
          deletedAt
          driver
        }
      }
      error
    }
  }
`;

export const GET_TRUCKS_BY_SERVICE = gql`
  query GetTruckByService($service: String, $date: String) {
    getTruckByService(service: $service, date: $date) {
      status
      code
      data {
        id
        license
        mileage
        oil_level
        antifreeze_level
        tire_status
        def_level
        gas_level
        oil_service
        oil_service_gallons
        grease_service
        grease_service_gallons
        fatBones_service
        fatBones_service_gallons
        hood_service
        hood_service_gallons
        make
        tk
        model
        status
        createdAt
        updatedAt
        deletedAt
      }
      error
    }
  }
`;
