import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import clientLogo from '../../assets/icons/clients-small.svg';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import { LoaderScreen } from '../../components/ui/LoaderScreen';

const DTClients = React.lazy(
  () => import('../../components/ui/clients/DTClients'),
);

const ClientsView = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <section>
        <Breadcrumb title={t('clients.title-h1')} image={clientLogo} />
        <Suspense fallback={<LoaderScreen />}>
          <DTClients />
        </Suspense>
      </section>
    </>
  );
};

export default ClientsView;
