import { useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import type { GetStatusStopsResponse } from '@/types/route';
import type { LastRoutesTruckTable } from '@/types/tables';

import { LAST_ROUTES_TABLE_MAX_RESULTS } from '../../../config/constants';
import { selectedClient } from '../../../recoil/navigation';
import { GET_ALL_ROUTES } from '../../../services/graphql/route/route-querys';
import { convertToAmericanDateFormat } from '../../../utils/convertTimestamp';
import { getCurrentDate } from '../../../utils/dateFormatting';
import { formatNumber } from '../../../utils/numberFormatter';
import DataTable from '../common/DataTable/DataTable';
import DTHeader from '../common/DataTable/DTHeader';

type Props = {
  truckLicense: string;
};

const DTLastRoutes = ({ truckLicense }: Props) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  // States
  const [client] = useRecoilState(selectedClient);
  const [filters, setFilters] = useState({
    orderBy: '',
    orderDirection: '',
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: LAST_ROUTES_TABLE_MAX_RESULTS,
  });
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  // Queries
  const {
    data: fetchData,
    loading,
    error,
    refetch,
  } = useQuery<GetStatusStopsResponse>(GET_ALL_ROUTES, {
    variables: {
      licenseFilter: [truckLicense],
      limit: pageSize,
      dateEndFilter: getCurrentDate(),
      dateStartFilter: '2000-01-01',
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'date',
      orderDirection: filters?.orderDirection || 'DESC',
    },
    fetchPolicy: 'no-cache',
    skip: !truckLicense,
  });

  useEffect(() => {
    if (!fetchData?.getRoutes.data) {
      return;
    }

    const formattedData = fetchData?.getRoutes?.data.map((item) => ({
      id: item.id,
      name: item.route_name,
      date: item.date,
      driver: item.driver,
      service: item.service,
      incident: item.truck_incidents,
      mileage: item.truck_mileage,
    }));

    setData(formattedData);
    setTotal(fetchData?.getRoutes?.count || 0);
  }, [fetchData, client]);

  const columnHelper = createColumnHelper<LastRoutesTruckTable>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info: any) => (
          <span className="cell-class cursor-pointer text-xs font-bold">
            {info.getValue()}
          </span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-route-name')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="name"
          />
        ),
      }),
      columnHelper.accessor('date', {
        size: 150,
        minSize: 100,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() !== null
              ? convertToAmericanDateFormat(info.getValue() as string)
              : ''}
          </span>
        ),

        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-last-routes-date')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="date"
          />
        ),
      }),
      columnHelper.accessor('driver', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-driver')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="driver"
          />
        ),
        cell: (info: any) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
      }),
      columnHelper.accessor('service', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-service')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="service"
          />
        ),
      }),
      columnHelper.accessor('mileage', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">
            {formatNumber(info.getValue())}
          </span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('trucks.th-mileage')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="mileage"
          />
        ),
      }),
      columnHelper.accessor('incident', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">
            {info.getValue() || 'None'}
          </span>
        ),
        header: () => (
          <DTHeader title={t('trucks.th-incident')} accessor="mileage" />
        ),
      }),
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: 'DESC',
      orderBy: 'date',
    }));
  }, []);

  useEffect(() => {
    if (!truckLicense) return;
    refetch({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...filters,
    });
  }, [filters, pageIndex, pageSize]);

  // FUNCTIONS
  const handleRowClick = (routeId: string, event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    const isCellClick = target.classList.contains('cell-class');

    if (isCellClick) {
      // Go to route
      navigate(`/dashboard/routes/${routeId}`);
    }
  };

  return (
    <>
      <div className="min-h-[200px] overflow-auto">
        <DataTable
          error={error}
          handleRowClick={handleRowClick}
          loading={loading}
          table={table}
          total={total}
          tableId="LastRoutesTable"
          sorting={{ ...filters }}
          loadingRows={pageSize}
        />
      </div>
    </>
  );
};

export default DTLastRoutes;
