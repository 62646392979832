import { useNavigate } from 'react-router-dom';

import iconHoodCleaning from '../../../../assets/icons/cooker-hood-btn.svg';
import fatBones from '../../../../assets/icons/fat-bones-btn.svg';
import iconGreaseTrap from '../../../../assets/icons/grease-trap-btn.svg';
import iconZone from '../../../../assets/icons/location.svg';
import iconOilDisposal from '../../../../assets/icons/oil-disposal-btn.svg';
import iconFrequency from '../../../../assets/icons/repeat-circle.svg';
import iconRoute from '../../../../assets/icons/routing.svg';
import iconTruck from '../../../../assets/icons/truck-tick.svg';
import {
  FAT_BONES_SERVICE_NAME,
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
} from '../../../../config/constants';
import type { ClientService } from '../../../../types/service';
import { convertToAmericanDateFormat } from '../../../../utils/convertTimestamp';

type Props = {
  service: ClientService;
  client: string;
  id?: string;
};

const ClientServiceCard = ({ service, client, id }: Props) => {
  const { name } = service;

  const switchIcon = () => {
    switch (name) {
      case OIL_DISPOSAL_SERVICE_NAME:
        return iconOilDisposal;
      case HOOD_CLEANING_SERVICE_NAME:
        return iconHoodCleaning;
      case GREASE_TRAP_SERVICE_NAME:
        return iconGreaseTrap;
      case FAT_BONES_SERVICE_NAME:
        return fatBones;
      default:
        return iconOilDisposal;
    }
  };

  const navigate = useNavigate();

  const handleGoToService = () => {
    const serviceName = service.name.toLowerCase().replace(/\s+/g, '-');
    if (serviceName === 'meat,-fat-&-bone') {
      navigate(`/dashboard/clients/meat-fat-bone/${client}`);
      return;
    }
    navigate(`/dashboard/clients/${serviceName}/${client}`);
  };

  return (
    <button
      id={id}
      onClick={handleGoToService}
      className="box-shadow flex h-[122px] w-[241px]  items-center gap-2 rounded-lg px-6 py-4"
    >
      <div className="flex w-1/3 justify-center">
        <img src={switchIcon()} alt="service icon" />
      </div>
      <div className="flex w-2/3 flex-col items-start">
        <div className="flex gap-2">
          <img src={iconFrequency} alt="frequency icon" />
          <span className="text-xs">{service.frequency || '-'}</span>
        </div>
        <div className="flex gap-2">
          <img src={iconTruck} alt="last service icon" />
          <span className="text-xs">
            {service.last_service
              ? convertToAmericanDateFormat(service.last_service)
              : '-'}
          </span>
        </div>
        <div className="flex gap-2">
          <img src={iconRoute} alt="scheduled icon" />
          <span className="text-xs">
            {service.next_scheduled
              ? convertToAmericanDateFormat(service.next_scheduled)
              : '-'}
          </span>
        </div>
        <div className="flex gap-2">
          <img src={iconZone} alt="zone icon" />
          <span className="text-xs">{service.route_zone || '-'}</span>
        </div>
      </div>
    </button>
  );
};

export default ClientServiceCard;
