import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { selectedClient } from '../recoil/navigation';

export const useClientSelected = (clientData: any) => {
  const [clientSelected, setClientSelected] = useRecoilState(selectedClient);
  useEffect(() => {
    if (!clientSelected.id && clientData?.getClientById?.data) {
      setClientSelected({
        ...clientSelected,
        id: clientData?.getClientById?.data.client.id || '',
        name: clientData?.getClientById?.data.client.name || '',
        services: clientData?.getClientById?.data.clientService.map(
          (s: any) => s.name,
        ),
      });
    }
  }, [clientData]);

  return {};
};
