import { atom } from 'recoil';

export interface DisbursementFilter {
  orderBy?: string | null;
  orderDirection?: 'ASC' | 'DESC' | null;
  limit?: number | null;
  offset?: number | null;
  [key: string]: string | string[] | boolean | null | number | undefined | Date;
}

export const disburseFilterState = atom<DisbursementFilter | null>({
  key: '_disbursementFiltersState',
  default: null,
});

export const paymentFilterState = atom<DisbursementFilter | null>({
  key: '_paymentsFiltersState',
  default: null,
});
