import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import arrowDownIcon from '../../../assets/icons/arrow-down.svg';

type Option = {
  value: string;
  label: string;
};

type Props = {
  options: string[] | Option[];
  selectedValues: string[] | undefined;
  onChange: (value: string[]) => void;
  label: string;
  placeholder?: string;
};

const DropdownMultiple = ({
  options,
  onChange,
  label,
  selectedValues,
  placeholder,
}: Props) => {
  const { t } = useTranslation('common');

  const [statusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false);
  const [localOptions] = useState<string[] | Option[]>(options || []);

  const handleChange = (value: string) => {
    const updatedValue = selectedValues?.includes(value)
      ? selectedValues.filter((item) => item !== value)
      : [...(selectedValues || []), value];

    onChange(updatedValue.filter((v) => v));
  };

  return (
    <div className="h-[60px]">
      <label
        onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}
        htmlFor={label}
        className="my-2 block text-[14px] font-[600]"
      >
        {label}
      </label>
      <div className="relative">
        <div
          onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}
          className="flex w-full cursor-pointer justify-end overflow-hidden"
        >
          <span className={`w-full truncate text-left text-xs text-[#5A5A5A]`}>
            {selectedValues && selectedValues.length > 0
              ? selectedValues.join(', ')
              : placeholder || t('global.select-some-option')}
          </span>
          <img src={arrowDownIcon} alt="arrow down icon" />
        </div>
        {statusDropdownOpen && (
          <div
            onMouseLeave={() => setStatusDropdownOpen(false)}
            className="absolute left-0 top-5 z-10 flex max-h-[300px] min-w-[50%] max-w-[60%] flex-col justify-center gap-2 overflow-auto rounded-md bg-white p-2 shadow-md shadow-gray-300"
          >
            {localOptions?.length > 0 &&
              localOptions?.map((option: string | Option, index: number) => (
                <div key={index} className="flex items-center gap-2">
                  <input
                    checked={
                      !!selectedValues?.includes(
                        typeof option === 'string' ? option : option.value,
                      )
                    }
                    onChange={() => {
                      handleChange(
                        typeof option === 'string' ? option : option.value,
                      );
                    }}
                    className="flex h-[18px] w-[18px] cursor-pointer items-center justify-center rounded-md border border-green bg-transparent text-green checked:bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
                    type="checkbox"
                    name="filterStatusActive"
                    id="filterStatusActive"
                  />
                  <span className="text-xs">
                    {typeof option === 'string'
                      ? option
                      : option.label.charAt(0).toUpperCase() +
                        option.label.slice(1).toLowerCase()}
                  </span>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownMultiple;
