// En tu archivo de estado Recoil
import { atom } from 'recoil';

export const confirmationModalState = atom({
  key: 'confirmationModalStateAtom',
  default: {
    isOpen: false,
    onConfirm: () => {},
    onCancel: () => {},
    message: '',
  },
});
