import type { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import filterIcon from '../../../assets/icons/filter.svg';
import searchIcon from '../../../assets/icons/search.svg';

type Props = {
  filterValue: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOpenFilterModal: () => void;
};

const Searchbar = ({ filterValue, onChange, handleOpenFilterModal }: Props) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex gap-4">
      <div className="flex h-[41px] w-[360px] items-center rounded-[15px] border border-greenlight">
        <div className="flex w-[30px] items-center justify-center">
          <img
            src={searchIcon}
            alt="search icon"
            className="h-[16px] w-[16px]"
          />
        </div>
        <input
          value={filterValue}
          onChange={onChange}
          type="text"
          id="searchBarClient"
          placeholder={t('clients.filter-placeholder')}
          className="inline-flex  h-[90%] w-[88%]  items-center justify-start gap-2.5 border-transparent p-2 text-base font-medium focus:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0"
        />
      </div>
      <button
        onClick={handleOpenFilterModal}
        className="button-icon inline-flex h-[41px] w-[46px] items-center justify-center rounded-[5px] border border-greenlight p-2 hover:bg-green"
      >
        <img src={filterIcon} alt="filter icon" />
      </button>
    </div>
  );
};

export default Searchbar;
