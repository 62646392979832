import { useQuery } from '@apollo/client';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import addIcon from '../../assets/icons/add.svg';
import smallSettings from '../../assets/icons/smallSettings.svg';
import AddButton from '../../components/ui/common/AddButton';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import DataTable from '../../components/ui/common/DataTable/DataTable';
import DTHeader from '../../components/ui/common/DataTable/DTHeader';
import { CLIENTS_TABLES_MAX_RESULTS } from '../../config/constants';
import { useModal } from '../../hooks/useModal';
import { equipmentSelectedToEdit } from '../../recoil/dataToEdit';
import { needRefreshState } from '../../recoil/needRefresh';
import { settingEquipmentFilter } from '../../recoil/settingEquipmentFilter';
import { GET_EQUIPMENT } from '../../services/graphql/equipment/equipmentQuery';
import { formatNumberMoney } from '../../utils/numberFormatter';

type TEquipment = {
  id: number;
  name: string;
  quantity: number;
  cost: number;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
};

const Equipment = () => {
  const { t } = useTranslation('common');
  const [filters, setFilters] = useRecoilState(settingEquipmentFilter);
  const [, setEquipment] = useRecoilState(equipmentSelectedToEdit);
  const { handleOpenNewEquipmentModal } = useModal();

  // Sorting
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };
  // const notifyUpdateSuccess = () => toast.success(t('settings.notifySuccess'));
  const [data, setData] = useState<Array<TEquipment>>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: CLIENTS_TABLES_MAX_RESULTS,
  });
  const [total, setTotal] = useState<number>(0);

  const columnHelper = createColumnHelper<TEquipment>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-bold">{info.getValue()}</span>
        ),
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('settingsEquipment.name')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="name"
          />
        ),
      }),
      columnHelper.accessor('quantity', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        cell: (info) => (
          <span className="text-xs font-medium">{info.getValue()}</span>
        ),

        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('settingsEquipment.quantity')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="quantity"
          />
        ),
      }),
      columnHelper.accessor('cost', {
        size: 150,
        minSize: 150,
        maxSize: 200,
        header: ({ header, ...info }) => (
          <DTHeader
            sortable
            onClick={() => handleChangeSortDirection(header.id)}
            title={t('settingsEquipment.cost')}
            sorting={(info as any).orderBy}
            sortingDirection={(info as any).orderDirection}
            accessor="cost"
          />
        ),
        cell: (info) => (
          <span className="text-xs font-medium">
            {formatNumberMoney(info.getValue())}
          </span>
        ),
      }),
    ],
    [],
  );

  const {
    data: fetchData,
    loading,
    error,
    refetch: refetchEquipment,
  } = useQuery(GET_EQUIPMENT, {
    variables: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      orderBy: filters?.orderBy || 'name',
      orderDirection: filters?.orderDirection || 'DESC',
    },
  });

  useEffect(() => {
    if (fetchData && fetchData.getEquipment && fetchData.getEquipment.data) {
      const updatedData: TEquipment[] = fetchData.getEquipment.data.map(
        (equipment: TEquipment) => ({
          id: equipment.id,
          name: equipment.name || '',
          quantity: equipment.quantity || '',
          cost: equipment.cost || '',
        }),
      );

      setData(updatedData);
      setTotal(fetchData.getEquipment.count);
    }
  }, [fetchData]);

  const [needRefresh] = useRecoilState(needRefreshState);

  useEffect(() => {
    refetchEquipment({
      limit: pageSize,
      offset: pageIndex * pageSize,
      ...filters,
    });
  }, [filters, needRefresh, pageIndex, pageSize]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  const handleRowClick = (id: string, event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    const isCellClick = target.classList.contains('cell-class');

    if (isCellClick) {
      setEquipment(id);
      handleOpenNewEquipmentModal();
    }
  };

  return (
    <>
      <section>
        <div className="fixed top-0 z-20 w-full bg-white">
          <Breadcrumb
            title={`${t('settings.title')}`}
            secondTitle={`${t('settings.equipment')}`}
            image={smallSettings}
          />
        </div>
        <div className="mt-8 flex flex-col">
          <div className="flex justify-between">
            <h1 className="flex font-inter text-xl font-bold">
              {t('settings.equipment')}
            </h1>
            <AddButton
              text={t('settingsEquipment.button')}
              icon={addIcon}
              id="newEquipmentButton"
              handleAction={handleOpenNewEquipmentModal}
            />
          </div>
          <div className="mt-5">
            <DataTable
              error={error}
              loading={loading}
              handleRowClick={handleRowClick}
              table={table}
              total={total}
              tableId="EquipmentTable"
              sorting={{ ...filters }}
              loadingRows={total || pageSize}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Equipment;
