import { atom } from 'recoil';

export const userStateAtom = atom({
  key: 'userState',
  default: {
    id: '',
    name: '',
    phone: '',
    email: '',
    role: '',
    status: '',
    profile_picture: '',
    signature: '',
    createdAt: '',
    updatedAt: '',
    auth0_id: '',
  },
});

export const userRolesAtom = atom({
  key: 'userRoles',
  default: [],
});
