import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import clientLogo from '../../assets/icons/clients-small.svg';
import DTDocuments from '../../components/ui/clients/documents/DTDocuments';
import ClientFixedCard from '../../components/ui/clients/overall/ClientFixedCard';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import { useClientSelected } from '../../hooks/useClientSelected';
import { useNotification } from '../../hooks/useNotification';
import { UPDATE_CLIENT } from '../../services/graphql/client/client-mutations';
import { GET_CLIENT_BY_ID } from '../../services/graphql/client/client-querys';
import type { ClientQuery } from '../../types/client';

const ClientDocumentView = () => {
  const { t } = useTranslation('common');
  const { notifySaveSuccess, notifySaveError } = useNotification();

  const { client } = useParams();

  // const setNeedRefresh = useSetRecoilState(needRefreshState);

  // === QUERIES ===

  const { data, refetch } = useQuery<ClientQuery>(GET_CLIENT_BY_ID, {
    variables: { clientId: client },
  });

  // === MUTATIONS ===

  const [updateClient] = useMutation<any>(UPDATE_CLIENT, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: () => {
      notifySaveSuccess();
      refetch();
    },
  });

  // This is necessary to load client info on reload on this page.
  // This hook needs to be on every single client page so the global state
  // knows what to show on the secondary menu for the client we currently are in.
  useClientSelected(data);

  // === FUNCTIONS ===

  return (
    <>
      <section>
        <div className="fixed top-0 z-20 w-full bg-white">
          <Breadcrumb
            title={`${t('clients.title-h1')}`}
            secondTitle={data?.getClientById?.data.client.name}
            thirdTitle={t('client-documents.title-h1')}
            image={clientLogo}
          />
        </div>
        <div className="mt-[30px] flex w-full flex-col md:flex-row">
          {/* Client Business Card */}
          <div className="flex w-[260px]">
            {data && client && (
              <ClientFixedCard
                client={client}
                data={data}
                updateClient={updateClient}
              />
            )}
          </div>

          <div className="flex w-full flex-col px-6 pt-6">
            {/* Service info */}
            <div className="w-full">
              <h1 className="mb-2 text-xl font-semibold">
                {t('client-documents.title-h1')}
              </h1>
            </div>

            {/* Data table */}

            {data?.getClientById?.data.clientService && <DTDocuments />}
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientDocumentView;
