import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import calendarIcon from '../../../assets/icons/calendar.svg';
import { convertStrToDate } from '../../../utils/convertDate';

type Props = {
  label?: string;
  selectedValue?: string | null;
  onSelectChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  onlyActualDate?: boolean;
};

const DateField = ({
  selectedValue,
  onSelectChange,
  label,
  placeholder,
  onlyActualDate,
  disabled = false,
}: Props) => {
  const [inputDate, setInputDate] = useState<Date | null>(
    selectedValue ? convertStrToDate(selectedValue) : null,
  );

  useEffect(() => {
    if (selectedValue && inputDate !== convertStrToDate(selectedValue))
      setInputDate(convertStrToDate(selectedValue));
  }, [selectedValue]);

  return (
    <div>
      {label && (
        <label htmlFor={label} className="my-2 block text-[14px] font-[600]">
          {label}
        </label>
      )}
      <div className="flex items-center">
        <img src={calendarIcon} alt="calendar icon" />
        <DatePicker
          id={label}
          calendarStartDay={1}
          dateFormat={'yyyy-MM-dd'}
          className="w-full cursor-pointer border-none bg-white px-2 py-0 text-xs text-green placeholder:text-[#5A5A5A] focus:ring-0 focus:ring-transparent focus:ring-offset-0"
          selected={inputDate || null}
          onChange={(date: any) => {
            onSelectChange(date);
            setInputDate(date);
          }}
          placeholderText={placeholder || ''}
          disabled={disabled}
          autoComplete="off"
          minDate={onlyActualDate ? new Date() : undefined}
        />
      </div>
    </div>
  );
};

export default DateField;
