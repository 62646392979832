import 'react-datepicker/dist/react-datepicker.css';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import clientIcon from '../../../assets/icons/clients-small.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { routeOptions, serviceClientOptions } from '../../../config/constants';
import { useModal } from '../../../hooks/useModal';
import { needRefreshState } from '../../../recoil/needRefresh';
import { routeFilterState } from '../../../recoil/routesFilter';
import { trucksListState } from '../../../recoil/trucksList';
import { convertDateToString } from '../../../utils/convertDate';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import DateField from '../../ui/common/DateField';
import DropdownMultiple from '../../ui/common/DropdownMultiple';

interface DriverOrTruck {
  label: string;
  value: string;
}
const FilterRoute = () => {
  const { t } = useTranslation('common');

  // Form title
  const breadcrumbTitle = `${t('routes.title-h1')} > ${t(
    'modalFilterRoutes.filters',
  )}`;

  const trucks: DriverOrTruck[] = useRecoilValue(trucksListState);
  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  // Filters
  const [filters, setFilters] = useRecoilState(routeFilterState);

  const [statusFilter, setStatusFilter] = useState<string[] | undefined>(
    undefined,
  );
  const [serviceFilter, setServiceFilter] = useState<string[] | undefined>(
    undefined,
  );
  const [startDateFilter, setStartDateFilter] = useState<string | null>('');
  const [endDateFilter, setEndDateFilter] = useState<string | null>('');
  const [licenseFilter, setLicenseFilter] = useState<string[] | undefined>(
    undefined,
  );

  const routeStatus = routeOptions.map((option) => option.value);
  useEffect(() => {
    if (filters?.statusFilter) {
      const updatedStatusFilter = [...filters.statusFilter];
      setStatusFilter(updatedStatusFilter);
    }
    if (filters?.dateStartFilter) {
      setStartDateFilter(filters.dateStartFilter);
    }
    if (filters?.dateEndFilter) {
      setEndDateFilter(filters.dateEndFilter);
    }
    if (filters?.serviceFilter) {
      const updatedServiceFilter = [...filters.serviceFilter];
      setServiceFilter(updatedServiceFilter);
    }
    if (filters?.licenseFilter) {
      const updatedLicenseFilter = [...filters.licenseFilter];
      setLicenseFilter(updatedLicenseFilter);
    }
  }, [filters]);

  const handleStatusFilterChange = (selected: string[]) => {
    if (selected.length > 0) {
      setStatusFilter(selected);
    } else {
      setStatusFilter(undefined);
    }
  };

  const handleServiceFilterChange = (selected: string[]) => {
    if (selected.length > 0) {
      setServiceFilter(selected);
    } else {
      setServiceFilter(undefined);
    }
  };

  const handleLicenseFilterChange = (selectedTruckLabels: string[]) => {
    const selectedTrucks = trucks.filter((truck) =>
      selectedTruckLabels.includes(truck.label),
    );
    const transformedTrucks = selectedTrucks.map((truck) => truck.label);
    setLicenseFilter(transformedTrucks);
  };

  const handleFiltersSave = () => {
    let newFilters: Record<string, any> = {};

    newFilters = { ...filters };

    if (statusFilter) {
      newFilters.statusFilter = statusFilter;
    } else {
      newFilters.statusFilter = undefined;
    }

    if (serviceFilter) {
      newFilters.serviceFilter = serviceFilter;
    } else {
      newFilters.serviceFilter = undefined;
    }

    if (startDateFilter) {
      newFilters.dateStartFilter = startDateFilter;
    } else {
      newFilters.dateStartFilter = undefined;
    }

    if (endDateFilter) {
      newFilters.dateEndFilter = endDateFilter;
    } else {
      newFilters.dateEndFilter = undefined;
    }

    if (licenseFilter) {
      newFilters.licenseFilter = licenseFilter;
    } else {
      newFilters.licenseFilter = undefined;
    }

    if (Object.keys(newFilters).length > 0) {
      setFilters(newFilters);
    } else {
      setFilters(null);
    }
    closeOrder();
  };
  return (
    <>
      <Breadcrumb title={breadcrumbTitle} image={clientIcon} />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalFilterClient.filters')}
      </h1>
      <DropdownMultiple
        label="Status"
        onChange={handleStatusFilterChange}
        options={routeStatus}
        selectedValues={statusFilter || []}
      />

      {/* Start Date */}
      <div className="h-[60px]">
        <DateField
          onSelectChange={(date: any) => {
            setStartDateFilter(convertDateToString(date));
          }}
          selectedValue={
            startDateFilter
              ? new Date(startDateFilter).getTime().toString()
              : ''
          }
          label={t('modalFilterRoutes.start-date')}
          placeholder={t('global.select-date')}
        />
      </div>
      {/* End Date */}
      <div className="h-[60px]">
        <DateField
          onSelectChange={(date: any) => {
            setEndDateFilter(convertDateToString(date));
          }}
          selectedValue={
            endDateFilter ? new Date(endDateFilter).getTime().toString() : ''
          }
          label={t('modalFilterRoutes.end-date')}
          placeholder={t('global.select-date')}
        />
      </div>
      {/* Service */}
      <div className="h-[60px]">
        <DropdownMultiple
          label={t('modalFilterRoutes.service')}
          onChange={handleServiceFilterChange}
          options={serviceClientOptions}
          selectedValues={serviceFilter || undefined}
        />
      </div>
      {/* License */}
      <div className="h-[60px]">
        <DropdownMultiple
          label={t('modalFilterRoutes.license')}
          onChange={handleLicenseFilterChange}
          options={trucks}
          selectedValues={licenseFilter || undefined}
        />
      </div>

      <div className="flex justify-end">
        <Button
          text={t('modalNewClient.save')}
          variant="green"
          icon={saveBigIcon}
          onClick={handleFiltersSave}
          type="button"
          id="save-route-filters"
        />
      </div>
    </>
  );
};

export default FilterRoute;
