import { selector } from 'recoil';

import type { WithDrawFilter } from './withdrawFilter';
import { withDrawFilterState } from './withdrawFilter';

export const cleanWithDrawFiltersState = selector({
  key: 'cleanWithDrawFilters',
  get: ({ get }) => {
    const currentFilters = get(withDrawFilterState);

    // Clean the filters and return a new copy with everything null
    const cleanedFilters: WithDrawFilter = {};
    Object.keys(currentFilters || {}).forEach((key) => {
      cleanedFilters[key] = null;
    });

    return cleanedFilters;
  },
});
