import { gql } from '@apollo/client';

export const UPDATE_DRIVER_RECEIPT = gql`
  mutation UpdateDriverReceipt(
    $id: String!
    $amount: Float
    $category: String
  ) {
    updateDriverReceipt(id: $id, amount: $amount, category: $category) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_DRIVER_RECEIPT = gql`
  mutation DeleteDriverReceipt($id: String!) {
    deleteDriverReceipt(id: $id) {
      status
      code
      data
      error
    }
  }
`;
