import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useSetRecoilState } from 'recoil';

import type { GetStatisticsQuery } from '@/types/document';

import clientLogo from '../../assets/icons/clients-small.svg';
import removeIcon from '../../assets/icons/trush-square.svg';
import DTGreaseTrap from '../../components/ui/clients/grease-trap/DTGreaseTrap';
import ClientFixedCard from '../../components/ui/clients/overall/ClientFixedCard';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import DateField from '../../components/ui/common/DateField';
import Dropdown from '../../components/ui/common/Dropdown';
import DropdownMultiple from '../../components/ui/common/DropdownMultiple';
import Graph from '../../components/ui/common/Graph';
import GraphTruck from '../../components/ui/common/GraphTruck';
import InputField from '../../components/ui/common/InputField';
import Toggle from '../../components/ui/common/Toggle';
import {
  GREASE_TRAP_SERVICE_NAME,
  serviceFrequencyOptions,
  serviceZoneOptions,
} from '../../config/constants';
import { useClientSelected } from '../../hooks/useClientSelected';
import { confirmationModalState } from '../../recoil/modalDelete';
import { selectedClient } from '../../recoil/navigation';
import { needRefreshState } from '../../recoil/needRefresh';
import { UPDATE_CLIENT } from '../../services/graphql/client/client-mutations';
import { GET_CLIENT_BY_ID } from '../../services/graphql/client/client-querys';
import {
  DELETE_SERVICE,
  UPDATE_SERVICE,
} from '../../services/graphql/service/service-mutations';
import { GET_STATISTICS } from '../../services/graphql/service/service-querys';
import type { ClientQuery } from '../../types/client';
import { formatNumber } from '../../utils/numberFormatter';

const ClientGreaseTrapView = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { client } = useParams();

  const [serviceId, setServiceId] = useState<string>('');
  const setConfirmModalState = useSetRecoilState(confirmationModalState);
  const setNeedRefresh = useSetRecoilState(needRefreshState);
  const [clientSelected, setClientSelected] = useRecoilState(selectedClient);

  // === VISUAL NOTIFICATIONS ===
  const notifySaveError = () => toast.error(t('notifications.save-error'));
  const notifySaveSuccess = () =>
    toast.success(t('notifications.save-ok'), {
      toastId: 'notifyStatusSaveOk',
    });
  const notifyDeleteOk = () =>
    toast.success(t('notifications.delete-ok'), {
      toastId: 'notifyStatusDeleteOk',
    });
  const notifyDeleteError = () => toast.error(t('notifications.delete-error'));

  // === QUERIES ===

  const { data, refetch } = useQuery<ClientQuery>(GET_CLIENT_BY_ID, {
    variables: { clientId: client },
  });

  const { data: statistics } = useQuery<GetStatisticsQuery>(GET_STATISTICS, {
    variables: {
      serviceId,
    },
    skip: !serviceId,
  });

  // This is necessary to load client info on reload on this page.
  // This hook needs to be on every single client page so the global state
  // knows what to show on the secondary menu for the client we currently are in.
  useClientSelected(data);

  // === MUTATIONS ===
  const [updateService] = useMutation<any>(UPDATE_SERVICE, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: () => {
      notifySaveSuccess();
      refetch();
    },
  });

  const [updateClient] = useMutation<any>(UPDATE_CLIENT, {
    onError: () => {
      notifySaveError();
    },
    onCompleted: () => {
      notifySaveSuccess();
      refetch();
    },
  });

  const [deleteService] = useMutation<any>(DELETE_SERVICE, {
    variables: {
      serviceId,
    },
    onCompleted: () => {
      notifyDeleteOk();
      navigate(`/dashboard/clients/overall/${client}`);
    },
    onError: () => {
      notifyDeleteError();
    },
  });

  useEffect(() => {
    const service = data?.getClientById?.data.clientService.find(
      (_service) => _service.name === GREASE_TRAP_SERVICE_NAME,
    );
    if (service) {
      setServiceId(service.id);
    }
  }, [data]);

  // === FUNCTIONS ===
  const handleFrequencyChange = (value: string) => {
    const service = data?.getClientById?.data.clientService.find(
      (_service) => _service.name === GREASE_TRAP_SERVICE_NAME,
    );
    if (service) {
      updateService({
        variables: {
          serviceId: service.id,
          key: 'frequency',
          value,
        },
      });
    }
  };

  const handleZoneChange = (value: string) => {
    const service = data?.getClientById?.data.clientService.find(
      (_service) => _service.name === GREASE_TRAP_SERVICE_NAME,
    );
    if (service) {
      updateService({
        variables: {
          serviceId: service.id,
          key: 'route_zone',
          value,
        },
      });
    }
  };

  const handleReadyToVisitChange = (value: boolean) => {
    const service = data?.getClientById?.data.clientService.find(
      (_service) => _service.name === GREASE_TRAP_SERVICE_NAME,
    );
    if (service) {
      updateService({
        variables: {
          serviceId: service.id,
          key: 'ready_to_visit',
          value: value.toString(),
        },
      });
    }
  };

  const handleContainerSizeChange = (value: string | number) => {
    const service = data?.getClientById?.data.clientService.find(
      (_service) => _service.name === GREASE_TRAP_SERVICE_NAME,
    );
    if (service) {
      updateService({
        variables: {
          serviceId: service.id,
          key: 'container_size',
          value,
        },
      });
    }
  };

  const handleContainerLocationChange = (value: string | number) => {
    const service = data?.getClientById?.data.clientService.find(
      (_service) => _service.name === GREASE_TRAP_SERVICE_NAME,
    );
    if (service) {
      updateService({
        variables: {
          serviceId: service.id,
          key: 'container_location',
          value,
        },
      });
    }
  };

  const handleLastServiceChange = (value: string) => {
    const service = data?.getClientById?.data.clientService.find(
      (_service) => _service.name === GREASE_TRAP_SERVICE_NAME,
    );
    if (service) {
      updateService({
        variables: {
          serviceId: service.id,
          key: 'last_service',
          value,
        },
      });
    }
  };

  const handleNextScheduledChange = (value: string) => {
    const service = data?.getClientById?.data.clientService.find(
      (_service) => _service.name === GREASE_TRAP_SERVICE_NAME,
    );
    if (service) {
      updateService({
        variables: {
          serviceId: service.id,
          key: 'next_scheduled',
          value,
        },
      });
    }
  };

  const handleAvaliableDaysChange = (value: string[]) => {
    const service = data?.getClientById?.data.clientService.find(
      (_service) => _service.name === GREASE_TRAP_SERVICE_NAME,
    );
    if (service) {
      updateService({
        variables: {
          serviceId: service.id,
          key: 'available_days',
          value: value.toString().trim(),
        },
      });
    }
  };

  const handleRemoveService = () => {
    setConfirmModalState({
      isOpen: true,
      onConfirm: () => {
        deleteService();
        setClientSelected({
          ...clientSelected,
          services: clientSelected?.services?.filter(
            (service) => service !== GREASE_TRAP_SERVICE_NAME,
          ),
        });
        setNeedRefresh(true);
        setTimeout(() => {
          navigate(`/dashboard/clients/overall/${client}`);
        }, 1000);
      },
      onCancel: () => {
        // Nothing happens
      },
      message: t('global.are-you-sure-delete'),
    });
  };

  return (
    <>
      <section>
        <div className="fixed top-0 z-20 w-full bg-white">
          <Breadcrumb
            title={`${t('clients.title-h1')}`}
            secondTitle={data?.getClientById?.data.client.name}
            thirdTitle={t('client-grease-trap.service-name')}
            image={clientLogo}
          />
        </div>
        <div className="mt-[30px] flex w-full flex-col md:flex-row">
          {/* Client Business Card */}
          <div className="flex w-[260px]">
            {data && client && (
              <ClientFixedCard
                client={client}
                data={data}
                updateClient={updateClient}
              />
            )}
          </div>

          <div className="flex w-full flex-col px-6 pt-6">
            {/* Service info */}
            <div className="w-full">
              <h1 className="mb-2 text-xl font-semibold">
                {t('client-grease-trap.service-info')}
              </h1>
              <div className="grid w-full max-w-6xl grid-cols-1 gap-2 md:grid-cols-2 md:gap-2 lg:grid-cols-3 lg:gap-4 xl:grid-cols-4 xl:gap-6">
                {/* Frequency option */}
                <div className="min-h-[44px] w-[200px]">
                  <Dropdown
                    options={serviceFrequencyOptions}
                    selectedValue={
                      data?.getClientById?.data.clientService.find(
                        (service) => service.name === GREASE_TRAP_SERVICE_NAME,
                      )?.frequency || ''
                    }
                    onSelectChange={handleFrequencyChange}
                    label={t('client-grease-trap.frequency')}
                  />
                </div>

                {/* Last Service option */}
                <div className="min-h-[44px] w-[200px]">
                  <DateField
                    placeholder={t('global.select-date')}
                    onSelectChange={handleLastServiceChange}
                    selectedValue={
                      data?.getClientById?.data.clientService.find(
                        (service) => service.name === GREASE_TRAP_SERVICE_NAME,
                      )?.last_service || ''
                    }
                    label={t('client-grease-trap.last-service')}
                    disabled
                  />
                </div>

                {/* Available days option */}
                <div className="min-h-[44px] w-[200px]">
                  <DropdownMultiple
                    label={t('global.available-days')}
                    placeholder={t('global.select-days')}
                    options={[
                      'Monday',
                      'Tuesday',
                      'Wednesday',
                      'Thursday',
                      'Friday',
                      'Saturday',
                      'Sunday',
                    ]}
                    onChange={handleAvaliableDaysChange}
                    selectedValues={
                      data?.getClientById?.data.clientService
                        .find(
                          (service) =>
                            service.name === GREASE_TRAP_SERVICE_NAME,
                        )
                        ?.available_days?.filter((v) => v) || []
                    }
                  />
                </div>

                {/* Next Scheduled option */}
                <div className="min-h-[44px] w-[200px]">
                  <DateField
                    placeholder={t('global.select-date')}
                    onSelectChange={handleNextScheduledChange}
                    selectedValue={
                      data?.getClientById?.data.clientService.find(
                        (service) => service.name === GREASE_TRAP_SERVICE_NAME,
                      )?.next_scheduled || ''
                    }
                    label={t('client-grease-trap.next-scheduled')}
                    disabled
                  />
                </div>

                {/* Container size option */}
                <div className="min-h-[44px] w-[200px]">
                  <InputField
                    type="number"
                    unitGal={true}
                    label={t('client-grease-trap.container-size')}
                    selectedValue={
                      formatNumber(
                        data?.getClientById?.data.clientService
                          .find(
                            (service) =>
                              service.name === GREASE_TRAP_SERVICE_NAME,
                          )
                          ?.container_size?.toString() ?? 0,
                      ) || 0
                    }
                    onSelectChange={handleContainerSizeChange}
                    placeholder={formatNumber(
                      data?.getClientById?.data.clientService
                        .find(
                          (service) =>
                            service.name === GREASE_TRAP_SERVICE_NAME,
                        )
                        ?.container_size?.toString() ?? 0,
                    )}
                  />
                </div>
                {/* Container location option */}
                <div className="min-h-[44px] w-[200px]">
                  <InputField
                    type="text"
                    label={t('client-grease-trap.container-location')}
                    selectedValue={
                      data?.getClientById?.data.clientService.find(
                        (service) => service.name === GREASE_TRAP_SERVICE_NAME,
                      )?.container_location || null
                    }
                    onSelectChange={handleContainerLocationChange}
                  />
                </div>

                {/* Zone Option */}
                <div className="min-h-[44px] w-[200px]">
                  <Dropdown
                    options={serviceZoneOptions}
                    selectedValue={
                      data?.getClientById?.data.clientService.find(
                        (service) => service.name === GREASE_TRAP_SERVICE_NAME,
                      )?.route_zone || ''
                    }
                    onSelectChange={handleZoneChange}
                    label={t('client-grease-trap.route-zone')}
                  />
                </div>
                {/* Ready to visit option */}
                <div className="min-h-[44px] w-[200px]">
                  <Toggle
                    selectedValue={
                      data?.getClientById?.data.clientService.find(
                        (service) => service.name === GREASE_TRAP_SERVICE_NAME,
                      )?.ready_to_visit || false
                    }
                    onSelectChange={handleReadyToVisitChange}
                    label={t('client-grease-trap.ready-to-visit')}
                    placeholder={t('client-grease-trap.is-ready-to-visit')}
                  />
                </div>
              </div>
            </div>

            {/* Data table */}

            <h1 className="mb-2 mt-12 text-xl font-semibold">
              {t('client-grease-trap.table-label')}
            </h1>

            {data?.getClientById?.data.clientService && (
              <DTGreaseTrap
                serviceId={
                  data.getClientById.data.clientService.find(
                    (_service) => _service.name === GREASE_TRAP_SERVICE_NAME,
                  )?.id || ''
                }
              />
            )}

            {/* Metrics */}
            {statistics && statistics.getStatistics?.data && (
              <>
                <h2 className="mb-2 mt-6 text-xl font-semibold">
                  {t('metrics.title')}
                </h2>

                <div className="mb-6 flex flex-col gap-6 md:gap-12 lg:flex-row">
                  {serviceId &&
                    statistics &&
                    statistics?.getStatistics?.data.total_numbers &&
                    statistics?.getStatistics?.data.total_oil && (
                      <Graph
                        data={statistics?.getStatistics?.data.total_numbers}
                        qty={statistics?.getStatistics?.data.total_oil.toString()}
                        qtyType={t('metrics.gal')}
                        subTitle={t('metrics.total-oil')}
                      />
                    )}

                  {serviceId &&
                    statistics &&
                    statistics?.getStatistics?.data.percentaje_numbers &&
                    statistics?.getStatistics?.data.container_percentage && (
                      <GraphTruck
                        qty={
                          statistics?.getStatistics?.data.container_percentage
                        }
                        qtyType="%"
                        subTitle={t('metrics.truck-capacity')}
                      />
                    )}
                </div>
              </>
            )}

            {/* Delete service */}
            <div className="m-6 flex justify-center">
              <button
                onClick={handleRemoveService}
                className="flex items-center"
              >
                <img src={removeIcon} alt="remove icon" />
                <span className="ml-2 text-xs font-bold text-redish underline">
                  {t('client-grease-trap.remove-service')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientGreaseTrapView;
