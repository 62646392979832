import 'react-datepicker/dist/react-datepicker.css';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import routeIcon from '../../../assets/icons/routes.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { receiptTypeOptions } from '../../../config/constants';
import { useCreateDriverReceipt } from '../../../hooks/createDriverReceipt';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { routeUpdateState } from '../../../recoil/routeUpdate';
import { selectedRouteState } from '../../../recoil/selectedRoute';
import { formatNumberMoney } from '../../../utils/numberFormatter';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import Dropdown from '../../ui/common/Dropdown';
import InputField from '../../ui/common/InputField';
import ReceiptUploader from '../../ui/common/ReceiptUploader';

const NewReceipt = () => {
  const { t } = useTranslation('common');
  const { notifySaveError, notifySaveSuccess } = useNotification();

  const selectedRoute = useRecoilValue(selectedRouteState);
  const [receiptFile, setReceiptFile] = useState<File | null>(null);
  const [receiptAmount, setReceiptAmount] = useState<number>(0);
  const [receiptCategory, setReceiptCategory] = useState<string>('');
  const { createDriverReceipt, loading: createReceiptLoading } =
    useCreateDriverReceipt();
  const [routeUpdateStateValue, setRouteUpdated] =
    useRecoilState(routeUpdateState);
  const { id } = useParams();
  const routezId = id;
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
  };

  const handleFileSubmit = (file: File) => {
    setReceiptFile(file);
  };
  const handleCategoryChange = (category: string) => {
    setReceiptCategory(category);
  };
  const handleAmountChange = (value: number | string) => {
    setReceiptAmount(Number(value));
  };

  const handleSubmit = () => {
    if (!receiptFile || !receiptCategory || !receiptAmount) {
      notifySaveError();
      return;
    }
    createDriverReceipt({
      variables: {
        file: receiptFile,
        category: receiptCategory,
        amount: receiptAmount,
        route_id: routezId,
      },
      context: {
        headers: {
          'content-type': 'multipart/form-data',
          'x-apollo-operation-name': 'createDriverReceipt',
        },
      },
      onCompleted: () => {
        setRouteUpdated(!routeUpdateStateValue);
        notifySaveSuccess();
        closeOrder();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  return (
    <>
      <Breadcrumb
        title={t('modalNewReceipt.newReceiptBreadcrumbTitle')}
        secondTitle={selectedRoute?.route_name}
        thirdTitle={t('modalNewReceipt.newReceiptBreadcrumb')}
        image={routeIcon}
      />
      <p className="mt-6 text-xl font-[600]">
        {t('modalNewReceipt.newReceiptTitle')}
      </p>
      <p className="my-4 text-lg font-[600]">{t('modalNewReceipt.image')}</p>
      <ReceiptUploader submitFile={handleFileSubmit} />
      <Dropdown
        label={t('modalNewReceipt.category')}
        onSelectChange={handleCategoryChange}
        options={receiptTypeOptions}
        selectedValue="Choose an option"
        placeholder="Choose an option"
      />
      <InputField
        label="Amount"
        type="number"
        onSelectChange={handleAmountChange}
        placeholder={formatNumberMoney(receiptAmount)}
        doNotUseSaveSelfButton={true}
      />
      <div className="mt-6 flex justify-end">
        <Button
          text={t('modalNewRoute.save')}
          variant="green"
          icon={saveBigIcon}
          disabled={createReceiptLoading}
          loading={createReceiptLoading}
          onClick={handleSubmit}
          type="button"
          id="save-new-receipt"
        />
      </div>
    </>
  );
};

export default NewReceipt;
