import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import closeIcon from '../../assets/icons/close-square.svg';
import { useBlockScroll } from '../../hooks/useBlockScroll';
import DTSuggested from '../ui/dashboard/DTSuggested';

type Props = {
  toggleModal: () => void;
  isOpen: boolean;
};

function ModalFull({ toggleModal, isOpen }: Props) {
  const { t } = useTranslation('common');
  const { allowScroll, blockScroll } = useBlockScroll();

  useEffect(() => {
    const { body } = document;
    if (isOpen && body) blockScroll();
    else if (!isOpen && body) allowScroll();
  }, [isOpen]);

  if (!isOpen) return <></>;

  return (
    <div id="modal">
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          className="fixed inset-0 bg-gray-800 opacity-50"
          onClick={toggleModal}
        ></div>
        <div className="fixed left-1/2 top-1/2 max-h-[80%] min-h-[50%] w-[80%] -translate-x-1/2 -translate-y-1/2 overflow-auto rounded-[20px] bg-white p-6 shadow-lg md:px-12 md:py-16">
          <button
            onClick={toggleModal}
            className="absolute right-2 top-2 m-2 cursor-pointer text-lg text-gray-500 hover:text-red-500"
          >
            <img src={closeIcon} alt="close icon" />
          </button>
          <h2 className="my-1 text-lg font-medium">
            {t('dashboard.table-suggested-title')}
          </h2>
          <DTSuggested forModal />
        </div>
      </div>
    </div>
  );
}

export default ModalFull;
