/* eslint-disable consistent-return */
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactComponent as AddIcon } from '../../../../assets/icons/add-circle.svg';
import { useModal } from '../../../../hooks/useModal';
import { tokenStateAtom } from '../../../../recoil/authState';
import { routeUpdateState } from '../../../../recoil/routeUpdate';
import { selectedReceipt } from '../../../../recoil/selectedReceipt';
import { GET_ROUTE_RECEIPTS_BY_ID } from '../../../../services/graphql/receipts/receiptsQuery';
import { formatNumberMoney } from '../../../../utils/numberFormatter';

interface ReceiptProps {
  route_Id: string | undefined;
}

const Receipts: React.FC<ReceiptProps> = ({ route_Id }) => {
  const { t } = useTranslation('common');
  const token = useRecoilValue(tokenStateAtom);
  const [receipts, setReceipts] = useState<any[]>([]);
  // const { createDriverReceipt } = useCreateDriverReceipt();
  const { data, refetch } = useQuery(GET_ROUTE_RECEIPTS_BY_ID, {
    variables: { routeId: route_Id },
  });
  const { handleOpenEditReceiptModal, handleOpenNewReceiptModal } = useModal();
  const setReceipt = useSetRecoilState(selectedReceipt);
  const routeUpdated = useRecoilValue(routeUpdateState);

  const handleReceiptClick = (receipt: {
    image: string;
    id: string;
    amount: number;
    category: string;
  }) => {
    setReceipt({
      image: receipt.image,
      id: receipt.id,
      amount: receipt.amount,
      category: receipt.category,
    });
    handleOpenEditReceiptModal();
  };
  const handleNewReceiptClick = () => {
    handleOpenNewReceiptModal();
  };

  useEffect(() => {
    if (data && data.getRouteById && data.getRouteById.data) {
      Promise.all(
        data.getRouteById.data.driverReceipts.map(async (receipt: any) => {
          const imageUrl = `${process.env.REACT_APP_FILE_URL}${receipt.image}`;
          if (imageUrl) {
            const response = await fetch(imageUrl, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const blob = await response.blob();
            return { ...receipt, image: URL.createObjectURL(blob) };
          }
          return null;
        }),
      ).then((fetchedReceipts) => {
        fetchedReceipts.sort((a, b) => b.date - a.date);
        setReceipts(fetchedReceipts);
      });
    }
  }, [data, token]);

  useEffect(() => {
    refetch();
  }, [routeUpdated]);
  if (!receipts || receipts.length === 0)
    return (
      <label
        htmlFor="fileInput"
        className="inline-flex h-[300px] w-[300px] cursor-pointer flex-col items-center justify-center gap-2 rounded-[10px] bg-white px-[15px] shadow"
        onClick={handleNewReceiptClick}
      >
        <div className="flex flex-col items-center justify-center rounded-lg">
          <span className="mb-2">
            <AddIcon className="h-8 w-8" />
          </span>
          <p className="mt-2 w-full text-xs italic">
            {t('routes.route-driver-receipts-add')}
          </p>
          <span className="text-center text-white">{t('receipts.add')}</span>
        </div>
      </label>
    );

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
      {receipts.map((receipt, index) => (
        <div
          key={index}
          className="inline-flex h-[300px] w-[201px] cursor-pointer flex-col items-center justify-center gap-2 rounded-[10px] bg-white px-[25px] py-5 shadow"
          onClick={() => handleReceiptClick(receipt)}
        >
          <div className="flex h-[177px] flex-col items-start justify-center gap-2 self-stretch">
            <img
              className="h-[100px] self-stretch rounded-[10px] object-cover"
              src={receipt.image}
              alt="Receipt"
            />
            <div className="flex-col items-center justify-center self-stretch">
              <div className="mb-2 text-sm font-normal text-gray-400">
                {new Date(parseInt(receipt.date, 10)).toLocaleString('en-US', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                })}
              </div>
              <div className="mb-2 flex w-full flex-row items-center justify-between border-b border-gray-400">
                <p className="text-sm font-normal text-gray-400">Category</p>
                <p className="text-right text-sm font-normal text-zinc-800">
                  {receipt.category}
                </p>
              </div>
            </div>
            <div className="flex h-[19px] flex-col items-start justify-start gap-0.5 self-stretch">
              <div className="mb-1 flex w-full flex-row items-center justify-between border-b border-gray-400">
                <p className="text-sm font-normal text-gray-400">Amount</p>
                <p className="text-right text-sm font-normal text-zinc-800">
                  {formatNumberMoney(receipt.amount)}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
      <label
        htmlFor="fileInput"
        className="inline-flex h-[300px] w-[74px] cursor-pointer flex-col items-center justify-center gap-2 rounded-[10px] bg-white px-[15px] shadow"
        onClick={handleNewReceiptClick}
      >
        <div className="flex flex-col items-center justify-center rounded-lg">
          <span className="mb-2">
            <AddIcon className="h-8 w-8" />
          </span>
          <span className="text-center text-white">{t('receipts.add')}</span>
        </div>
      </label>
    </div>
  );
};

export default Receipts;
