import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MAX_NUMBER_OF_NOTES, NOTES_MAX_RESULTS } from '../config/constants';
import { GET_NOTES_BY_ROUTEID } from '../services/graphql/notes/notesQuery';

const useRouteNotes = () => {
  const { id: routeId } = useParams();

  const {
    loading,
    fetchMore,
    error: errorNotes,
    data,
    refetch,
    updateQuery,
  } = useQuery(GET_NOTES_BY_ROUTEID, {
    variables: {
      routeId,
      offset: 0,
      limit: NOTES_MAX_RESULTS,
    },
  });

  const [notes, setNotes] = useState(data?.getNotesByRouteId.data ?? []);

  const [totalNotes, setTotalNotes] = useState(
    data?.getNotesByRouteId.count || 0,
  );

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  function calculateHasNextPage(
    total: number,
    limit: number,
    currentTotal: number,
  ) {
    const currentPage = Math.ceil(currentTotal / limit);
    const totalPages = Math.ceil(total / limit);

    return currentPage < totalPages;
  }

  function refetchNotes() {
    setHasNextPage(true);
    setNotes([]);

    refetch({
      routeId,
      offset: 0,
      limit: NOTES_MAX_RESULTS,
    });
  }

  useEffect(() => {
    if (
      data?.getNotesByRouteId.data &&
      data?.getNotesByRouteId.data.length > 0
    ) {
      setNotes(data.getNotesByRouteId.data);
      setTotalNotes(data.getNotesByRouteId.count);
    }
  }, [data]);

  const fetchMoreNotes = useCallback(() => {
    if (notes.length === MAX_NUMBER_OF_NOTES) return;

    fetchMore({
      variables: {
        routeId,
        offset: notes.length,
        limit: NOTES_MAX_RESULTS,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const newData = fetchMoreResult.getNotesByRouteId.data || [];
        setNotes((prevNotes: any) => [...prevNotes, ...newData]);

        // Verificar si hay más páginas después de la página actual
        setHasNextPage(
          calculateHasNextPage(
            fetchMoreResult.getNotesByRouteId.count || 0,
            NOTES_MAX_RESULTS,
            notes.length + newData.length,
          ),
        );

        return {
          getNotesByRouteId: {
            ...prev.getNotesByRouteId,
            data: [...prev.getNotesByRouteId.data, ...newData],
          },
        };
      },
    });
  }, [fetchMore, notes, routeId]);

  return {
    fetchMoreNotes,
    loading,
    notes,
    refetchNotes,
    errorNotes,
    hasNextPage,
    updateQuery,
    totalNotes,
  };
};

export default useRouteNotes;
