import { atom } from 'recoil';

export interface InvoicesFilters {
  nameFilter?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  deletedFilter?: boolean | null;
  typeFilter?: string[] | null;
  orderBy?: string | null;
  orderDirection?: 'ASC' | 'DESC' | null;
  limit?: number | null;
  offset?: number | null;
  [key: string]: string | string[] | boolean | null | number | undefined;
}

export const invoicesFiltersState = atom<InvoicesFilters | null>({
  key: '_invoicesFiltersState',
  default: null,
});
