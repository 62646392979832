export function convertToAmericanDateFormatWithTime(
  dateString: string
): string {
  const timestamp = Number(dateString);

  if (!isNaN(timestamp)) {
    const date = new Date(timestamp);

    const dateOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    return `${formattedDate} · ${formattedTime}`;
  } else {
    const date = new Date(dateString);

    if (!isNaN(date.getTime())) {
      const dateOptions: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      const timeOptions: Intl.DateTimeFormatOptions = {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };

      const formattedDate = date.toLocaleDateString("en-US", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

      return `${formattedDate} · ${formattedTime}`;
    } else {
      return "Invalid date";
    }
  }
}
