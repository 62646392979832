import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

export const useDatadogRum = () => {
  const { user } = useAuth0();

  /**
   * Initialize Datadog RUM
   */
  useEffect(() => {
    if (!datadogRum.getInternalContext()) {
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
        site: process.env.REACT_APP_DATADOG_SITE || '',
        service: process.env.REACT_APP_DATADOG_SERVICE || '',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'allow',
      });
    }

    datadogRum.startSessionReplayRecording();
  }, []);

  useEffect(() => {
    if (user && datadogRum) {
      datadogRum.setUser({
        id: user.sub,
        email: user.email,
        name: user.name,
      });
    }
  }, [user, datadogRum]);
};
