type Props = {
  label?: string;
  selectedValue: boolean;
  onSelectChange: (value: boolean) => void;
  placeholder?: string;
  id?: string;
};

const Toggle = ({
  selectedValue,
  onSelectChange,
  label,
  placeholder,
  id,
}: Props) => {
  return (
    <div>
      {label && (
        <label htmlFor={label} className="my-2 block text-[14px] font-[600]">
          {label}
        </label>
      )}
      <div className="relative flex h-[20px] items-center">
        <div className="flex w-full items-center justify-between text-xs font-medium">
          {placeholder && (
            <p className="text-xs text-[#5A5A5A]">{placeholder}</p>
          )}
          <label className="switch">
            <input
              id={id}
              type="checkbox"
              checked={selectedValue}
              onChange={(e) => onSelectChange(e.target.checked)}
              className="h-[16px] w-[16px] rounded-sm border border-[#E5E5E5]"
            />

            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Toggle;
