import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  FAT_BONES_SERVICE_NAME,
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
} from '../../../config/constants';
import { GET_TRUCK_CAPACITY_BY_ID } from '../../../services/graphql/trucks/trucks-queries';

interface ProgressBarProps {
  estimatedCapacity: number;
  truckId?: string;
  service?: string;
}

const COLORS = {
  ok: 'bg-green',
  over: 'bg-redish',
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  estimatedCapacity,
  truckId,
  service,
}) => {
  const { data } = useQuery(GET_TRUCK_CAPACITY_BY_ID, {
    variables: { truckId },
  });
  const { t } = useTranslation('common');

  let totalCapacity;
  let type = 'Gal';
  switch (service) {
    case OIL_DISPOSAL_SERVICE_NAME:
      totalCapacity = data?.getTruckById?.data?.truck?.oil_service_gallons;
      break;
    case HOOD_CLEANING_SERVICE_NAME:
      totalCapacity = data?.getTruckById?.data?.truck?.hood_service_gallons;
      break;
    case GREASE_TRAP_SERVICE_NAME:
      totalCapacity = data?.getTruckById?.data?.truck?.grease_service_gallons;
      break;
    case FAT_BONES_SERVICE_NAME:
      totalCapacity = data?.getTruckById?.data?.truck?.fatBones_service_gallons;
      type = 'Barrels';
      break;
    default:
      totalCapacity = 0;
      break;
  }

  let percentage = (estimatedCapacity / totalCapacity) * 100;
  let color = COLORS.ok;

  if (estimatedCapacity > totalCapacity) {
    percentage = 100;
    color = COLORS.over;
  }

  if (estimatedCapacity > totalCapacity) {
    percentage = 100;
    color = COLORS.over;
  }

  return (
    <div className="flex flex-col items-start gap-2">
      <p className="text-md font-semibold">{t('routes.truck-capacity')}</p>
      <div className="flex w-full flex-row items-center gap-x-2">
        <div className="inline-flex h-[16px] w-full items-center justify-end gap-2.5 rounded-xl bg-graylight py-1">
          <div className="relative h-[16px] w-full">
            <div
              style={{ width: `${percentage}%` }}
              className={`absolute left-0 top-0 h-full rounded-xl ${color}`}
            />
          </div>
        </div>
        <div className="whitespace-nowrap text-xs font-normal text-black">
          {`${estimatedCapacity}/${totalCapacity} ${type}`}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
