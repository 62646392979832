import { useEffect, useState } from 'react';
import Select from 'react-select';

import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import { statusTruckOptions } from '../../../config/constants';
import {
  colourStyles,
  CustomOption,
  CustomSingleValue,
} from '../../../styles/statusDropdownStyle';

type Props = {
  initialStatusValue: string;
  mutation: (arg: any) => void;
  truckId: string;
};

const TruckStatusDropdown = ({
  initialStatusValue,
  mutation,
  truckId,
}: Props) => {
  const [status, setStatus] = useState<string>(initialStatusValue);

  const handleChangeStatus = (selectedOption: any) => {
    const newStatus = selectedOption.value;
    setStatus(newStatus);

    mutation({
      variables: {
        status: selectedOption.value,
        truckId,
      },
    });
  };

  useEffect(() => {
    if (initialStatusValue !== status) setStatus(initialStatusValue);
  }, [initialStatusValue]);

  return (
    <>
      <div className="absolute flex text-xs font-medium">
        <Select
          styles={colourStyles(status, statusTruckOptions)}
          components={{
            DropdownIndicator: () => <ArrowDownIcon />,
            IndicatorSeparator: () => null,
            Option: CustomOption,
            SingleValue: CustomSingleValue,
          }}
          options={statusTruckOptions}
          onChange={handleChangeStatus}
          id="TruckStatusDropdown"
          value={statusTruckOptions.find((option) => option.value === status)}
          isSearchable={false}
        />
      </div>
    </>
  );
};

export default TruckStatusDropdown;
