import { gql } from '@apollo/client';

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($key: String!, $value: String!, $clientId: String!) {
    updateClient(key: $key, value: $value, clientId: $clientId) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_CLIENTS = gql`
  mutation UpdateClientPeople($ids: [String]) {
    deleteClient(ids: $ids) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation CreateClient(
    $clientName: String!
    $phone: String!
    $address: String!
    $email: String!
  ) {
    createClient(
      clientName: $clientName
      phone: $phone
      address: $address
      email: $email
    ) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_CLIENT_PERSON = gql`
  mutation CreateClientPeople(
    $fullName: String!
    $role: String!
    $clientId: String!
    $phone: String!
    $email: String!
  ) {
    createClientPeople(
      fullName: $fullName
      role: $role
      clientId: $clientId
      phone: $phone
      email: $email
    ) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_CLIENT_PERSON = gql`
  mutation UpdateClientPeople(
    $clientPeopleId: String!
    $fullName: String!
    $role: String!
    $phone: String!
    $email: String!
  ) {
    updateClientPeople(
      fullName: $fullName
      phone: $phone
      role: $role
      email: $email
      clientPeopleId: $clientPeopleId
    ) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_CLIENT_PEOPLE = gql`
  mutation DeleteClientPeople($id: String!) {
    deleteClientPeople(id: $id) {
      status
      code
      data
      error
    }
  }
`;
