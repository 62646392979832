import 'react-datepicker/dist/react-datepicker.css';

import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import type { UsersQuery } from '@/types/user';

import calendarIcon from '../../../assets/icons/calendar.svg';
import invoiceIcon from '../../../assets/icons/dollar-square.svg';
import saveBigIcon from '../../../assets/icons/save.svg';
import { useModal } from '../../../hooks/useModal';
import { paymentFilterState } from '../../../recoil/disburseFilter';
import { needRefreshState } from '../../../recoil/needRefresh';
import { GET_USERS } from '../../../services/graphql/user/user-query';
import { convertDateToString } from '../../../utils/convertDate';
import { formatNumberMoney } from '../../../utils/numberFormatter';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import Dropdown from '../../ui/common/Dropdown';
import InputField from '../../ui/common/InputField';

const FilterPayment = () => {
  const { t } = useTranslation('common');
  const [filters, setFilters] = useRecoilState(paymentFilterState);

  const [drivers, setDrivers] = useState([{ label: '', value: '' }]);
  const [driver, setDriver] = useState('');
  const [receiptId, setReceipt] = useState('');
  const [amountFrom, setAmountFrom] = useState<Number | null>();
  const [amountTo, setAmountTo] = useState<Number | null>();

  // Form title
  const breadcrumbTitle = `${t('modalPaymentFilter.payment')}`;
  const breadcrumbSecondTitle = t('modalPaymentFilter.filters');
  const { data: fetchDrivers } = useQuery<UsersQuery>(GET_USERS, {
    variables: {
      orderBy: 'name',
      orderDirection: 'ASC',
      filterRole: 'Driver',
    },
  });

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  // Filters

  const [startDateFilter, setStartDateFilter] = useState<string>('');
  const [endDateFilter, setEndDateFilter] = useState<string>('');

  const handleFiltersSave = () => {
    let newFilters: Record<string, any> = {};

    newFilters = { ...filters };
    if (startDateFilter) {
      newFilters.filterStartDate = startDateFilter;
    } else {
      newFilters.filterStartDate = null;
    }
    if (endDateFilter) {
      newFilters.filterEndDate = endDateFilter;
    } else {
      newFilters.filterEndDate = null;
    }
    if (driver) {
      newFilters.filterDriver = driver;
    } else {
      newFilters.filterDriver = null;
    }
    if (receiptId) {
      newFilters.filterReceipt = receiptId;
    } else {
      newFilters.filterReceipt = null;
    }
    if (amountFrom) {
      newFilters.filterAmountFrom = amountFrom;
    } else {
      newFilters.filterAmountFrom = null;
    }
    if (amountTo) {
      newFilters.filterAmountTo = amountTo;
    } else {
      newFilters.filterAmountTo = null;
    }

    if (Object.keys(newFilters).length > 0) {
      setFilters(newFilters);
    } else {
      setFilters(null);
    }
    closeOrder();
  };

  useEffect(() => {
    if (filters) {
      if (filters.filterStartDate) {
        setStartDateFilter((filters.filterStartDate as string) || '');
      }
      if (filters.filterEndDate) {
        setEndDateFilter((filters.filterEndDate as string) || '');
      }
      if (filters.filterDriver) {
        setDriver((filters.filterDriver as string) || '');
      }
      if (filters.filterReceipt) {
        setReceipt((filters.filterReceipt as string) || '');
      }
      if (filters.filterAmountFrom) {
        setAmountFrom(filters.filterAmountFrom as number);
      }
      if (filters.filterAmountTo) {
        setAmountTo(filters.filterAmountTo as number);
      }
    }
  }, [filters]);
  useEffect(() => {
    if (fetchDrivers && fetchDrivers.getUsers) {
      const updatedData = fetchDrivers.getUsers.data.map((user) => ({
        value: user.id,
        label: user.name,
      }));
      setDrivers(updatedData);
    }
  }, [fetchDrivers]);

  return (
    <>
      <Breadcrumb
        title={breadcrumbTitle}
        secondTitle={breadcrumbSecondTitle}
        image={invoiceIcon}
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalPaymentFilter.filters')}
      </h1>
      <InputField
        label={t('modalPaymentFilter.receipt')}
        selectedValue={receiptId}
        onSelectChange={(value) => {
          setReceipt(String(value));
        }}
        doNotUseSaveSelfButton
        type="text"
        registerName="name"
        validationSchema={{
          required: t('global.required'),
        }}
      />
      <InputField
        label={t('modalPaymentFilter.startAmount')}
        selectedValue={Number(amountFrom)}
        onSelectChange={(value) => {
          if (value === '') {
            setAmountFrom(null);
          }
          setAmountFrom(Number(value));
        }}
        doNotUseSaveSelfButton
        type="text"
        registerName="AmountFrom"
        placeholder={formatNumberMoney(Number(amountFrom) || 0)}
        validationSchema={{
          required: t('global.required'),
        }}
      />
      <InputField
        label={t('modalPaymentFilter.endAmount')}
        selectedValue={Number(amountTo)}
        onSelectChange={(value) => {
          setAmountTo(Number(value));
        }}
        doNotUseSaveSelfButton
        placeholder={formatNumberMoney(Number(amountTo) || 0)}
        type="text"
        registerName="AmountTo"
        validationSchema={{
          required: t('global.required'),
        }}
      />
      <div className="my-6 flex flex-col gap-2">
        {/* Start Date Filter Datepicker */}
        <div className="h-[60px]">
          <label htmlFor="startDate" className="block text-[14px] font-[600]">
            {t('modalPaymentFilter.startDate')}
          </label>
          <div className="flex items-center">
            <img src={calendarIcon} alt="calendar icon" />
            <DatePicker
              calendarStartDay={1}
              dateFormat={'yyyy-MM-dd'}
              className="h-[40px] w-full cursor-pointer border-none bg-white px-2 text-xs text-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
              selected={new Date(startDateFilter || Date.now())}
              onChange={(date: any) => {
                setStartDateFilter(convertDateToString(date));
              }}
            />
          </div>
        </div>

        {/* End Date Filter Datepicker */}
        <div className="h-[60px]">
          <label htmlFor="endDate" className="block text-[14px] font-[600]">
            {t('modalInvoicesFilter.endDate')}
          </label>
          <div className="flex items-center">
            <img src={calendarIcon} alt="calendar icon" />
            <DatePicker
              calendarStartDay={1}
              dateFormat={'yyyy-MM-dd'}
              className="h-[40px] w-full cursor-pointer border-none bg-white px-2 text-xs text-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
              selected={new Date(endDateFilter || Date.now())}
              onChange={(date: any) => {
                setEndDateFilter(convertDateToString(date));
              }}
            />
          </div>
        </div>
        <Dropdown
          label={t('modalNewCashDisburse.driver')}
          id="driver"
          onSelectChange={(value) => {
            setDriver(value);
          }}
          options={drivers}
          selectedValue={driver || 'Choose an option'}
          placeholder="Choose an option"
        />

        <div className="mt-6 flex justify-end">
          <Button
            text={t('modalNewClient.save')}
            variant="green"
            icon={saveBigIcon}
            onClick={handleFiltersSave}
            type="button"
            id="save-invoice-filters"
          />
        </div>
      </div>
    </>
  );
};

export default FilterPayment;
