import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { tokenStateAtom } from '../../../recoil/authState';
import Loader from '../Loader';

interface WorkerSignatureProps {
  signature?: string | null;
}

function WorkerSignatureImage({ signature }: WorkerSignatureProps) {
  const token = useRecoilValue(tokenStateAtom);
  const [imgSrc, setImgSrc] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New loading state

  useEffect(() => {
    const loadImage = async () => {
      if (token && signature) {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_FILE_URL}${signature}&${Date.now()}`, // Add timestamp as query parameter
          {
            headers: new Headers({
              Authorization: `Bearer ${token}`,
              'apollo-require-preflight': 'true',
              'Accept-Encoding': 'gzip, deflate, br',
            }),
          },
        );
        const blob = await response.blob();
        const imgURL = URL.createObjectURL(blob);
        setImgSrc(imgURL);
        setIsLoading(false);
      }
    };

    loadImage();
  }, [signature, token]);

  if (isLoading || signature === undefined)
    return (
      <div className="box-shadow flex h-full w-full items-center justify-center rounded-lg md:w-[400px]">
        <Loader />
      </div>
    );

  return signature === null ? (
    <div className="box-shadow flex h-full w-[300px] items-center gap-2 rounded-lg object-contain px-6 py-4 md:w-[400px]" />
  ) : (
    <img
      src={imgSrc}
      alt="Driver's signature"
      className="box-shadow flex h-full max-w-[400px] items-center gap-2 rounded-lg object-contain px-6 py-4"
    />
  );
}

export default WorkerSignatureImage;
