export const formatNumber = (num: string | number) => {
  if (typeof num === 'string')
    return Intl.NumberFormat('en-US').format(parseInt(num));
  return Intl.NumberFormat('en-US').format(num);
};

export const formatNumberMoney = (num: string | number) => {
  if (typeof num === 'string')
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(parseInt(num));
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(num);
};

export const formatNumberPercentage = (num: string | number) => {
  let number = typeof num === 'string' ? parseInt(num) : num;
  number = number / 100;
  return Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(number);
};
