import { ReactComponent as UserIcon } from '../../../../../assets/icons/user.svg';

type Props = {
  note: string;
  user_name: string;
  createdAt: number;
  id: any;
};

const NoteCard = ({ user_name, createdAt, note, id }: Props) => {
  return (
    <div key={id} className="mb-1 flex flex-row items-start gap-4 ">
      <div className="inline-flex h-[26px] w-[26px] items-center justify-center gap-2.5 rounded-[90px] bg-gray-400 p-[5px]">
        <UserIcon />
      </div>
      <div className="flex flex-col">
        <div className="mb-4 flex flex-row justify-start gap-1">
          <p className="text-sm font-normal text-gray-400">
            {user_name}
            {' -'}
          </p>
          <p className="text-sm font-normal text-gray-400">
            {new Date(createdAt).toLocaleString('en-US', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            })}
          </p>
        </div>
        <p className="mb-4 text-sm font-normal text-black">{note}</p>
      </div>
    </div>
  );
};

export default NoteCard;
