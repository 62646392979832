import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import saveBigIcon from '../../../assets/icons/save.svg';
import truckIcon from '../../../assets/icons/truck.svg';
import { truckMakeOptions } from '../../../config/constants';
import { useModal } from '../../../hooks/useModal';
import { useNotification } from '../../../hooks/useNotification';
import { needRefreshState } from '../../../recoil/needRefresh';
import { CREATE_TRUCK } from '../../../services/graphql/trucks/trucks-mutations';
import Breadcrumb from '../../ui/common/Breadcrumb';
import { Button } from '../../ui/common/Button';
import Dropdown from '../../ui/common/Dropdown';
import InputField from '../../ui/common/InputField';

const NewTruck = () => {
  const { t } = useTranslation('common');
  const { notifyEmptyWarn, notifySaveSuccess, notifySaveError } =
    useNotification();
  // States
  const [make, setMake] = useState<string | null>(null);
  const [model, setModel] = useState<string | null>(null);
  const [license, setLicense] = useState<string | null>(null);

  const breadcrumbTitle = t('modalNewTruck.newTruckBreadcrumb');
  const breadcrumbSecondTitle = t('modalNewTruck.newTruckTitle');

  // Change data for refresh tables
  const setNeedRefresh = useSetRecoilState(needRefreshState);

  const refreshOrder = () => {
    setNeedRefresh(true);
  };

  const endRefreshOrder = () => {
    setTimeout(() => {
      setNeedRefresh(false);
    }, 500);
  };

  // On submit success close modal notification
  const { handleCloseAllModals } = useModal();

  const closeOrder = () => {
    handleCloseAllModals();
    refreshOrder();
    endRefreshOrder();
  };

  const handleFormReset = () => {
    setMake(null);
    setModel(null);
    setLicense(null);
  };

  // Queries
  const [createTruck, { loading: createTruckLoading }] = useMutation<any>(
    CREATE_TRUCK,
    {
      variables: {
        make,
        model,
        license,
      },
      onCompleted: () => {
        notifySaveSuccess();
        closeOrder();
        handleFormReset();
      },
      onError: () => {
        notifySaveError();
      },
    },
  );

  // On change
  const handleMakeChange = (value: string | number | null) => {
    if (value && typeof value === 'number') {
      setMake(value.toString());
    }
    if (value && typeof value === 'string') {
      setMake(value);
    }
    if (value === null) {
      setMake(null);
    }
  };

  const handleModelChange = (value: string | number | null) => {
    if (value && typeof value === 'number') {
      setModel(value.toString());
    }
    if (value && typeof value === 'string') {
      setModel(value);
    }
    if (value === null) {
      setModel(null);
    }
  };

  const handleLicenseChange = (value: string | number | null) => {
    if (value && typeof value === 'number') {
      setLicense(value.toString());
    }
    if (value && typeof value === 'string') {
      setLicense(value);
    }
    if (value === null) {
      setLicense(null);
    }
  };

  // On Submit
  const onSubmit = () => {
    if (createTruckLoading) return;

    if (make && model && license) {
      createTruck();
    }

    if (!make || !model || !license) {
      notifyEmptyWarn();
    }
  };

  return (
    <>
      <Breadcrumb
        title={breadcrumbTitle}
        secondTitle={breadcrumbSecondTitle}
        image={truckIcon}
      />
      <h1 className="mt-6 text-xl font-[600]">
        {t('modalNewTruck.newTruckTitle')}
      </h1>
      <form className="my-6">
        {/* Make Input */}
        <div className="h-[50px]">
          <Dropdown
            options={truckMakeOptions}
            selectedValue={make || ''}
            onSelectChange={handleMakeChange}
            label={t('trucks.make')}
          />
        </div>

        {/* Model Input */}
        <div className="h-[50px]">
          <InputField
            label={t('modalNewTruck.model')}
            onSelectChange={handleModelChange}
            selectedValue={model}
            doNotUseSaveSelfButton
            type="text"
          />
        </div>

        {/* License Input */}
        <div className="h-[50px]">
          <InputField
            label={t('modalNewTruck.license')}
            onSelectChange={handleLicenseChange}
            selectedValue={license}
            doNotUseSaveSelfButton
            type="text"
          />
        </div>

        <div className="mt-6 flex justify-end">
          <Button
            onClick={onSubmit}
            loading={createTruckLoading}
            icon={saveBigIcon}
            text={t('modalNewTruck.save')}
            variant="green"
          />
        </div>
      </form>
    </>
  );
};

export default NewTruck;
