import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import workerIcon from '../../assets/icons/personalcard.svg';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import { LoaderScreen } from '../../components/ui/LoaderScreen';
import DTWorkers from '../../components/ui/workers/DTWorkers';

const WorkersView = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <section>
        <Breadcrumb title={t('workers.title-h1')} image={workerIcon} />
        <Suspense fallback={<LoaderScreen />}>
          <DTWorkers />
        </Suspense>
      </section>
    </>
  );
};

export default WorkersView;
