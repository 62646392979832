import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// MAP CONFIG
const DEFAULT_CENTER_LATS: [number, number] = [37.7675902, 33.79333422222222];
const DEFAULT_CENTER_LNGS: [number, number] = [-122.4297657, -116.505380027777];
const markerElement = () => {
  // Create a DOM element for each marker.
  const el = document.createElement('div');
  const WIDTH = 15;
  const HEIGHT = 15;
  el.className = 'marker';
  el.style.backgroundImage = `url(/svg/map-marker.svg)`;
  // el.style.backgroundImage = `url(https://placekitten.com/g/${WIDTH}/${HEIGHT}/)`;
  el.style.width = `${WIDTH}px`;
  el.style.height = `${HEIGHT}px`;
  el.style.backgroundSize = '100%';
  return el;
};

type Props = {
  stops: any[];
  isNotSticky?: boolean;
};

const Map: React.FC<Props> = ({ stops, isNotSticky }) => {
  const mapDiv = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [currentMarkers, setCurrentMarkers] = useState<mapboxgl.Marker[]>([]);

  useEffect(() => {
    if (!mapDiv.current || map) {
      return;
    }
    while (mapDiv.current.firstChild) {
      mapDiv.current.removeChild(mapDiv.current.firstChild);
    }

    const lats =
      stops.length > 0
        ? stops.map((stop) => stop.clientCoords.coordinates[0])
        : DEFAULT_CENTER_LATS;
    const lngs =
      stops.length > 0
        ? stops.map((stop) => stop.clientCoords.coordinates[1])
        : DEFAULT_CENTER_LNGS;

    const minLat = Math.min(...lats);
    const maxLat = Math.max(...lats);
    const minLng = Math.min(...lngs);
    const maxLng = Math.max(...lngs);

    const mapInstance = new mapboxgl.Map({
      accessToken: process.env.REACT_APP_PUBLIC_MAPBOX_GL_ACCESS_TOKEN ?? '',
      container: mapDiv.current!,
      style: process.env.REACT_APP_PUBLIC_MAPBOX_GL_STYLE ?? '',
      center: [(minLng + maxLng) / 2, (minLat + maxLat) / 2],
      zoom: 10,
    });

    mapInstance.on('load', () => {
      mapInstance.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: { top: 50, bottom: 50, left: 50, right: 50 },
        },
      );
    });

    setMap(mapInstance);
  }, [map, stops]);

  useEffect(() => {
    if (!map) return;

    currentMarkers.forEach((marker) => marker.remove());
    const newCurrentMarkers: mapboxgl.Marker[] = [];

    stops.forEach(
      (stop: {
        clientName: string;
        status: string;
        clientCoords: { coordinates: [any, any] };
      }) => {
        const [stopLatitude, stopLongitude] = stop.clientCoords.coordinates;
        if (
          stopLatitude >= -90 &&
          stopLatitude <= 90 &&
          stopLongitude >= -180 &&
          stopLongitude <= 180
        ) {
          const marker = new mapboxgl.Marker(markerElement())
            .setLngLat([stopLongitude, stopLatitude])
            .addTo(map);
          newCurrentMarkers.push(marker);
          const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
            `Client Name: ${stop.clientName}<br />Stop status: ${stop.status}`,
          );
          marker.setPopup(popup);
          setCurrentMarkers([...newCurrentMarkers]);
        } else {
          console.warn(
            `Invalid coordinates: ${stopLongitude}, ${stopLatitude}`,
          );
        }
      },
    );
  }, [map, stops]);
  return (
    <div
      ref={mapDiv}
      className={`h-[460px] w-full items-center justify-center rounded-lg ${
        isNotSticky ? 'absolute top-0' : '!sticky top-[55px] z-20'
      }`}
    />
  );
};

export default Map;
