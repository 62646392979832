export function convertToTwelveHourFormat(
  timeString: string | undefined
): string {
  if (timeString === undefined) {
    return "Invalid time";
  }

  const [hoursStr, minutesStr] = timeString.split(":");
  const hours = parseInt(hoursStr!, 10);
  const minutes = parseInt(minutesStr!, 10);

  if (!isNaN(hours) && !isNaN(minutes)) {
    const date = new Date(2000, 0, 1, hours, minutes);

    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  } else {
    return "Invalid time";
  }
}
