import type { OptionProps, SingleValueProps } from 'react-select';
import { components } from 'react-select';

const hexToRgb = (hex: string | undefined) => {
  if (!hex) return null;

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return null;

  const r = result[1];
  const g = result[2];
  const b = result[3];

  if (!r || !g || !b) return null;

  return {
    r: parseInt(r, 16),
    g: parseInt(g, 16),
    b: parseInt(b, 16),
  };
};

export const CustomOption: React.FC<OptionProps<any, boolean>> = (props) => {
  const { data, label } = props;
  return (
    <components.Option {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            height: '7px',
            width: '7px',
            minWidth: '7px',
            borderRadius: '50%',
            backgroundColor: data.color,
            marginRight: '10px',
          }}
        />
        {label}
      </div>
    </components.Option>
  );
};

export const colourStyles = (status: any, statusOptions: any) => ({
  menu: (styles: any) => ({
    ...styles,
    minWidth: '200px',
  }),
  option: (styles: any, { data, isDisabled, isSelected }: any) => {
    // let backgroundColor;
    // let color;
    const cursor = isDisabled ? 'not-allowed' : 'pointer';
    let activeStyles;

    // if (isDisabled) {
    //   backgroundColor = undefined;
    //   color = '#ccc';
    // }

    // if (!isDisabled) {
    //   activeStyles = {
    //     backgroundColor: isSelected ? data.color : 'rgba(0, 0, 0, 0.1)',
    //   };
    // }

    return {
      ...styles,
      // backgroundColor,
      // color,
      cursor,
      ':active': activeStyles,
      textTransform: 'uppercase',
      backgroundColor: isSelected ? `${data.color}50` : '',
      color: '#000',
      '&:hover': {
        background: isSelected ? `${data.color}50` : '#B2BFC1',
        backgroundOpacity: 0.5,
      },
    };
  },
  control: (styles: any, { selectProps: { value } }: any) => {
    const selectedOption = statusOptions.find(
      (option: any) => option.value === value,
    );
    const defaultOption = statusOptions.find(
      (option: any) => option.value === status,
    );

    const color =
      (selectedOption && selectedOption.color) ||
      (defaultOption && defaultOption.color) ||
      '#9242D1';

    const rgb = hexToRgb(color);

    return {
      outline: styles.outline,
      transition: styles.transition,
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      padding: '0 2px',
      fontSize: '14px',
      cursor: 'pointer',
      backgroundColor: 'white',
      minWidth: '130px',
      height: '19px',
      borderRadius: '6px',
      border: `1px solid ${color}`,
      background: rgb ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)` : color,
    };
  },
  singleValue: (styles: any) => ({
    ...styles,
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    textTransform: 'uppercase',
  }),
});

export const CustomSingleValue: React.FC<SingleValueProps<any>> = (props) => {
  const { data } = props;
  const { label } = data;
  return (
    <components.SingleValue {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          minWidth: '80px',
        }}
      >
        <div
          style={{
            height: '7px',
            width: '7px',
            minWidth: '7px',
            borderRadius: '50%',
            backgroundColor: data.color,
            marginRight: '10px',
          }}
        />
        {label}
      </div>
    </components.SingleValue>
  );
};
