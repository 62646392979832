import { useTranslation } from 'react-i18next';

import type { SuggestedVisit } from '@/types/service';

import { LoaderScreen } from '../../LoaderScreen';
import NewStopCard from './NewStopCard';

type Props = {
  loading?: boolean;
  suggestedVisitsData?: SuggestedVisit[];
  readyToVisit?: boolean;
  mutation: (arg: any) => void;
  existingStops: any[] | undefined;
  routeId: string;
};

const SuggestedVisitsList = ({
  loading,
  suggestedVisitsData,
  readyToVisit,
  mutation,
  existingStops,
  routeId,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-col gap-2">
      {suggestedVisitsData && suggestedVisitsData?.length > 0 ? (
        suggestedVisitsData.map((visit) => (
          <NewStopCard
            key={visit.id}
            visit={visit}
            isReadyToVisit={readyToVisit}
            mutation={mutation}
            existingStops={existingStops}
            routeId={routeId}
          />
        ))
      ) : (
        <div className="my-6 flex w-full items-center justify-center">
          <p>{t('routes.no-suggested-visit')}</p>
        </div>
      )}

      {loading && <LoaderScreen />}
    </div>
  );
};

export default SuggestedVisitsList;
